.SubmitButtonContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-left: 5px;
    height: 35px;
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
}

.Cancel {
    background-color: white;
    border: 1px solid var(--color-text-secondary);
    margin-right: 15px;
    color: var(--color-text-dark-secondary);
}

.FeedSubmit {
    padding: 6px 15px;
}

.MediaOptions {
    border-top: 1px solid #F0F1F5;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.MediaOptions input {
    display: none;
}

.MediaOption {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 13px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    color: var(--color-primary);
}

.MediaOption:hover {
    background-color: var(--color-input-background-light);
}

.MediaOption svg {
    height: 18px;
    color: var(--color-primary);
    margin-right: 7px;
}

.MediaPreviewContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 0 10px;
    font-size: 13px;
    align-items: center;
}

.FileSizePopup {
    width: 300px;
    height: 150px;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.FileSizePopupButton {
    padding: 8px 10px;
}

.FileSizePopupText {
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
}

.Exclamation {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.Exclamation svg {
    width: 40px;
}

.ImagePreview {
    display: flex;
    align-items: center;
    padding: 3px 5px 3px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
    position: relative;
}

.ImagePreview svg {
    width: 15px;
    margin-right: 10px;
}

.ImagePreview img {
    max-width: 130px;
    max-height: 75px;
}

.VideoPreview {
    display: flex;
    align-items: center;
    padding: 3px 5px 3px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
    position: relative;
}

.VideoPreview svg {
    width: 15px;
    margin-right: 10px;
}

.VideoPreview video {
    max-width: 130px;
    max-height: 75px;
}

.RemoveMedia {
    position: absolute;
    top: 7px;
    right: 7px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: #C1C1CD;
    border-radius: 100%;
    color: var(--color-text-dark);
}

.RemoveMedia svg {
    height: 15px;
    margin: 0;
}
