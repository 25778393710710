@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.Container {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.SplitContainer {
    display: flex;
}

.Profile {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 15px;
}

.Metadata {
    width: calc(100% - 50px);
    padding-left: 20px;
    box-sizing: border-box;
}

.TitleStrip {
    width: 30%;
    height: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.Strip {
    width: 100%;
    height: 12px;
    margin-bottom: 7px;
    overflow: hidden;
}

.LinearBackground {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}
