.EditIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    width: fit-content;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: bold;
}

.EditIcon:hover {
    background-color: var(--color-primary-washed);
}

.PopupContainer {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    width: 600px;
    position: relative;
}

.Title {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.SubmitContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.SaveOption {
    margin-left: 10px;
}

.CancelOption {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.ProfileEditPopup {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    max-height: 600px;
    overflow-y: scroll;
}

.ProfileEditPopup textarea{
    height: 350px;
}

.ChangeButtonContainer {
    height: 38px;
    margin-bottom: 20px;
}

.ChangeButtonContainer i {
    margin-right: 10px;
}

.ChangeButton {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}
