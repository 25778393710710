.Exit {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0 10px;
}

.ExitIcon {
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ExitIcon:hover {
    background-color: #F5F7FA;
    border-radius: 100%;
}

.NavigationSmallOptionsActive {
    position: fixed;
    left: 0 !important;
    z-index: 2000;
    transition: left .2s;
    box-shadow: 0 4px 20px 1px rgba(0,0,0, 0.3);
}

.NavigationSmallOptions {
    position: fixed;
    background-color: white;
    width: 250px;
    max-width: 100vw;
    height: 100%;
    top: 0;
    left: -250px;
    z-index: 100;
    transition: left .2s;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 0 0 10px;
    box-sizing: border-box;
}

.Backdrop {
    background-color: var(--color-text-dark);
    opacity: .1;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
}

.Option {
    font-size: 20px;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
}

.Option:hover {
    background-color: #F5F7FA;
}
