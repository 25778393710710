.LinkContainer {
    text-decoration: none;
    color: inherit;
}

.PreviewContainer {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid var(--color-border-light);
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 0 3px -3px rgba(0,0,0, 0.4);
    background-color: white;
}

.PreviewContainerLeft {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.PreviewContainerLeft img {
    max-width: 100%;
    max-height: 330px;
    object-fit: contain;
}

.PreviewContainerRight {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    word-wrap: break-word;
    color: var(--color-text-dark-secondary);
}

.PreviewContainerRight h2 {
    color: var(--color-text-dark);
    margin: 0 0 10px 0;
    font-size: 14px;
}


@media (max-width: 1100px) {
    .PreviewContainerLeft img {
        max-width: 100%;
        max-height: 230px;
        object-fit: contain;
    }
}
