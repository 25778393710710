.DescriptionContainer {
    font-size: 15px;
    margin-bottom: 20px;
}

.DescriptionContainer pre {
    margin: 0;
}

.ExpandCollapseButtonContainer {
    padding: 10px 0;
}

.ExpandCollapseButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-primary);
}

.ExpandCollapseButton i {
    margin-left: 10px;
}

.Expanded i {
    transform: rotate(-180deg);
}
