.PopupContainer {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    width: 600px;
    position: relative;
}

.ProfileEditPopup {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    max-height: 600px;
    overflow-y: scroll;
}

.SubmitContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.InvisibleInput {
    display: none;
}

@media (max-width: 1100px) {
    .PopupContainer {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        padding-top: 60px;
        padding-bottom: 15vh;
        box-sizing: border-box;
    }

    .ProfileEditPopup {
        max-height: none;
        overflow-y: auto;
    }

    .SubmitContainer {
        border-top: none;
    }
}
