.InputContainer {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.InputLabel {
    margin-top: 20px;
}

.FounderInputContainer {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    padding: 20px 15px 10px 15px;
    box-sizing: border-box;
    background-color: #F5F7FA;
}

.AddFounder {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AddFounderIcon {
    padding: 5px 15px;
    border: 1px solid #CDD6E5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #A8AEBD;
    font-size: 25px;
    cursor: pointer;
    background-color: #F5F7FA;
}

.FeaturedImages {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
}

.FeaturedImage {
    width: 100%;
    margin-bottom: 15px;
}

.NoFeaturedImages {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #F5F7FA;
    padding: 40px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.NoHeadshot {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: white;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.NoHeadshotIcon {
    width: 100%;
    font-size: 40px;
    display: flex;
    justify-content: center;
    color: #757887;
}

.NoHeadshotText {
    font-size: 16px;
    font-weight: 400;
    color: #757887;
}

.NoFeaturedImagesIcon {
    width: 100%;
    font-size: 80px;
    display: flex;
    justify-content: center;
    color: #757887;
}

.NoFeaturedImagesText {
    font-size: 22px;
    font-weight: 400;
    color: #757887;
}

.FounderInputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.AddFounderButtonContainer {
    width: fit-content;
    border: 1px solid var(--color-text-dark);
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 2px;
    color: var(--color-text-dark);
    font-weight: 400;
    cursor: pointer;
}

.AddFounderButtonContainer i {
    margin-right: 10px;
}

.RemoveFounderIconContainer {
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.RemoveFounderIcon {
    width: fit-content;
    cursor: pointer;
    padding: 5px 10px;
}

.RemoveFounderIcon:hover {
    background-color: white;
}

.RemoveFounderIcon i {
    margin-right: 7px;
}

.FounderContainer {
    width: 100%;
}

.AddButton {
    margin-top: 30px;
    width: 100%;
}

.AddHeadShotButton {
    background-color: white;
}
