.Container {
    width: 100%;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}

.Spinner {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
}
