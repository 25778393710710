.SideNav {
    width: 220px;
}

.Back {
    width: fit-content;
    position: relative;
    display: flex;
    top: 0;
    padding: 5px 10px;
    align-items: center;
    cursor: pointer;
    border-radius: 25px;
    margin: 20px 0 15px 10px;
    font-weight: bold;
    font-size: 15px;
}

.Back svg {
    width: 16px;
    max-height: 16px;
    margin-right: 6px;
}

.Back:hover {
    background-color: var(--color-input-background-light);
}

.FeedPost {
    width: calc(100% - 220px - 340px);
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
    box-sizing: border-box;
}

.FeedPostPrimary {
    box-sizing: border-box;
}

.FeedPostPrimary > div:hover {
    background-color: inherit; /* TODO: this is a hack */
}

.CommentsHeader {
    width: 100%;
    padding: 60px 15px 15px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-border-light);
}

.CommentsHeader h3 {
    margin: 0;
}

.SpinnerContainer {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EndOfResults {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-weight: bold;
}

@media (max-width: 1200px) {
    .SideNav {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .FeedPost {
        width: calc(100% - 220px);
        margin-bottom: 150px;
        padding-right: 50px;
        box-sizing: border-box;
    }
}

@media (max-width: 700px) {
    .SideNav {
        display: none;
    }

    .FeedPost {
        width: 100%;
        padding: 0;
        border-left: none;
        border-right: none;
    }

    .FeedPostPrimary {
        padding: 10px;
        box-sizing: border-box;
    }
}
