@import '../../../styles/Styles.module.css';

.ContainerOuter {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}

.Container {
    width: 100%;
    background-color: white;
    overflow: hidden;
    font-size: 15px;
    height: 40px;
    border: 1px solid #cdd6e5;
    box-sizing: border-box;
    border-radius: 3px;
    position: relative;
}

.ContainerFocused {
    -webkit-box-shadow: 0 0 0 1px var(--color-primary);
        box-shadow: 0 0 0 1px var(--color-primary);
    box-sizing: border-box;
    border: 1px solid var(--color-primary);
}

.TypeError {
    position: absolute;
    right: 0;
    top: -23px;
    font-size: 12px;
    font-weight: 400;
    color: #6e7891;
}

.Error {
    color: #D93025;
    font-size: 12px;
    margin-bottom: 5px;
}

.Error i {
    margin-right: 3px;
}

.ContainerError {
    outline: 2px solid #D93025 !important;
}

.Container input {
    font-size: 16px;
    padding: 0 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;

}

.Container input::placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}

.CharacterCounter {
    position: absolute;
    right: 0;
    bottom: -20px;
    font-size: 12px;
    font-weight: 400;
}
