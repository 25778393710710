.Container {
    position: fixed;
    bottom: 0;
    right: 50px;
    width: 400px;
    box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
}

.Icon {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    justify-content: center;
}

.Icon:hover {
    background-color: var(--color-input-background-light);
}

.Icon svg {
    width: 24px;
    max-height: 22px;
}

.Open {
    height: 80vh;
    transition: height .3s ease-out;
    display: flex;
    flex-flow: column;
}

.Closed {
    height: 60px;
    transition: height .3s ease-out;
}

@media (max-width: 700px) {

    .Container {
        display: none;
    }
}
