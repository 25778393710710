@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: Roboto, 'Gothic A1', Arial, sans-serif;
  color: var(--color-text-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

h3, h2, h1 {
  font-family: Roboto, 'Gothic A1', Arial, sans-serif;
  line-height: 1.2;
}

input, textarea, div {
  font-family: Roboto, 'Gothic A1', Arial, sans-serif;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: Roboto, 'Gothic A1', Arial, sans-serif;
}

.App_PrimaryContainer__1G6S7 {
    height:100%;
    width:100vw;
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    overflow: hidden;

}

.FeaturedEntity_FeaturedEntityContainer__23l2w {
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.FeaturedEntity_Logo__3MaYw {
    width: 40px;
    height: 40px;
}

.FeaturedEntity_Right__2Dv6S {
    width: calc(100% - 40px);
    padding-left: 10px;
box-sizing: border-box;
}

.FeaturedEntity_Preview__bD8HJ {
    width: 100%;
    height: 225px;
    object-fit: contain;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.FeaturedEntity_Preview__bD8HJ img {
    height: 225px;
    min-width: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.FeaturedEntity_Details__iiwpj {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    padding-top: 10px;
}

.FeaturedEntity_Title__3QHBY {
    font-size: 18px;
    font-weight: bold;
}

.FeaturedEntity_Description__2nFfr {
    width: 100%;
    color: var(--color-text-dark-secondary);
}

.FeaturedEntity_Description__2nFfr svg {
    width: 10px;
}

.ProfilePhotoWithDefault_Container__2rNky {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
}

.ProfilePhotoWithDefault_Container__2rNky > div {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;

    display: -webkit-flex !important;

    display: flex !important;
    -webkit-align-items: center !important;
            align-items: center !important;
}

.ProfilePhotoWithDefault_Container__2rNky > div > div {
    height: 100% !important;
    width: 100% !important;
}

.ProfilePhotoWithDefault_Container__2rNky > div > div > div {
    height: 100% !important;
    width: 100% !important;
}

.ProfilePhotoWithDefault_Container__2rNky img {
    object-fit: contain;
    width: 100% !important;
    height: auto !important;
}

.ProfilePhotoWithDefault_Container__2rNky span {
    margin: 0 !important;
}

.Footer_FooterContainer__1Ujmv {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 15px;
    /*background-color: var(--color-input-background-light);*/
    padding-bottom: 20px;
}

.Footer_ConnectContainer__3vNE_ {
    display: -webkit-flex;
    display: flex;
}

.Footer_ConnectLogoContainer__mW7s8 {
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 100%;
    margin: 10px 10px 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
}

.Footer_FooterInner__22xut {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    display: -webkit-flex;
    display: flex;
    padding: 10px 0;
    -webkit-justify-content: center;
            justify-content: center;
}

.Footer_FooterInner__22xut {
    padding: 0 10px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
}

.Footer_FooterInner__22xut a {
    color: var(--color-text-dark);
    text-decoration: none;
    width: 100%;
    display: block;
    padding: 3px 0;
}

.Footer_ContactOption__2eGS7 {
    margin-right: 20px;
}

.Footer_ContactOption__2eGS7 a {
    color: var(--color-text-secondary);
}

.Footer_ContactOption__2eGS7 i {
    margin-right: 10px;
}

.Footer_LogoContainer__WqF7h {
    width: 100%;
}

.Footer_LogoContainer__WqF7h img {
    max-width: 100px;
}


@media (max-width: 1100px) {
    .Footer_FooterPage__2oC5w {
        max-width: 50%;
    }
}

:root {
    --color-primary: rgba(83, 144, 253, 1);
    --color-primary-washed: rgba(83, 144, 253, .1);
    --color-border-light: rgba(205, 214, 229, .55);
    --color-text-secondary: rgba(169, 175, 190, 1);
    --color-input-background: rgba(233, 236, 241, 1);
    --color-text-area-border: rgba(218, 221, 226, 1);
    --color-input-background-light: rgba(238, 241, 246, .6);
    --color-input-background-very-light: rgba(238, 241, 246, .4);
    --color-text-dark: rgba(10, 37, 64, 1);
    --color-text-dark-secondary: rgba(10, 37, 64, .6);
    --color-text-dark-secondary-darker: rgba(10, 37, 64, .8);
    --page-width: 1200px;
}

.Styles_ContentContainer__2koE- {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -ms-scroll-chaining: none !important;
        overscroll-behavior: contain !important;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    height: 100%;
}

.Styles_ContentContainerInner__RlRTg {
    width: 100%;
    max-width: 1200px;
    max-width: var(--page-width);
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.Styles_ClimateAds__3MORL {
    width: 340px;
    padding-left: 20px;
    box-sizing: border-box;
    top: 20px;
    margin-top: 20px;
    position: -webkit-sticky;
    position: sticky;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: calc(100% - 80px);
    overflow-y: auto;
}

.Styles_ImageContainer__2R_uc {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.Styles_ImageContainer__2R_uc img {
    width: 100%;
    margin-bottom: 10px;
}

.Styles_PeopleContainer__2qDLS {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    position: relative;
}

.Styles_EditIconContainer__3MHFy {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-bottom: 30px;
}

@media (max-width: 1200px) {

    .Styles_ClimateAds__3MORL {
        display: none;
    }

    .Styles_ContentContainerInner__RlRTg {
        padding-top: 0;
    }
}

.Landing_Container__1jnml {
    width: 100%;
    padding: 100px 80px 0 80px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
}

.Landing_FeaturedContainer__3FY1B {
    width: var(--page-width);
    max-width: 100%;
    background-color: white;
    margin-top: 90px;
}

.Landing_ContainerInner__3GUvz {
    width: 100%;
    max-width: var(--page-width);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    box-sizing: border-box;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    position: relative;
}

.Landing_LandingTitle__Myzm4 {
    font-size: 50px !important;
    width: 100%;
    margin: 0;
}

.Landing_Title__3Wh4L {
    font-size: 28px;
    width: 100%;
    margin: 0;
}

.Landing_SeeAllLink__2LWni {
    color: var(--color-text-dark-secondary);
}

.Landing_FeaturedDescription__fFvLk {
    width: 100%;
    color: var(--color-text-dark-secondary);
    font-size: 19px;
    margin-bottom: 5px;
}

.Landing_Description__3XN7t {
    color: var(--color-text-dark-secondary);
    font-size: 20px;
    width: 900px;
    margin-bottom: 25px;
    padding-right: 200px;
    box-sizing: border-box;
}

.Landing_LandingContainer__2Fkv8 {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--color-input-background-light);
    padding: 10px 20px 20px 20px;
    box-sizing: border-box;

}

.Landing_ProfileTypes__3gmy1 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
}

.Landing_ProfileType__1Z-Cp {
    padding: 10px 30px;
    border-bottom: 2px solid var(--color-text-secondary);
    color: var(--color-text-secondary);

    margin: 0 10px;
    font-weight: 500;
}

.Landing_ProfileTypeActive__3XWj9 {
    border-bottom: 2px solid var(--color-text-dark);
    color: var(--color-text-dark);
}

.Landing_ProfileType__1Z-Cp svg {
    width: 16px;
}


.Landing_RelativeContainer__1IgJl {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Landing_DiagonalContainer__1od2V {
    position: relative;
    width: 100%;
}

.Landing_DiagonalLeft__1sjyv {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 10vw solid white;
    border-right: 100vw solid transparent;
}

.Landing_DiagonalRight__zPYQd {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 10vw solid white;
    border-left: 100vw solid transparent;
}

.Landing_DiagonalTopRight__iA759 {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 10vw solid white;
    border-right: 100vw solid transparent;
    z-index: 1000;
}

.Landing_Iphone__2mp5N {
    width: 220px;
    position: relative;
    padding-right: 40px;
    box-sizing: border-box;
}

.Landing_Iphone__2mp5N img {
    max-width: 100%;
}

.Landing_LandingLeft__16I_t {
    width: calc(100%);
    padding-right: 80px;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: 70px;
    padding-left: 215px;
    padding-top: 0;
}

.Landing_LandingLeft__16I_t h2 {
    font-size: 65px;
    font-weight: bold;
    line-height: 1.0;
    margin-bottom: 5px;
}

.Landing_Logo__2AXEn {
    margin-bottom: 20px;
}

.Landing_Logo__2AXEn img {
    width: 60px;
}

.Landing_SwitchPopup__3RAwd {
    font-size: 14px;
    width: 100%;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.Landing_SideSwitchPopupLeft__130pA{
    text-align: right;
    margin-right: 30px;
}
.Landing_SideSwitchPopupLeft__130pA{
    text-align: left;
}

.Landing_LabelUnderline__3tKyk {
    text-decoration: underline;
    cursor: pointer;
}

.Landing_LandingRight__2H-at {
    /*display: none;*/
    width: 40%;
    padding-right: 50px;
    box-sizing: border-box;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 100;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.Landing_LandingRight__2H-at img {
    max-width: 100%;
    width: 600px;
    object-fit: contain;
}

.Landing_LandingButtonsNew__VEDMX {
    margin-top: 5px;
    width: 100%;
}

.Landing_LandingButtons__YhPGa {
    margin-bottom: 5px;
    /*margin-top: 5px;*/
    display: -webkit-flex;
    display: flex;
    /*z-index: 100;*/
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    text-decoration: underline;
}

.Landing_SubmitButtonWrapper__3fJDg {
    width: 100%;
    position: relative;
    margin: 10px 0;
    height: 50px;
}

.Landing_GoogleButton__2Gc6q {
    background-color: white !important;
    color: black !important;
    border: 1px solid var(--color-text-dark-secondary) !important;
}

.Landing_GoogleButton__2Gc6q svg {
    height: 20px;
    margin-right: 15px;
}

.Landing_Line__305ju {
    border-bottom: 1px solid var(--color-border-light);
    margin: 15px 0;
}


.Landing_Link__30XOF {
    text-decoration: none;
}

.Landing_SignupContainer__sHhPl {
    width: 500px;
    background: rgba(255, 255, 255, 1);
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    -webkit-animation: Landing_fade-translate__1BoM8 1s ease-out;
            animation: Landing_fade-translate__1BoM8 1s ease-out;
}

@-webkit-keyframes Landing_fade-translate__1BoM8 {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
}

@keyframes Landing_fade-translate__1BoM8 {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
}

@-webkit-keyframes Landing_fade__21_B3 {
    0% {
        opacity: 0;
    }
}

@keyframes Landing_fade__21_B3 {
    0% {
        opacity: 0;
    }
}

.Landing_SignupHeader__1HahC {
    padding-bottom: 10px;
    font-size: 18px;
    color: var(--color-text-dark-secondary);
}

.Landing_SignupHeader__1HahC h3 {
    margin: 15px 0 5px 0;
    font-size: 24px;
    color: var(--color-text-dark);
}

.Landing_FeaturedProfilesContainer__qsjn_ {
    background-size: 100%;
    margin-bottom: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: var(--page-width);
}

.Landing_LogoText__1NTAm {
    margin-left: 0;
    margin-right: auto;
    font-weight: bold;
    font-size: 26px;
}

.Landing_Navigation__3U2Kz {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-bottom: 40px;
}


.Landing_LatestNews__1Kh1Q {
    background-color: #fdcf9b;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 10px;
}

.Landing_FeaturedProfiles__1b1ZA {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
}

.Landing_FullWidth__3a6-Y {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    overflow: hidden;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Landing_LeftHalf__ARt2e {
    width: 50%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    object-fit: contain;
    height: 450px;
}

.Landing_LeftHalf__ARt2e img {
    width: 100%;
    object-fit: cover;
    height: 450px;
}

.Landing_RightHalf__2GjOh {
    width: 50%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #F5F7FA;
    position: relative;
    padding: 40px 80px;
    box-sizing: border-box;
    height: 450px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.Landing_RightHalf__2GjOh h3 {
    color: var(--color-text-dark-secondary);
    font-size: 20px;
    margin: 0 0 10px 0;
}

.Landing_RightHalf__2GjOh h2 {
    font-size: 32px;
    margin: 0 0 10px 0;
}

.Landing_RightHalf__2GjOh div > div {
    color: var(--color-text-dark-secondary);
}

.Landing_JohnTest__1jqsZ {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 20px;
    padding: 15px 15px !important;
}

.Landing_JohnTest__1jqsZ > div {
    color: white !important;
}

.Landing_SpaceBetween__3HC7K {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Landing_FeaturedProfile__3yY4e {
    width: calc(33% - 10px);
    box-sizing: border-box;
    margin: 10px 0;
    display: -webkit-flex;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Landing_SeeAll__9cxGK {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 120px;
    border-radius: 100%;
    font-size: 13px;
    text-align: center;
    text-decoration: underline;
}

.Landing_BorderContainer__2XXNE {
    width: 100%;
    height: 258px;
    border: 1px solid var(--color-border-light);
    border-radius: 5px;
}

.Landing_BuildingFuture__2rMhr {
    background-color: rgba(10, 37, 64, 1);
    padding-top: 250px !important;
    padding-bottom: 200px !important;
    color: white;
}

.Landing_BuildingFutureSplitHeader__R2k_9 {
    width: 50%;
}

.Landing_BuildingFutureSplit__CX3Hk {
    padding-right: 20px;
    box-sizing: border-box;
    width: 50%;
}

.Landing_BuildingFutureSplitHeader__R2k_9 h2 {
    font-size: 50px;
}

.Landing_BuildingFutureSplitHeader__R2k_9 h3 {
    font-size: 30px;
    margin: 0;
}

.Landing_BuildingFutureHighlight__USJ9B {
    color: #1BB2D3;
}

.Landing_JoinNetworkButton__v-P0z {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 50px;
}

.Landing_FocusAreasContainerOuter__3nLSS {
    padding-top: 0;
}

.Landing_FocusAreasContainer__3sQHo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 90%;
    color: white;
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 0 5px 10px 2px rgba(0,0,0, 0.2);
    border-radius: 10px;
    position: relative;
}

.Landing_FocusAreasHeader__n6kBe {
    padding-top: 50px;
    width: 50%;
}

.Landing_FocusAreasHeader__n6kBe h3 {
    font-size: 30px;
    margin: 0;
    color: #1BB2D3;
}

.Landing_FocusAreasHeader__n6kBe h2 {
    font-size: 50px;
    margin: 10px 0 20px 0;
}

.Landing_FocusAreaSelectedIconContainer__FEPMA {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px;
    -webkit-align-items: center;
            align-items: center;
    height: 100px;
}

.Landing_FocusAreaSelectedIconContainer__FEPMA img {
    width: 65px;
    -webkit-filter: invert(88%) sepia(83%) saturate(0%) hue-rotate(10deg) brightness(111%) contrast(101%);
            filter: invert(88%) sepia(83%) saturate(0%) hue-rotate(10deg) brightness(111%) contrast(101%);
    margin-right: 20px;
    -webkit-animation: Landing_fade-translate-focus-area__2fP9M 1s ease-out;
            animation: Landing_fade-translate-focus-area__2fP9M 1s ease-out;
    height: 65px;
}

.Landing_FocusAreaSelectedIconContainer__FEPMA h2 {
    margin: 0;
    font-size: 35px;
    -webkit-animation: Landing_fade-translate-focus-area__2fP9M 1s ease-out;
            animation: Landing_fade-translate-focus-area__2fP9M 1s ease-out;
}

.Landing_FocusAreaDescription__3ERDy {
    min-height: 100px;
    -webkit-animation: Landing_fade-translate__1BoM8 1s ease-out;
            animation: Landing_fade-translate__1BoM8 1s ease-out;
}

.Landing_FocusAreaTabs__3H7nB {
    width: 800px;
    max-width: 50%;
    display: -webkit-flex;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 0 0 0;
}

.Landing_FocusAreaTextTabs__2eF5T {
    width: 100%;
    max-width: var(--page-width);
    margin-right: auto;
    margin-left: auto;
    display: -webkit-flex;
    display: flex;
    background: none;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 0 20px;
}

.Landing_FocusAreaTab__12rMY {
    width: calc(100% / 7 - 28px);
    margin: 0 14px;
    position: relative;
    height: 3px;
    background-color: white;
    opacity: .4;
    transition: opacity .2s linear;
}

.Landing_FocusAreaTabActive__3JwAW {
    opacity: 1;
    transition: opacity .2s linear;
}

.Landing_FocusAreaTabText__1PkS5 {
    width: calc(100% / 7);
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 10px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: bold;
    color: var(--color-text-secondary);
    transition: color .1s linear;
}

.Landing_FocusAreaTabTextActive__3YuTG {
    color: var(--color-text-dark);
    transition: color .1s linear;
}


.Landing_ContactLeft__3QNnr {
    width: 60%;
    padding-right: 20px;
    box-sizing: border-box;
}

.Landing_ContactRight__3EsLx {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 40%;
}

.Landing_ContactUsContainer__130UF {
    background: rgba(255, 255, 255, 0.75);
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    -webkit-animation: Landing_fade-translate__1BoM8 1s ease-out;
            animation: Landing_fade-translate__1BoM8 1s ease-out;
}

.Landing_Questions__31y0O {
    width: 100%;
}


.Landing_FrequentlyAskedQuestions__2ZdYX {
    padding-bottom: 200px;
    padding-top: 200px;
    position: relative;
}

.Landing_QuestionsDecorationBox1__2gOhZ {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #0A2540;
    height: 100px;
    width: 400px;
}

.Landing_QuestionsDecorationBox2__rVFk4 {
    background-color: rgba(27, 178, 211, .6);
    position: absolute;
    bottom: 60px;
    left: 0;
    height: 80px;
    width: 250px;
}


@media (max-width: 1200px) {
    .Landing_Container__1jnml {
        padding: 90px 20px 0 20px;
    }

    .Landing_LandingTitle__Myzm4 {
        font-size: 55px !important;
    }

    .Landing_LandingLeft__16I_t {
        padding: 0;
        box-sizing: border-box;
        position: relative;
        z-index: 100;
        -webkit-justify-content: center;
                justify-content: center;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        margin-top: 70px;
    }

    .Landing_LandingTitle__Myzm4 {
        text-align: center;
    }

    .Landing_Description__3XN7t {
        color: var(--color-text-dark-secondary);
        font-size: 20px;
        width: 900px;
        margin-bottom: 25px;
        padding: 0;
        box-sizing: border-box;
        text-align: center;
    }
    .Landing_LandingRight__2H-at {
        margin-top: 150px;
        width: 100%;
    }

    .Landing_SignupContainer__sHhPl {
        width: 100% !important;
    }

    .Landing_BuildingFutureSplitHeader__R2k_9 {
        width: 100%;
    }

    .Landing_FeaturedProfilesContainer__qsjn_ {
        background-image: none;
        background-size: 100%;
    }

    .Landing_FeaturedProfile__3yY4e {
        width:  calc(50% - 10px);
    }

    .Landing_FocusAreasHeader__n6kBe {
        width: 100%;
    }

    .Landing_FocusAreasContainer__3sQHo h2 {
        font-size: 20px;
    }

    .Landing_FocusAreasContainer__3sQHo {
        width: 100%;
    }

    .Landing_FocusAreasContainerOuter__3nLSS {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .Landing_FocusAreaTabText__1PkS5 {
        width: calc(100% / 3);
    }

    .Landing_FocusAreaDescription__3ERDy {
        height: 300px;
        width: 100%;
    }

    .Landing_ContactLeft__3QNnr {
        width: 100%;
        margin-bottom: 60px;
    }

    .Landing_ContactRight__3EsLx {
        width: 100%;
    }

    .Landing_ContactUsContainer__130UF {
        width: 100%;
        box-shadow: none;
        margin-bottom: 200px;
    }

    .Landing_QuestionsDecorationBox1__2gOhZ {
        height: 60px;
        width: 300px;
    }

    .Landing_QuestionsDecorationBox2__rVFk4 {
        bottom: 40px;
        height: 50px;
        width: 150px;
    }
}

@media (max-width: 700px) {
    .Landing_FeaturedProfile__3yY4e {
        width: 100%;
        margin: 0;
    }

    .Landing_LandingLeft__16I_t {
        margin-top: 0;
    }

    .Landing_LandingContainer__2Fkv8 {
        padding: 40px 10px 0 10px;
    }

    .Landing_LandingTitle__Myzm4 {
        font-size: 40px !important;
    }

    .Landing_Description__3XN7t {
        font-size: 16px !important;
    }

    .Landing_FullWidth__3a6-Y {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .Landing_RightHalf__2GjOh {
        width: 100%;
        border-radius: 0 0 20px 20px;
        height: 300px;
        padding: 15px 20px;
    }

    .Landing_RightHalf__2GjOh h2 {
        font-size: 22px;
    }

    .Landing_LeftHalf__ARt2e {
        width: 100%;
        height: 300px;
        border-radius: 20px 20px 0 0;
    }
}

.QuestionnaireBar_Container__1joJI {
    position: relative;
    width: 100%;
    margin-bottom: -90px;
    max-width: 100%;
}

.QuestionnaireBar_SearchBar__3fnNy {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0 0 8px -3px rgba(0,0,0, 0.5);
    border-radius: 45px;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 30px;
    position: relative;
    background-color: white;
    -webkit-align-items: center;
            align-items: center;
    max-width: 100%;
}

.QuestionnaireBar_QuestionBubble__1Mlfd {
    padding: 17px 27px;
    font-size: 12px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: left;
    min-width: 200px;
    max-width: 100%;
}

.QuestionnaireBar_Question__mlNV1 {
    position: relative;
}

.QuestionnaireBar_Question__mlNV1:hover {
    cursor: pointer;
}

.QuestionnaireBar_Question__mlNV1 svg {
    width: 24px;
    max-height: 22px;
    margin-right: 18px;
    color: var(--color-text-dark-secondary);
}

.QuestionnaireBar_QuestionSearch__aqqmS {
    height: 48px;
    border-radius: 25px;
    background-color: var(--color-primary);
    color: white;
    margin-right: 10px;
    margin-left: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0 15px;
    cursor: pointer;
}

.QuestionnaireBar_QuestionSearch__aqqmS svg {
    width: 17px;
    margin-right: 10px;
}

.QuestionnaireBar_ExploreIcon__OHTzp {
    display: none;
}

.QuestionnaireBar_QuestionActive__35gMd {
    box-shadow: 2px 4px 12px -3px rgba(0,0,0, 0.5);
    border-radius: 45px;
}

.QuestionnaireBar_QuestionActive__35gMd > .QuestionnaireBar_QuestionBubble__1Mlfd {
    box-shadow: 0 0 0 1px var(--color-text-dark);
    width: 100%;
    height: 100%;
    border-radius: 45px;
    transition: box-shadow .2s;
}

.QuestionnaireBar_QuestionBubble__1Mlfd > div > div {
    color: var(--color-text-dark) !important;
    font-weight: bold;
}

.QuestionnaireBar_QuestionOptions__3iybl {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    box-shadow: 2px 3px 9px -3px rgba(0,0,0, 0.5);
    background-color: white;
    top: 80px;
    border-radius: 10px;
    border: 1px solid var(--color-border-light);
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-width: 500px;
    z-index: 1000000000;
}

.QuestionnaireBar_LeftOptions__2cWdx {
    left: 0;
}

.QuestionnaireBar_QuestionDivider__26nGn {
    height: 40px;
    width: 1px;
    background-color: var(--color-border-light);
}

.QuestionnaireBar_QuestionDividerHidden__1HrDr {
    visibility: hidden;
}

.QuestionnaireBar_MiddleOptions__2PHW7 {
    left: 180px;
}

.QuestionnaireBar_RightOptions__2fnpL {
    right: 230px;
}

.QuestionnaireBar_QuestionOption__3cyin {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 15px 25px;
    font-size: 16px;
    color: var(--color-text-dark-secondary);
    cursor: pointer;
    box-sizing: border-box;
}

.QuestionnaireBar_QuestionOptionFull__rwNI5 {
    width: 100%;
}

.QuestionnaireBar_QuestionOption__3cyin:hover {
    background-color: var(--color-input-background-light);
}

.QuestionnaireBar_QuestionOption__3cyin svg {
    width: 22px;
    max-height: 20px;
    margin-right: 20px;
    color: var(--color-text-dark);
}

.QuestionnaireBar_Question__mlNV1 h3 {
    font-size: 14px;
    margin: 0;
    margin-bottom: 3px;
}

.QuestionnaireBar_Question__mlNV1 div  > div{
    color: var(--color-text-secondary);
    font-size: 14px;
}

.QuestionnaireBar_SearchInput__2dJuU {
    font-weight: bold;
    padding: 20px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;

}

.QuestionnaireBar_SearchInput__2dJuU {
    padding: 10px;
}

.QuestionnaireBar_SearchInput__2dJuU svg {
    width: 15px;
    margin-right: 30px;
}

.QuestionnaireBar_ContentMobile__KDTY0 {
    display: none;
}

@media (max-width: 1200px) {
    .QuestionnaireBar_SearchBar__3fnNy {
        margin-left: auto;
        margin-right: auto;
    }

    .QuestionnaireBar_QuestionBubble__1Mlfd {
        min-width: 100px;
    }

    .QuestionnaireBar_Question__mlNV1 h3 {
        font-size: 12px;
    }

    .QuestionnaireBar_LeftOptions__2cWdx {
        left: 165px;
    }
}

@media (max-width: 700px) {
    .QuestionnaireBar_ContentNonMobile__3zcr3 {
        display: none;
    }

    .QuestionnaireBar_ContentMobile__KDTY0 {
        display: block;
        font-size: 11px !important;
    }

    .QuestionnaireBar_Question__mlNV1 {
        width: calc(33.33% - (40px / 3));
    }

    .QuestionnaireBar_Question__mlNV1 h3 {
        font-size: 12px;
    }

    .QuestionnaireBar_QuestionBubble__1Mlfd {
        padding: 17px 10px;
        box-sizing: border-box;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        text-align: left;
        min-width: 100%;
        width: calc(33.33% - (50px / 3)) !important;
    }

    .QuestionnaireBar_QuestionSearch__aqqmS {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0 5px;
    }

    .QuestionnaireBar_QuestionSearch__aqqmS svg {
        margin: 0;
    }

    .QuestionnaireBar_ExploreIcon__OHTzp {
        display: -webkit-flex;
        display: flex;
    }

    .QuestionnaireBar_ExploreText__223ib {
        display: none;
    }

    .QuestionnaireBar_SelectText__3LLLq {
        display: none;
    }

    .QuestionnaireBar_SearchBar__3fnNy {
        width: 100%;
    }

    .QuestionnaireBar_QuestionOptions__3iybl {
        width: 100%;
    }

    .QuestionnaireBar_MiddleOptions__2PHW7 {
        left: 0;
    }

    .QuestionnaireBar_LeftOptions__2cWdx {
        left: 0;
    }

    .QuestionnaireBar_RightOptions__2fnpL {
        left: 0;
    }

    .QuestionnaireBar_QuestionOption__3cyin {
        font-size: 12px;
        padding: 10px 25px;
    }
}


@media (max-width: 400px) {
    .QuestionnaireBar_Question__mlNV1 h3 {
        font-size: 11px;
    }

    .QuestionnaireBar_Question__mlNV1 svg {
        display: none;
    }
}

.SubmitButton_SpinnerContainer__2nYBJ {
    height: 100%;
    padding-right: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.SubmitButton_Container__2X82e {
    border: none;
    outline: none;
    padding: 15px 0;
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    background-color: var(--color-primary);
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;
}

.SubmitButton_FullWidth__1pwHr {
    width: 100%;
}

.SubmitButton_Label__j4e7- {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.SubmitButton_ContainerSubmitting__j-FrM {
    background-color: white;
    box-shadow: 0 0 0 1px var(--color-border-light);
    color: #a8aebd;
    cursor: default;
}

.spinner_Container__2keA2 {
    height: 13px;
    width: 13px;
    margin-left:-13px;
    margin-top:-13px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
}

.spinner_Spinner__3vQdt:before {
    content:"";
    box-sizing: border-box;
    position: absolute;
    -webkit-align-items: center;
            align-items: center;
    height:13px;
    width:13px;
    border-radius: 50%;
    border-top: 2px solid;
    border-right: 2px solid;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    -webkit-animation: spinner_spinner__2AyWI 0.7s linear infinite;
            animation: spinner_spinner__2AyWI 0.7s linear infinite;
}

@-webkit-keyframes spinner_spinner__2AyWI {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spinner_spinner__2AyWI {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.Carousel_Test__2_b01 {
    position: relative;
}

.Carousel_ImagesContainer__1S0Fl {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.Carousel_ImagesContainerInner__3f6n5 {
    display: -webkit-flex;
    display: flex;
    transition: margin-left .3s;
    position: relative;
}

.Carousel_Arrow__3TBm7 {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 100;
    cursor: pointer;
    font-size: 25px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, .3);
}

.Carousel_ArrowRight__iDjJY {
    top: calc(50% - 15px);
    right: -13px;
    background-color: white;
}

.Carousel_ArrowLeft__3BNLY {
    top: calc(50% - 15px);
    left: -13px;
    background-color: white;
}

.Carousel_ArrowRight__iDjJY svg, .Carousel_ArrowLeft__3BNLY svg {
    width: 8px;
}

.Carousel_Dots__3hN7K {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.Carousel_Dot__32UTb {
    color: var(--color-text-secondary);
    font-size: 6px;
    margin: 2px;
}

.Carousel_ActiveDot__2uiOB {
    color: var(--color-text-dark);
}

.SearchResult_Container__H8SU6 {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    border-top: 1px solid var(--color-border-light);
    padding: 0 20px;
}

.SearchResult_Container__H8SU6:hover {
    background-color: var(--color-input-background-very-light);
}

.SearchResult_Container__H8SU6:last-child {
    border-bottom: 1px solid var(--color-border-light);
}

.SearchResult_SearchResult__3kwaF {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    padding: 20px 0;
}

.SearchResult_Header__3Z4v9 {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.SearchResult_Left__3lLl5 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.SearchResult_Right__3pjt7 {
    width: 100%;
    padding-left: 65px;
    box-sizing: border-box;
}

.SearchResult_InvestorTypeBadge__3xHX0 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius:20px;
    padding: 0 10px;
    font-weight: 400;
    border: 1px solid var(--color-border-light);
}

.SearchResult_LinkContainer__5gm04 {
    text-decoration: none;
    color: var(--color-text-dark);
}

.SearchResult_Logo__2psa- {
    width: 50px;
    height: 50px;
    position: relative;
}

.SearchResult_Metadata__3OYXY {
    margin-left: 15px;
}

.SearchResult_Metadata__3OYXY h3 {
    font-size: 16px;
    margin: 0;
}

.SearchResult_Metadata__3OYXY h3:hover {
    text-decoration: underline;
}

.SearchResult_Title__3Zv6l h1 {
    margin: 0;
    font-size:20px;
}

.SearchResult_Description__3UJGz {
    font-size: 15px;
}

.SearchResult_Description__3UJGz p {
    margin: 0;
}

.SearchResult_SubTitle___GEMw {
    width: 100%;
    font-size: 14px;
    color: var(--color-text-dark-secondary);
}

.SearchResult_SubTitle___GEMw i {
    margin-right: 5px;
}

.SearchResult_Technologies__5jl8C {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-top: 10px;
}

.SearchResult_Technology__3_Wkn {
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    background-color: var(--color-text-dark-secondary);
    color: white;
    padding: 0 5px;
    border-radius: 3px;
    font-weight: bold;
}

.SearchResult_Technology__3_Wkn img {
    width: 100%;
    -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(46deg) brightness(102%) contrast(101%);
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(46deg) brightness(102%) contrast(101%);
}


@media (max-width: 700px) {
    .SearchResult_Container__H8SU6 {
        border-left: none;
        border-right: none;
        padding: 0 10px;
    }

    .SearchResult_Logo__2psa- {
        width: 40px;
        height: 40px;
        position: relative;
    }

    .SearchResult_Right__3pjt7 {
        padding-left: 0;
        margin-top: 10px;
    }
}

.noresult_NoResultsContainer__2Tquc {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 100px 10px;
    box-sizing: border-box;
}

.noresult_NoResultsInner__IGm6T {
    font-size: 20px;
}

.Explore_SideNav__1UNDt {
    width: 220px;
}

.Explore_Header__-uYlf {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px 0 10px 10px;
    min-width: 0;
    min-height: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--color-border-light);
}

.Explore_HeaderOption__2mKXb {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 45px;
    box-shadow: 0 0 0 1px var(--color-border-light);
    background-color: var(--color-input-background-light);
    transition: box-shadow 0.1s ease-in-out;
    margin-right: 8px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 15px;
}

.Explore_HeaderOption__2mKXb i {
    margin-right: 7px;
}

.Explore_HeaderOptionActive__3LBWb {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 45px;
    transition: box-shadow 0.1s ease-in-out;
    margin-right: 8px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 15px;
    font-weight: bold;
    background-color: var(--color-primary);
    color: white;
}

.Explore_SearchResults__1vwZc {
    width: calc(100% - 220px - 340px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    position: relative;
    box-sizing: border-box;
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
}

.Explore_ResultsAll__1Xx8p {
    width: 100%;
    position: relative;
}

.Explore_ResultsAllHeader__aOg0k {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0 0 0;
    color: var(--color-text-dark-secondary);
    font-size: 15px;
}

.Explore_ResultsAllLink__JhtJh {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 10px 0 25px 0;
}

.Explore_ResultsAllLink__JhtJh a {
    text-decoration: none;
    color: #606060;
    font-weight: bold;
    cursor: pointer;
    padding: 4px 8px;
    width: 30%;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    font-size: 12px;
    text-transform: uppercase;
}

.Explore_SearchResult__15_gZ {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    background-color: white;
    box-sizing: border-box;
    padding: 20px;
}

.Explore_SearchResult__15_gZ:hover {
    background-color: rgba(205, 214, 229, .15);
}

.Explore_Footer__1ok9X {
    width: 100%;
    margin-top: 20px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    max-height: 50px;
    display: -webkit-flex;
    display: flex;
}

.Explore_TotalResults__ezaS_ {
    -webkit-flex: 1 1;
            flex: 1 1;
    font-size: 14px;
    text-align: right;
    padding: 5px;
}

.Explore_SearchResultsContainer__35RKy {
    width: 100%;
}

.Explore_EndOfResults__2goc4 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 60px;
    font-weight: bold;
}

.Explore_SpinnerContainer__Wot2X {
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

@media (max-width: 1200px) {
    .Explore_SideNav__1UNDt {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .Explore_SearchResults__1vwZc {
        width: calc(100% - 220px - 15px);
        margin-right: 15px;
    }

    .Explore_SearchFilters__1Efvo {
        margin-top: 0;
        width: 100%;
    }

    .Explore_SearchResultContainer__2ceox {
        width: 100%;
        height: auto;
        padding: 0;
    }

    .Explore_SearchPageContainer__3Piw- {
        min-height: calc(100% - 170px);
    }

    .Explore_TotalResults__ezaS_ {
        padding-right: 5px;
    }
}

.Explore_CreateBottom__3tmjW {
    width: 100%;
    height: 15px;
}

.Explore_CreatePlaceholder__3EBqi {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid var(--color-border-light);
    padding: 20px;
}

.Explore_CreatePlaceholder__3EBqi h3 {
    margin: 0;
    font-size: 22px;
}

.Explore_CreatePlaceholderDescription__1cKK4 {
    color: var(--color-text-dark-secondary);
}

.Explore_CreatePlaceholderButton__3S7jX {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background: white;
}

@media (max-width: 700px) {

    .Explore_Header__-uYlf {
        padding-left: 10px;
    }

    .Explore_SideNav__1UNDt {
        display: none;
    }

    .Explore_SearchResults__1vwZc {
        width: 100%;
        padding-right: 0;
        border-left: none;
        border-right: none;
        margin-right: 0;
    }
}

@-webkit-keyframes PlaceHolder_placeHolderShimmer__3McNH{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes PlaceHolder_placeHolderShimmer__3McNH{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.PlaceHolder_Container__2nHfm {
    margin-top: 20px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.PlaceHolder_SplitContainer__17qwj {
    display: -webkit-flex;
    display: flex;
}

.PlaceHolder_Profile__ZcJGf {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 15px;
}

.PlaceHolder_Metadata__1Ed3g {
    width: calc(100% - 70px);
    padding-left: 20px;
    box-sizing: border-box;
}

.PlaceHolder_TitleStrip__Dx-z5 {
    width: 30%;
    height: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.PlaceHolder_Strip__3qQPc {
    width: 100%;
    height: 12px;
    margin-bottom: 10px;
    overflow: hidden;
}

.PlaceHolder_StripThin__3qBjQ {
    width: 120px;
    height: 10px;
    overflow: hidden;
}

.PlaceHolder_Content__2kfeQ {
    width: 100%;
    padding-left: 70px;
    box-sizing: border-box;
}

.PlaceHolder_LinearBackground__1jGYU {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: PlaceHolder_placeHolderShimmer__3McNH;
            animation-name: PlaceHolder_placeHolderShimmer__3McNH;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}


@media (max-width: 700px) {
    .PlaceHolder_Content__2kfeQ {
        padding-left: 0;
    }
}

.Recommendations_Container__24IzV {
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    border-radius: 20px;
    font-size: 15px;
    background-color: var(--color-input-background-very-light);
}

.Recommendations_Container__24IzV h2 {
    font-size: 20px;
    padding: 0 0 10px 0;
    margin: 0;
    border-bottom: 1px solid var(--color-border-light);
}

.Recommendations_Recommendation__k7c2F {
    width: 100%;
    padding-top: 15px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 10px;
}

.Recommendations_Logo__2ljuv {
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.Recommendations_Title__Ra97b {
    margin: 0 0 0 10px;
    max-width: calc(100% - 60px - 80px);
}

.Recommendations_Title__Ra97b h3:hover {
    cursor: pointer;
    text-decoration: underline;
}

.Recommendations_RecommendationTitle__3jxVB {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 5px 0;
}

.Recommendations_RecommendationTitle__3jxVB h3 {
    font-size: 15px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.Recommendations_RecommendationSubtitle__3kFak {
    color: var(--color-text-secondary);
    font-size: 14px;
    margin-bottom: 5px;
    width: 100%;
}

.Recommendations_RecommendationSubtitle__3kFak span {
    margin-right: 5px;
}

.Recommendations_FollowButtonContainer__3IAgW {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.Recommendations_FollowButton__2zPee {
    padding: 2px 10px;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    color: var(--color-primary);
    cursor: pointer;
    margin-left: 10px;
}

.Recommendations_FollowButtonFollowing__3ZK_I {
    background: var(--color-primary);
    color: white;
}

@-webkit-keyframes PlaceHolder_placeHolderShimmer__3y8eM{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes PlaceHolder_placeHolderShimmer__3y8eM{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.PlaceHolder_Container__22bQf {
    margin-top: 20px;
    width: 100%;
    padding: 15px 10px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.PlaceHolder_PlaceHolderLeft__KYmS8 {
    width: 40px;
    position: relative;
}

.PlaceHolder_PlaceHolderRight__29MUt {
    width: calc(100% - 40px);
    padding-left: 10px;
    box-sizing: border-box;
    position: relative;

}

.PlaceHolder_Profile__3N5_T {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 15px;
}

.PlaceHolder_TitleStrip__3cyXu {
    width: 50%;
    height: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.PlaceHolder_Strip__3eT6N {
    width: 100%;
    height: 12px;
    margin-bottom: 10px;
    overflow: hidden;
}

.PlaceHolder_LinearBackground__2-GCo {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: PlaceHolder_placeHolderShimmer__3y8eM;
            animation-name: PlaceHolder_placeHolderShimmer__3y8eM;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #d4d7d9 8%, #e4e7e9 18%, #d4d7d9 33%);
    background-size: 1000px 104px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.FollowButton_FollowButton__2Fl-M {
    padding: 0 13px;
    font-weight: 400;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    margin-left: 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 15px;
}

.FollowButton_FollowButton__2Fl-M:hover {
    background-color: var(--color-primary-washed);
}

.FollowButton_FollowButtonFollowing__2ZA0c {
    background: var(--color-primary);
    color: white;
}

.FollowButton_FollowButtonFollowing__2ZA0c:hover {
    background: var(--color-primary);
    color: white;
}

.SelectedContext_Dropdown__25KG_ {
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 10px 1px rgba(0,0,0, 0.3);
    border-radius: 5px;
    z-index: 2000;
}

.SelectedContext_DropdownOption__83aVS {
    padding: 7px 10px;
    width: 150px;
    cursor: pointer;
}

.SelectedContext_DropdownOption__83aVS:hover {
    background-color: var(--color-border-light);
}

.SelectedContext_HeaderOptionActive__1BvGY i {
    margin-right: 7px;
}

.SelectedContext_HeaderOptionActive__1BvGY {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 45px;
    margin-right: 10px;
    font-size: 15px;
    background-color: var(--color-primary);
    color: white;
    font-weight: bold;
    transition: background-color .1s linear, color .1s linear;
}

.SelectedContext_Caret__5hlAC i {
    margin: 0 0 0 6px;
}

.SearchFilters_FilterOptions__2FYdi {
    margin: 10px 0;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.SearchFilters_FilterOptionsCollapsed__jpVQC {
    display: none;
}

.SearchFilters_FilterOption__3ICa1 {
    padding: 4px 8px;
    cursor: pointer;
    color: var(--color-text-dark-secondary);
    font-weight: 400;
    font-size: 14px;
    margin: 5px 10px 5px 0;
    border-radius: 40px;

}

.SearchFilters_FilterOptionSelected__3VVbF {
    background-color: var(--color-input-background-light);
    color: var(--color-primary);
}

.SearchFilters_CloseFiltersButton__3_xss {
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    bottom: -16px;
    right: 50%;
    z-index: 1000;
    background-color: var(--color-text-dark);
    border-radius: 100%;
    color: white;
    box-shadow: 0 6px 9px -3px rgba(0,0,0, 0.2);
}

.SearchFiltersScrollMenu_ScrollMenu__1FO6W {
    -webkit-flex: 1 0;
            flex: 1 0;
    position: relative;
    overflow: hidden;
}

.SearchFiltersScrollMenu_ScrollMenuContainer__3cguY {
    padding: 1px 0;
    border-left: 1px solid var(--color-border-light);
    width: 100%;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    overflow-x: auto;
    -webkit-flex-direction: row;
            flex-direction: row;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    min-width: 0;
    min-height: 0;
    position: relative;
}

.SearchFiltersScrollMenu_ScrollMenuContainer__3cguY::-webkit-scrollbar {
    display: none;
}

.SearchFiltersScrollMenu_CaretLeft__2lq4C {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.SearchFiltersScrollMenu_CaretLeftContent__V-5zR {
    background-color: white;
    padding: 0 12px;
    box-sizing: border-box;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    margin-left: 1px; /* TODO: this is a hack to not extend over the divider border*/
}

.SearchFiltersScrollMenu_CaretLeftContent__V-5zR:after {
    width: 30px;
    height: 100%;
    content: '';
    top: 0;
    right: -30px;
    position: absolute;
    background-image: linear-gradient(to left, rgba(255,255,255, .0), rgba(255,255,255, 1));
}

.SearchFiltersScrollMenu_CaretRight__2cyIt {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.SearchFiltersScrollMenu_CaretRightContent__1J1za {
    background-color: white;
    padding: 0 12px;
    box-sizing: border-box;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.SearchFiltersScrollMenu_CaretRightContent__1J1za:after {
    width: 30px;
    height: 100%;
    content: '';
    top: 0;
    left: -30px;
    position: absolute;
    background-image: linear-gradient(to right, rgba(255,255,255, .0), rgba(255,255,255, 1));
}

.SearchFiltersScrollMenu_CaretRight__2cyIt svg, .SearchFiltersScrollMenu_CaretLeft__2lq4C svg {
    width: 9px;
}

.SearchFiltersScrollMenu_FilterHeader__1E0Pl {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 14px;
    font-weight: 400;
    box-shadow: 0 0 0 1px var(--color-border-light);
    background-color: var(--color-input-background-light);
    transition: box-shadow 0.1s ease-in-out;
    border-radius: 25px;
    padding: 5px 10px;
    box-sizing: border-box;
    margin: 0 0 0 10px;
}

.SearchFiltersScrollMenu_FilterHeaderSelected__15yzS {
    box-shadow: 0 0 0 1px var(--color-text-dark);
    color: var(--color-text-dark);
}

.SearchFiltersScrollMenu_FilterHeaderPseudo__3NS48 {
    visibility: hidden;
}

.SearchFiltersScrollMenu_FilterHeaderLabel__2_jIe {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.SearchFiltersScrollMenu_Caret__2JzLj {
    margin-left: 5px;
}

.SearchFiltersScrollMenu_FilterCount__21gSb {
    background-color: var(--color-primary);
    color: white;
    font-weight: bold;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: 8px;
}

.SideNav_Container__2maVe {
    width: 100%;
    padding-right: 30px;
    box-sizing: border-box;
    height: calc(100vh - 80px);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-top: 20px;
    background-color: white;
    overflow-y: auto;
}

.SideNav_NavGroup__11-Kc {
    margin-bottom: 10px;
    padding-bottom: 15px;
}

.SideNav_NavGroup__11-Kc a {
    text-decoration: none;
    color: inherit;
}

.SideNav_NavGroupNews__GVn5v {
    border-top: 1px solid var(--color-input-background);
    border-bottom: 1px solid var(--color-input-background);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 15px 0;
}

.SideNav_NavGroupNews__GVn5v a {
    text-decoration: none;
    color: inherit;
}

.SideNav_NavHeader__10Snw {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    font-size: 14px;
    color: var(--color-text-dark-secondary);
    transition: background-color .05s linear, color .05s linear;
    text-transform: uppercase;
}

.SideNav_NavHeader__10Snw span {
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
}


.SideNav_NavOption__1rchp {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    padding: 8px 10px 8px 10px;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 16px;
    background: none;
    transition: background-color .05s linear, color .05s linear;
}

.SideNav_CreateProfileButton__11rXO {
    padding: 10px 0;
    font-size: 15px;
    margin: 10px;
    border: 1px solid var(--color-primary);
    background-color: white;
    color: var(--color-primary);
}

.SideNav_CreateProfileButton__11rXO:hover {
    background-color: var(--color-primary-washed);
}

.SideNav_CreateProfileButtonSubText__15wIR {
    color: var(--color-text-dark-secondary);
    font-size: 15px;
    width: 100%;
    margin-top: 20px;
    padding: 0 10px;
}

.SideNav_NavOption__1rchp svg {
    width: 19px;
    max-height: 17px;
    margin-right: 20px;
    color: var(--color-text-dark-secondary);
}

.SideNav_NavOption__1rchp:hover {
    background-color: var(--color-input-background-light);
    transition: background-color .1s linear;
}

.SideNav_NavOptionActive__1QrF0 > .SideNav_NavOption__1rchp {
    color: var(--color-primary);
}

.SideNav_NavOptionActive__1QrF0 > .SideNav_NavOption__1rchp > svg {
    color: var(--color-primary) !important;
}

.SideNav_FooterPage__1iL3h {
    margin-top: 40px;
    color: var(--color-text-secondary);
    font-size: 12px;
    font-weight: bold;
    padding: 10px; box-sizing: border-box;
}

.SideNav_ContactOption__2d0vT {
    padding: 4px 0;
}

.SideNav_ContactOption__2d0vT a {
    text-decoration: none;
    color: inherit;
}

.FeedPost_FeedPost__1fZ7J {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    font-size: 15px;
    border-bottom: 1px solid var(--color-border-light);
    padding: 0 20px;
}

.FeedPost_FeedPost__1fZ7J:hover {
    background-color: var(--color-input-background-very-light);
}

.FeedPost_CommentReplyToConent__2_dQB {
    padding: 10px;
    box-sizing: border-box;
}

.FeedPost_FeedPostPrimary__NOyjO {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    box-sizing: border-box;
    padding: 20px 0 10px 0;
    cursor: pointer;
}

.FeedPost_FeedPostLeft__2s44u {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.FeedPost_FeedPostLeft__2s44u a {
    text-decoration: none;
}

.FeedPost_Logo__3Oj9x {
    height: 50px;
    width: 50px;
    position: relative;
}

.FeedPost_FeedPostRight__3OiDg {
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    position: relative;
}

.FeedPost_CreateCommentPopupContainer__rzu58 {
    z-index: 1000;
    width: 560px;
    background-color: white;
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 15px 0 0 0;
    position: relative;
}

.FeedPost_CloseCommentIconContainer__1iq9C {
    width: 35px;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;
}

.FeedPost_CloseCommentIconContainer__1iq9C:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

.FeedPost_CloseCommentIconContainer__1iq9C svg {
    width: 14px;
}

.FeedPost_DeleteFeedPostContainer__1SnUE {
    padding: 60px 100px;
    background-color: white;
    border-radius: 4px;
    position: relative;
}

.FeedPost_DeleteSubmitContainer__3zpkI {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 40px;
}

.FeedPost_FeedPostOptions__2BKoF {
    z-index: 10000;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    color: var(--color-text-dark-secondary);
}

.FeedPost_FeedPostOption__3787s {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #AAB0BF;
    cursor: pointer;
}

.FeedPost_FeedPostOption__3787s:hover {
    background-color: #F5F7FA;
}

.FeedPost_Container__1DzVp {
    width: 100%;
}

.FeedPost_FeedPostActions__3ir8A {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 10px 15px 0 0;
    box-sizing: border-box;
}

.FeedPost_FeedPostAction__2NpVI {
    margin-right: 20px;
    color: var(--color-text-dark-secondary);
    height: 40px;
    width: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
}

.FeedPost_FeedPostAction__2NpVI:hover {
    background-color: rgba(205, 214, 229, .15);
}

.FeedPost_FeedPostActionLiked__39jlU {
    color: var(--color-primary);
}

.FeedPost_FeedPostActionValue__3O1Xc {
    margin-left: 7px;
    font-size: 14px;
}

.FeedPost_EditContainer__gtsoP {
    border-top: 1px solid var(--color-border-light);
    border-bottom: 1px solid var(--color-border-light);
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
}

.FeedPost_EditSubmitContainer__1fvll {
    width: 100%;
}

.FeedPost_EditSubmitCancelButton__qIU1S {
    background-color: white;
    border: 1px solid #CDD6E5;
    font-size: 14px;
    color: #7c859c;
}

.FeedPost_EditSubmitButton__16DHs {
    font-size: 14px;
    margin-left: 10px;
}

.FeedPost_FeedPostHeader__3zLM0 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 14px;
    margin-left: 15px;
}

.FeedPost_FeedPostHeader__3zLM0 h3 {
    margin: 0;
    width: 100%;
}

.FeedPost_FeedPostHeader__3zLM0 h3 a {
    color: inherit;
}

.FeedPost_FeedPostHeader__3zLM0 h3 a:hover {
    text-decoration: underline;
}

.FeedPost_FeedPostHeader__3zLM0 h3 i {
    color: #a8aebd;
    font-size: 14px;
}

.FeedPost_Follow__1SrN- {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.FeedPost_Timestamp__i6F2O {
    color: #a8aebd;
}

.FeedPost_Timestamp__i6F2O span {
    margin-right: 6px;
    font-weight: bold;
    color: #757887;
}

.FeedPost_FeedPostContent__634Gs {
    font-weight: 400;
    word-wrap: break-word;
    padding-left: 65px;
    padding-right: 15px;
    box-sizing: border-box;
}

.FeedPost_FeedPostContent__634Gs pre {
    margin: 0;
    font-size: 15px;
}

.FeedPost_FeedPostText__1WXhH {
    margin-bottom: 10px;
}

.FeedPost_Images__2CngD img {
    margin-top: 10px;
    width: 100%;
    max-height: 500px;
}

.FeedPost_Videos__2XiuP video {
    margin-top: 10px;
    width: 100%;
}

.FeedPost_Input__OBC0s {
    margin-right:0;
    margin-left: auto;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.FeedPost_Input__OBC0s textarea {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    height: 100%;
    min-height: 100px;
    min-width: 100%;
    font-size: 16px;
    padding: 13px;
    box-sizing: border-box;
    resize: none;
    transition: background-color .2s, box-shadow .2s;
}

.FeedPost_Test__22tos {
    position: relative;
}

.FeedPost_ImagesContainer__1D50n {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.FeedPost_ImagesContainerInner__poxw1 {
    display: -webkit-flex;
    display: flex;
    transition: margin-left .3s;
    position: relative;
}

.FeedPost_ImageContainer___-DSY {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.FeedPost_ImageContainerInner__mkww0 {
    max-height: 450px;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.FeedPost_WebLinkContainerInner__1G5Oc {
    max-height: 450px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 5px;
    box-sizing: border-box;
}

.FeedPost_ImageContainerInner__mkww0 img {
    max-height: 350px;
    max-width: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.FeedPost_ImageContainerInner__mkww0 video {
    max-height: 350px;
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: var(--color-input-background-light);
    outline: none;
}

.FeedPost_WebLink__2QXTD {
    width: 100%;
}

.FeedPost_Arrow__3lvCN {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 100;
    cursor: pointer;
    font-size: 25px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, .3);
}

.FeedPost_ArrowRight__-fd7g {
    top: calc(50% - 15px);
    right: -13px;
    background-color: white;
}

.FeedPost_ArrowLeft__3EzlM {
    top: calc(50% - 15px);
    left: -13px;
    background-color: white;
}

.FeedPost_ArrowRight__-fd7g svg, .FeedPost_ArrowLeft__3EzlM svg {
    width: 8px;
}

.FeedPost_Dots__2_IfM {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.FeedPost_Dot__2MXA1 {
    color: var(--color-text-secondary);
    font-size: 6px;
    margin: 2px;
}

.FeedPost_ActiveDot__1CmhQ {
    color: var(--color-primary);
}

@media (max-width: 1200px) {
    .FeedPost_ImageContainerInner__mkww0 {
        max-height: 350px;
    }

    .FeedPost_WebLinkContainerInner__1G5Oc {
        max-height: 350px;
    }

    .FeedPost_ImageContainerInner__mkww0 img {
        max-height: 350px;
    }

    .FeedPost_ImageContainerInner__mkww0 video {
        max-height: 350px;
    }
}

@media (max-width: 700px) {
    .FeedPost_CreateCommentPopupContainer__rzu58 {
        width: 100vw;
        height: 100vh;
        padding-top: 60px;
        padding-bottom: 15vh;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    .FeedPost_FeedPostPrimary__NOyjO {
        box-sizing: border-box;
    }

    .FeedPost_FeedPostRight__3OiDg {
        padding-top: 10px;
        padding-left: 0;
    }

    .FeedPost_FeedPostContent__634Gs {
        padding: 0;
    }
}

.GlobalPopup_PopUp__wRzSL {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .2);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    z-index: 1000;
}

.GlobalPopup_PopUpInner__14_5C {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-animation: GlobalPopup_fade__3Q_cU .2s ease-out;
            animation: GlobalPopup_fade__3Q_cU .2s ease-out;
    box-shadow: 0 4px 20px 1px rgba(0,0,0, 0.3);
}

@-webkit-keyframes GlobalPopup_fade__3Q_cU {
    0% {
        opacity: 0;
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
}

@keyframes GlobalPopup_fade__3Q_cU {
    0% {
        opacity: 0;
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
}

.FeedPostOptions_Container__1ctm1 {
    position: absolute;
    top: -10px;
    right: 5px;
    z-index: 1;
}

.FeedPostOptions_ContainerInner__3kjjc {
    position: relative;
}

.FeedPostOptions_DropdownToggler__11A96 {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    transition: background-color .2s linear;
}

.FeedPostOptions_DropdownToggler__11A96:hover {
    background-color: rgba(205, 214, 229, .15);
    transition: background-color .1s linear;
}

.FeedPostOptions_OptionsDropdown__1Qi_n {
    right: 0;
    background-color: white;
    position: absolute;
    box-shadow: rgba(101, 119, 134, .2) 0 0 15px, rgba(101, 119, 134, .15) 0 0 3px 1px
}

.FeedPostOptions_OptionsDropdownInner__3fMOA {
    position: relative;
    width: 150px;
}

.FeedPostOptions_DropdownOption__2corK {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    font-size: 14px;
}

.FeedPostOptions_DropdownOption__2corK:hover {
    background-color: rgba(205, 214, 229, .15);
}

.FeedPostOptions_DropdownOption__2corK svg {
    width: 15px;
    margin-left: 10px;
}

.FeedPostContent_HashTag__2x3fx {
    color: var(--color-primary);
    cursor: pointer;
}

.FeedPostContent_Link__3FF8F {
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: none;
}

.FeedPostContent_Link__3FF8F i {
    font-size: 12px;
    margin-right: -2px;
}

.FeedPostContent_ShowMore__2420L {
    cursor: pointer;
    color: var(--color-primary);
}

.FeedSubmit_SubmitButtonContainer__1PGlz {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-left: 5px;
    height: 35px;
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
}

.FeedSubmit_Cancel__2PW2U {
    background-color: white;
    border: 1px solid var(--color-text-secondary);
    margin-right: 15px;
    color: var(--color-text-dark-secondary);
}

.FeedSubmit_FeedSubmit__2U8z6 {
    padding: 6px 15px;
}

.FeedSubmit_MediaOptions__2rPG5 {
    border-top: 1px solid #F0F1F5;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.FeedSubmit_MediaOptions__2rPG5 input {
    display: none;
}

.FeedSubmit_MediaOption__1AKc5 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 13px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    color: var(--color-primary);
}

.FeedSubmit_MediaOption__1AKc5:hover {
    background-color: var(--color-input-background-light);
}

.FeedSubmit_MediaOption__1AKc5 svg {
    height: 18px;
    color: var(--color-primary);
    margin-right: 7px;
}

.FeedSubmit_MediaPreviewContainer__QbMeh {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 10px 10px 0 10px;
    font-size: 13px;
    -webkit-align-items: center;
            align-items: center;
}

.FeedSubmit_FileSizePopup__1FdC5 {
    width: 300px;
    height: 150px;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.FeedSubmit_FileSizePopupButton__11R5w {
    padding: 8px 10px;
}

.FeedSubmit_FileSizePopupText__3kil4 {
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
}

.FeedSubmit_Exclamation__3i4Kz {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.FeedSubmit_Exclamation__3i4Kz svg {
    width: 40px;
}

.FeedSubmit_ImagePreview__2h0HX {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 3px 5px 3px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
    position: relative;
}

.FeedSubmit_ImagePreview__2h0HX svg {
    width: 15px;
    margin-right: 10px;
}

.FeedSubmit_ImagePreview__2h0HX img {
    max-width: 130px;
    max-height: 75px;
}

.FeedSubmit_VideoPreview__3RdCW {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 3px 5px 3px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
    position: relative;
}

.FeedSubmit_VideoPreview__3RdCW svg {
    width: 15px;
    margin-right: 10px;
}

.FeedSubmit_VideoPreview__3RdCW video {
    max-width: 130px;
    max-height: 75px;
}

.FeedSubmit_RemoveMedia__1kNd- {
    position: absolute;
    top: 7px;
    right: 7px;
    margin-left: 10px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 4px;
    background-color: #C1C1CD;
    border-radius: 100%;
    color: var(--color-text-dark);
}

.FeedSubmit_RemoveMedia__1kNd- svg {
    height: 15px;
    margin: 0;
}

.CreateModal_Container__SCCez {
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.CreateModal_InputContainer__180CG {
    width: 100%;
    position: relative;
    border-radius: 5px;
    transition: box-shadow 0.1s ease-in-out;
}

.CreateModal_Header__3roXa {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    height: 50px;
    margin-bottom: 10px;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    padding-left: 10px;
    padding-top: 15px;
    box-sizing: border-box;
}

.CreateModal_HeaderImage__23Swm {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.CreateModal_Header__3roXa a {
    text-decoration: none;
    color: inherit;
}

.CreateModal_Header__3roXa h3 {
    margin: 0;
}

.CreateModal_InputContainerInner__1ti-4 {
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
    min-height: 100%;
}

.CreateModal_Input__T9n2Q {
    margin-right:0;
    margin-left: auto;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.CreateModal_Input__T9n2Q textarea {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    height: 100%;
    min-height: 60px;
    min-width: 100%;
    font-size: 16px;
    padding: 13px;
    box-sizing: border-box;
    resize: none;
    transition: background-color .2s, box-shadow .2s;
}

.CreateModal_SubmitButtonContainer__2ShgG {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-left: 5px;
    height: 35px;
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
}

.CreateModal_MediaOptions__hdnb5 {
    border-top: 1px solid #F0F1F5;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.CreateModal_MediaOptions__hdnb5 input {
    display: none;
}

.CreateModal_MediaOption__l57S2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 5px 8px;
    border-radius: 4px;
    margin-right: 15px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
}

.CreateModal_MediaOption__l57S2:hover {
    background-color: var(--color-input-background-light);
}

.CreateModal_MediaOption__l57S2 svg {
    margin-right: 6px;
    width: 20px;
    max-height: 17px;
    color: var(--color-primary);
}

.CreateModal_MediaPreviewContainer__288Pa {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 10px 10px 0 10px;
    font-size: 13px;
}

.CreateModal_ImagePreview__1BEX- {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: var(--color-primary);
    background-color: var(--color-primary-washed);
    box-shadow: 0 0 0 1px var(--color-primary);
    padding: 3px 5px 3px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
}

.CreateModal_ImagePreview__1BEX- svg {
    width: 15px;
    margin-right: 10px;
}

.CreateModal_VideoPreview__3OnXK {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: rgba(0, 204, 170, 1);
    background-color: rgba(0, 204, 170, .1);
    box-shadow: 0 0 0 1px rgba(0, 204, 170, 1);
    padding: 3px 5px 3px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
}

.CreateModal_VideoPreview__3OnXK svg {
    width: 15px;
    margin-right: 10px;
}

.CreateModal_RemoveMedia__FqgUg {
    margin-left: 10px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 2px;
}

.CreateModal_RemoveMedia__FqgUg svg {
    height: 15px;
    margin: 0;
}

@media (max-width: 1100px) {

    .CreateModal_Header__3roXa {
        padding: 0 10px;
    }
}

.WebLinkPreview_LinkContainer__wEquk {
    text-decoration: none;
    color: inherit;
}

.WebLinkPreview_PreviewContainer__2Ll2G {
    width: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    border: 1px solid var(--color-border-light);
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 0 3px -3px rgba(0,0,0, 0.4);
    background-color: white;
}

.WebLinkPreview_PreviewContainerLeft__3a0cn {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.WebLinkPreview_PreviewContainerLeft__3a0cn img {
    max-width: 100%;
    max-height: 330px;
    object-fit: contain;
}

.WebLinkPreview_PreviewContainerRight__1d6RO {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    position: relative;
    word-wrap: break-word;
    color: var(--color-text-dark-secondary);
}

.WebLinkPreview_PreviewContainerRight__1d6RO h2 {
    color: var(--color-text-dark);
    margin: 0 0 10px 0;
    font-size: 14px;
}


@media (max-width: 1100px) {
    .WebLinkPreview_PreviewContainerLeft__3a0cn img {
        max-width: 100%;
        max-height: 230px;
        object-fit: contain;
    }
}

.Feed_SideNav__1yoRa {
    width: 220px;
}

.Feed_FeedPosts__1-t-2 {
    width: calc(100% - 220px - 340px);
    box-sizing: border-box;
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
}

.Feed_CreatePost__1c1Uz {
    padding: 20px 10px 0 10px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
}

.Feed_CreateBottom__qR8YX {
    border-bottom: 1px solid var(--color-border-light);
    height: 15px;
}

.Feed_FeedResults__22qSj {
    box-sizing: border-box;
}

.Feed_CreatePlaceholder__5zxMY {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid var(--color-border-light);
    padding: 20px;
}

.Feed_CreatePlaceholder__5zxMY h3 {
    margin: 0;
    font-size: 22px;
}

.Feed_CreatePlaceholderDescription__1UcFj {
    color: var(--color-text-dark-secondary);
}

.Feed_CreatePlaceholderButton__1VSz5 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
}

.Feed_NoPosts__1qj8r {
    width: 100%;
    height: 280px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    font-size: 25px;
    color: var(--color-text-secondary);
}

.Feed_NoPosts__1qj8r i {
    font-size: 55px;
}

.Feed_SpinnerContainer__3aQwI {
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Feed_EndOfResults__39sZP {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 60px;
    font-weight: bold;
}

.Feed_HashTagSearchBarContainer__2OrUf {
    padding: 20px 20px 0 20px;
    font-size: 20px;
}


@media (max-width: 1200px) {
    .Feed_SideNav__1yoRa {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .Feed_CreatePost__1c1Uz {
        border-left: none;
        border-right: none;
    }

    .Feed_CreatePlaceholder__5zxMY {
        border: none;
    }

    .Feed_FeedResults__22qSj {
        border-left: none;
        border-right: none;
    }

    .Feed_FeedPosts__1-t-2 {
        width: calc(100% - 220px);
        max-width: 600px;
    }
}

@media (max-width: 700px) {
    .Feed_SideNav__1yoRa {
        display: none;
    }

    .Feed_FeedPosts__1-t-2 {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding: 0;
        border-left: none;
        border-right: none;
    }

    .Feed_CreatePost__1c1Uz {
        margin-top: 10px;
    }
}

@-webkit-keyframes PlaceHolder_placeHolderShimmer__Pz-LA{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes PlaceHolder_placeHolderShimmer__Pz-LA{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.PlaceHolder_Container__2_4-y {
    margin-top: 20px;
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
}

.PlaceHolder_PlaceHolderLeft__1f1yf {
    width: 70px;
    position: relative;
}

.PlaceHolder_PlaceHolderRight__2cSd9 {
    width: calc(100% - 70px);
    padding-left: 10px;
    box-sizing: border-box;
    position: relative;

}

.PlaceHolder_Profile__umIfe {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 15px;
}

.PlaceHolder_TitleStrip__8yNk3 {
    width: 50%;
    height: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.PlaceHolder_Strip__29TuG {
    width: 100%;
    height: 12px;
    margin-bottom: 10px;
    overflow: hidden;
}

.PlaceHolder_LinearBackground__2Vc9r {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: PlaceHolder_placeHolderShimmer__Pz-LA;
            animation-name: PlaceHolder_placeHolderShimmer__Pz-LA;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}


@media (max-width: 700px) {
    .PlaceHolder_Container__2_4-y {
        padding: 15px 10px;
    }
}

.NativeAccountRedirect_Container__2uNYs {
    width: 100%;
    padding-top: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.NativeAccountRedirect_Spinner__2wfN5 {
    width: 100%;
    height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.ProfilePage_SideNav__3Oibo {
    width: 220px;
}

.ProfilePage_Back__1LCLZ {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    top: 0;
    padding: 5px 10px;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    border-radius: 25px;
    margin: 20px 0 5px 10px;
    font-weight: bold;
    font-size: 15px;
    background-color: white;
    z-index: 100;
}

.ProfilePage_Back__1LCLZ:hover {
    background-color: rgba(255, 255, 255, .8);
}

.ProfilePage_Back__1LCLZ svg {
    width: 16px;
    max-height: 16px;
    margin-right: 6px;
}

.ProfilePage_Profile__3ZEHF {
    width: calc(100% - 220px - 340px);
    position: relative;
    box-sizing: border-box;
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
}

.ProfilePage_ProfileMetadata__uuxnF {
    padding: 10px;
}

.ProfilePage_ProfileMetadata__uuxnF h3 {
    margin: 0;
}

.ProfilePage_ProfileMetadata__uuxnF div {
    color: var(--color-text-secondary);
}

.ProfilePage_BackContainer__1bi8G {
    width: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
}

.ProfilePage_BackIcon__25WKK {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color:rgba(255, 255, 255, 1);
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.ProfilePage_ProfileInner__39PH6 {
    border-bottom: none;
    position: relative;
}

.ProfilePage_Recommendations__JaTrO {
    width: 24%;
    position: -webkit-sticky;
    position: sticky;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 20px;
    margin-top: 20px;
}

.ProfilePage_Header__ZRkPG {
    width: 100%;
    height: 220px;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
}

.ProfilePage_HeaderBanner__7GX3V {
    height: 180px;
    width: 100%;
    overflow: hidden;
    background-color: var(--color-border-light);
}

.ProfilePage_HeaderBanner__7GX3V img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ProfilePage_HeaderContent__35gbX {
    padding: 0 20px 20px 20px;
    width: 100%;
    background: none;
    box-sizing: border-box;
    position: relative;
}

.ProfilePage_Content__3j9Aw {
    width: 100%;
    box-sizing: border-box;
}

.ProfilePage_ProfilePhoto__3zMj4 {
    width: 125px;
    height: 125px;
    position: absolute;
    left: 20px;
    bottom: 0px;
}

.ProfilePage_ProfileEditIcon__1pLLf {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid var(--color-border-light);
}

.ProfilePage_BannerEditIcon__3owcu {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid var(--color-border-light);
}

.ProfilePage_HeaderIconContainer__RNtK2 {
    position: absolute;
    top: -40px;
    right: 10px;
    display: -webkit-flex;
    display: flex;
}

.ProfilePage_HeaderEditIcon__3JmEM {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.ProfilePage_DeleteIcon__2Le-u {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 20px;
    height: 30px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 13px;
    color: var(--color-text-dark);

}

.ProfilePage_DeleteFeedPostContainer__3DQZ4 {
    padding: 60px 40px;
    background-color: white;
    border-radius: 4px;
    position: relative;
}

.ProfilePage_DeleteSubmitContainer__1zwAl {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    height: 40px;
}

.ProfilePage_EditSubmitCancelButton__3o-Ho {
    background-color: white;
    border: 1px solid #CDD6E5;
    font-size: 14px;
    color: #7c859c;
}

.ProfilePage_EditSubmitButton__1JfJQ {
    font-size: 14px;
    margin-left: 10px;
}

.ProfilePage_TitleContainer__36QVa {
    margin: 10px 0 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.ProfilePage_FollowButton__21a5A {
    padding: 0 13px;
    font-weight: 400;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    margin-left: 20px;
    cursor: pointer;
    border-radius: 4px;
}

.ProfilePage_FollowButtonFollowing__3w0mi {
    background: var(--color-primary);
    color: white;
}

.ProfilePage_Title__2CmOG {
    margin: 0;
    font-size: 25px;
}

.ProfilePage_SocialContainer__3-xGc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.ProfilePage_Link__1pi17 {
    color: var(--color-text-dark);
    margin-right: 10px;
}

.ProfilePage_Email__3Vvl8 {
    font-size: 14px;
    color: #6e7891;
}

.ProfilePage_Followers__2arij {
    display: -webkit-flex;
    display: flex;
    padding: 15px 0 0 0;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: bold;
}

.ProfilePage_Followers__2arij span {
    margin-right: 10px;
}

.ProfilePage_SpinnerContainer__b7W_V {
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 100;
    background-color: white;
}

.ProfilePage_Tabs__1d0hS {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-flex;
    display: flex;
    border-bottom: 1px solid var(--color-border-light);

}

.ProfilePage_Tab__2eznE {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: bold;
    cursor: pointer;
    height: 40px;
    width: 18%;
    min-width: 100px;
    font-size: 13px;
    -webkit-justify-content: center;
            justify-content: center;
    box-sizing: border-box;
    transition: background-color .1s linear, color .1s linear;
    text-transform: uppercase;
    color: var(--color-text-dark-secondary);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
}

.ProfilePage_TabActive__3pLTo {
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
    transition: background-color .1s linear, color .1s linear;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .ProfilePage_SideNav__3Oibo {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .ProfilePage_Profile__3ZEHF {
        width: calc(100% - 220px - 15px);
        margin-right: 15px;
    }
}

@media (max-width: 700px) {
    .ProfilePage_SideNav__3Oibo {
        display: none;
    }

    .ProfilePage_Profile__3ZEHF {
        width: 100%;
        padding-right: 0;
        border-left: none;
        border-right: none;
        margin-right: 0;
    }

    .ProfilePage_Header__ZRkPG {
        height: 160px;
    }

    .ProfilePage_HeaderBanner__7GX3V {
        height: 120px;
    }

    .ProfilePage_ProfilePhoto__3zMj4 {
        width: 95px;
        height: 95px;
    }

    .ProfilePage_Tab__2eznE {
        font-size: 12px;
        min-width: 60px;
    }

    .ProfilePage_Title__2CmOG {
        font-size: 18px;
    }

    .ProfilePage_Back__1LCLZ {
        margin-top: 10px;
    }

    .ProfilePage_SocialContainer__3-xGc {
        font-size: 13px;
    }

    .ProfilePage_Followers__2arij {
        font-size: 14px;
    }
}

.FollowersPopup_Container__3coBC {
    width: 500px;
    height: 600px;
    background-color: white;
    position: relative;
    cursor: default;
    border-radius: 3px;
}

.FollowersPopup_Header__WYR5b {
    width: 100%;
    height: 60px;
    display: -webkit-flex;
    display: flex;
}

.FollowersPopup_HeaderItem__3qSk8 {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-bottom: 3px solid #EBEEF0;
    font-weight: bold;
    cursor: pointer;
}

.FollowersPopup_HeaderActive__sSHFe {
    border-bottom: 3px solid var(--color-primary);
    color: var(--color-primary);
}

.FollowersPopup_ProfileList__1GN3e {
    overflow-y: scroll;
    max-height: calc(600px - 60px);
    padding-bottom: 150px;
    box-sizing: border-box;
}

.FollowersPopup_FollowerRow__ghqh6 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    border-bottom: 1px solid #EBEEF0;
    padding: 10px 20px;
    box-sizing: border-box;
    -webkit-align-items: center;
            align-items: center;
}

.FollowersPopup_Logo__1QrbX {
    width: 45px;
    height: 45px;
    position: relative;
    cursor: pointer;
}

.FollowersPopup_NameMetadataContainer__1gBFS {
    width: calc(100% - 70px - 100px);
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
}

.FollowersPopup_Name__3Ovbz {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.FollowersPopup_Name__3Ovbz:hover {
    text-decoration: underline;
}

.FollowersPopup_Metadata__1Eoxt {
    font-size: 14px;
    color: #6e7891;
    font-weight: 400;
}

.FollowersPopup_FollowButton__1SQwB {
    padding: 2px 10px;
    background-color: var(--color-primary);
    color: white;
    border-radius: 4px;
}

.FollowersPopup_Close__3kjIa {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 35px;
    width: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    cursor: pointer;
}

.FollowersPopup_Close__3kjIa svg {
    width: 15px;
}

.FollowersPopup_Close__3kjIa:hover {
    background-color: #F5F7FA;
}

@media (max-width: 1100px) {
    .FollowersPopup_Container__3coBC {
        width: 100vw;
        height: 100vh;
        padding-top: 60px;
        padding-bottom: 15vh;
        box-sizing: border-box;
    }

    .FollowersPopup_ProfileList__1GN3e {
        max-height: calc(100% - 60px);
        box-sizing: border-box;
    }
}

.SocialIcon_SocialIcon__20sB6 {
    width: 25px;
    height: 25px;
    background-color: var(--color-primary);
    color: white;
    border-radius: 100%;
    margin-right: 10px;
    font-size: 17px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.SocialIcon_SocialIcon__20sB6 a {
    color: white;
    text-decoration: none;
}

.SocialIcon_Link__3BR3H {
    text-decoration: none;
}


@media (max-width: 700px) {
    .SocialIcon_SocialIcon__20sB6 {
        width: 20px;
        height: 20px;
        font-size: 14px;
    }
}

.Description_DescriptionContainer__2T64T {
    font-size: 15px;
    margin-bottom: 20px;
}

.Description_DescriptionContainer__2T64T pre {
    margin: 0;
}

.Description_ExpandCollapseButtonContainer__MVNwk {
    padding: 10px 0;
}

.Description_ExpandCollapseButton__3g6ax {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    color: var(--color-primary);
}

.Description_ExpandCollapseButton__3g6ax i {
    margin-left: 10px;
}

.Description_Expanded__2-pZu i {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}

.InvestorInformation_InvestmentInfo__zjI6J {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.InvestorInformation_InvestmentInfo__zjI6J h4 {
    margin-top: 40px;
    margin-bottom: 5px;
}

.InvestorInformation_InvestmentInfoLeft__17KvO {
    width: 50%;
}

.InvestorInformation_InvestmentInfoRight__15lbN {
    width: 50%;
}


.InvestorInformation_EditIconContainer__aAuj- {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-bottom: 10px;
}


.InvestorInformation_IconsContainer__2vulN {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 20px;
}

.InvestorInformation_InvestmentRange__puJPQ {
    padding: 8px;
    margin: 0 15px 5px 0;
    border: 1px solid var(--color-text-dark);
    border-radius: 2px;
    font-weight: 400;
}

.InvestorInformation_FocusAreasContainer__-ywN2 {
    width: 100%;
}

.InvestorInformation_TechnologiesContainer__xQ4DX {
    width: 100%;
}

.InvestorInformation_PastInvestmentsContainer__s5rO3 {
    width: 100%;
    margin-bottom: 170px;
}

.InvestorInformation_EditIcon__PGmDb {
    position: absolute;
    top: 0;
    right: -10px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 30px;
    height: 30px;
}

@media (max-width: 1100px) {
    .InvestorInformation_InvestmentInfoLeft__17KvO, .InvestorInformation_InvestmentInfoRight__15lbN {
        width: 100%;
    }
}

.SharedStyles_ProfilePage__2Yvan {
    width: 100%;
    min-height: calc(100% - 370px);
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: white;
    padding: 0 20px;
    box-sizing: border-box;
}

.SharedStyles_ProfilePage__2Yvan h2 {
    margin: 0;
}

.SharedStyles_ProfilePage__2Yvan h3 {
    font-size: 25px;
    font-weight: 400;
    margin: 0;
    width: 100%;
}

.SharedStyles_ProfilePage__2Yvan h4 {
    margin: 0 0 10px 0;
}

.SharedStyles_Container__Yvu1F {
    width: 100%;
    max-width: 1000px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 10px 20px 70px 20px;
    box-sizing: border-box;
}

.SharedStyles_BackContainer__2K41Y {
    height: 50px;
    width: 100%;
    position: relative;
}

.SharedStyles_BackIcon__2Sv3v {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 1000;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color:rgba(255, 255, 255, 1);
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.SharedStyles_Header__29c1S {
    width: 100%;
    height: 250px;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
}

.SharedStyles_HeaderBanner__obz3W {
    height: 200px;
    width: 100%;
    overflow: hidden;
}

.SharedStyles_HeaderBanner__obz3W img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SharedStyles_ProfilePhoto__2A4d4 {
    border: 1px solid #CDD6E5;
    outline: 5px solid white;
    background: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 150px;
    height: 150px;
    position: absolute;
    left: 50px;
    bottom: 0;
}

.SharedStyles_ProfilePhoto__2A4d4 img {
    max-width: 100%;
}

.SharedStyles_ProfileOptionsContainer__86ipX {
    margin-top: 10px;
}

.SharedStyles_ProfileOption__tuPtw {
    height: 25px;
    background:  none;
    padding: 0;
    outline: none;
    border: none;
    color: var(--color-primary);
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
}

.SharedStyles_ProfileOption__tuPtw i {
    margin-left: 3px;
}

.SharedStyles_DeleteProfileContainer__3tzfB {
    padding: 60px 100px;
    background-color: white;
    border-radius: 4px;
    position: relative;
}

.SharedStyles_DeleteSubmitContainer__KsKX3 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 40px;
}

.SharedStyles_DeleteSubmitCancelButton__2xUpV {
    background-color: white;
    border: 1px solid #CDD6E5;
    font-size: 14px;
    color: #7c859c;
}

.SharedStyles_DeleteSubmitButton__3zNqx {
    font-size: 14px;
    margin-left: 10px;
}

.SharedStyles_ContainerInner__1bzDV {
    z-index: 100;
    width: 900px;
    background: none;
}

.SharedStyles_TitleContainer__1Nj-b {
    margin: 10px 0 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.SharedStyles_Title__3ygOH {
    margin: 0;
    font-size: 40px;
    line-height: 1.0em;
}

.SharedStyles_SubTitle__2sNfD {
    color: #6e7891;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 15px;
}

.SharedStyles_SubTitle__2sNfD i {
    font-size: 6px;
    margin: 0 7px;
    padding-bottom: 10px;
}

.SharedStyles_SocialContainer__1uHS8 {
    display: -webkit-flex;
    display: flex;
}

.SharedStyles_Link__1xfB_ {
    color: var(--color-text-dark);
    margin-right: 10px;
}

.SharedStyles_Followers__90Sca {
    display: -webkit-flex;
    display: flex;
    padding: 5px 0 10px 0;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.SharedStyles_FollowersOption__2WcUL {
    font-weight: bold;
    margin-right: 10px;
}

.SharedStyles_Connect__2dpMb {
    display: -webkit-flex;
    display: flex;
}

.SharedStyles_Description__2FlaG {
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.SharedStyles_FocusAreaIcon__3pARu {
    position: relative;
    width: 70px;
}

.SharedStyles_FocusAreaIcon__3pARu img {
    max-width: 100%;
}

.SharedStyles_SpinnerContainer__2M4Cl {
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 100;
    background-color: white;
}

.SharedStyles_SplitSection__1EHql {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px;
}

.SharedStyles_SplitSectionLeft__1-bRH {
    width: 30%;
}

.SharedStyles_SplitSectionLeft__1-bRH h3 {
    margin: 0;
    font-size: 15px;
}

.SharedStyles_SplitSectionRight__1Ly1w {
    width: 70%;
    font-size: 14px;
    color: var(--color-text-dark-secondary-darker);
}

.SharedStyles_Section__Fyghj {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    padding: 10px 0;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    position: relative;
}

.SharedStyles_Section__Fyghj h3 {
    width: 100%;
    margin: 0 0 10px 0;
}

.SharedStyles_HalfSection__26XhL {
    width: 50%;
}

.SharedStyles_IconsContainer__1xJij {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 20px;
}

.SharedStyles_IconContainer__oBAf6 {
    text-align: center;
    position: relative;
    width: 65px;
    margin: 0 5px 5px 0;
}

.SharedStyles_InvestmentRange__2sR5D {
    padding: 8px;
    margin: 0 15px 5px 0;
    border: 1px solid var(--color-text-dark);
    border-radius: 2px;
    font-weight: 400;
}

.SharedStyles_Icon__1juco {
    width: 60px;
    height: 60px;
    position: relative;
    padding: 7px;
    box-sizing: border-box;
}

.SharedStyles_Icon__1juco img {
    width: 100%;
}

.SharedStyles_TechnologyIcon__1fZmS img {
    -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(46deg) brightness(102%) contrast(101%);
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(46deg) brightness(102%) contrast(101%);
}

.SharedStyles_IconLabel__35tky {
    font-size: 12px;
    font-weight: 400;
}


.SharedStyles_Tabs__jqGfH {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 20px;
}

.SharedStyles_Tab__2M2JY {
    width: 125px;
    height: 40px;
    border-bottom: 2px solid var(--color-border-light);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: bold;
    font-size: 15px;
    color: var(--color-text-secondary);
    cursor: pointer;
}

.SharedStyles_TabActive__28522 {
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
}

.SharedStyles_TabFiller__2Lw0n {
    width: calc((100% - 250px) / 2);
    border-bottom: 1px solid var(--color-border-light);
}

.SharedStyles_Tab__2M2JY i {
    margin-right: 10px;
}

@media (max-width: 700px) {
    .SharedStyles_SubTitle__2sNfD {
        font-size: 13px;
    }
}

.EditOverview_EditIcon__2I7tY {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: bold;
}

.EditOverview_EditIcon__2I7tY:hover {
    background-color: var(--color-primary-washed);
}


.EditOverview_PopupContainer__rI3_K {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    width: 600px;
    position: relative;
}

.EditOverview_Title__1Dq2d {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.EditOverview_SubmitContainer__3esYp {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.EditOverview_SaveOption__1L6it {
    margin-left: 10px;
}

.EditOverview_CancelOption__2Igfb {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.EditOverview_ProfileEditPopup__DaSVJ {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    max-height: 600px;
    overflow-y: scroll;
}

.EditOverview_ProfileEditPopup__DaSVJ textarea{
    height: 350px;
}

.EditOverview_ChangeButtonContainer__1JXXH {
    height: 38px;
    margin-bottom: 20px;
}

.EditOverview_ChangeButtonContainer__1JXXH i {
    margin-right: 10px;
}

.EditOverview_ChangeButton__26b45 {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}

.InputLabel_RequiredAnnotation__1wSQ7 {
    color: #CC333A;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: -10px;
}

.InputLabel_Container__2UdCq {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    margin-bottom: 5px;
    width: 100%;
}

.InputLabel_Label__s9fOF {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.Steps_InputContainer__11tAm {
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.Steps_InputLabel__1vw9u {
    margin-top: 20px;
}

.Steps_FounderInputContainer__3YB5Q {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    padding: 20px 15px 10px 15px;
    box-sizing: border-box;
    background-color: #F5F7FA;
}

.Steps_AddFounder__3TzZj {
    width: 25%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Steps_AddFounderIcon__1ranx {
    padding: 5px 15px;
    border: 1px solid #CDD6E5;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #A8AEBD;
    font-size: 25px;
    cursor: pointer;
    background-color: #F5F7FA;
}

.Steps_FeaturedImages__1TWxn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    position: relative;
    width: 100%;
}

.Steps_FeaturedImage__1bbC6 {
    width: 100%;
    margin-bottom: 15px;
}

.Steps_NoFeaturedImages__1hgo4 {
    width: 100%;
    height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    background-color: #F5F7FA;
    padding: 40px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.Steps_NoHeadshot__3-7N2 {
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    background-color: white;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.Steps_NoHeadshotIcon__3TPo6 {
    width: 100%;
    font-size: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #757887;
}

.Steps_NoHeadshotText__3eBfW {
    font-size: 16px;
    font-weight: 400;
    color: #757887;
}

.Steps_NoFeaturedImagesIcon__2SThC {
    width: 100%;
    font-size: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #757887;
}

.Steps_NoFeaturedImagesText__3sT03 {
    font-size: 22px;
    font-weight: 400;
    color: #757887;
}

.Steps_FounderInputs__2cbXU {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.Steps_AddFounderButtonContainer__30Nw8 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--color-text-dark);
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 2px;
    color: var(--color-text-dark);
    font-weight: 400;
    cursor: pointer;
}

.Steps_AddFounderButtonContainer__30Nw8 i {
    margin-right: 10px;
}

.Steps_RemoveFounderIconContainer__1qjYG {
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20px;
}

.Steps_RemoveFounderIcon__3UzLL {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    padding: 5px 10px;
}

.Steps_RemoveFounderIcon__3UzLL:hover {
    background-color: white;
}

.Steps_RemoveFounderIcon__3UzLL i {
    margin-right: 7px;
}

.Steps_FounderContainer__1lJ33 {
    width: 100%;
}

.Steps_AddButton__3XgNi {
    margin-top: 30px;
    width: 100%;
}

.Steps_AddHeadShotButton__2le-1 {
    background-color: white;
}

.TextArea_Container__1mE43 {
    width: 100%;
    position: relative;
}

.TextArea_TextArea__fRY7b {
    width: 100%;
    border-radius: 3px;
    background-color: white;
    font-size: 16px;
    height: 40px;
    outline: none;
    border: 1px solid #cdd6e5;
    box-sizing: border-box;
    position: relative;
    min-height: 100px;
    padding: 10px;
    resize: vertical;
    overflow-y: scroll;
    box-shadow: none;
}

.TextArea_TextAreaFocused__RIqD0 {
    box-shadow: 0 0 0 1px var(--color-primary);
    border: 1px solid var(--color-primary);
}

.TextArea_Error__2-xzT {
    color: #D93025;
    font-size: 12px;
    margin-bottom: 5px;
}

.TextArea_Error__2-xzT i {
    margin-right: 3px;
}

.TextArea_TextAreaError__F5Dcd {
    outline: 2px solid #D93025 !important;
}

.TextArea_TextArea__fRY7b::-webkit-input-placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}

.TextArea_TextArea__fRY7b:-ms-input-placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}

.TextArea_TextArea__fRY7b::placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}

.EditableFieldStyles_PopupContainer__1AerA {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    width: 600px;
    position: relative;
}

.EditableFieldStyles_ProfileEditPopup__1fI-4 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    max-height: 600px;
    overflow-y: scroll;
}

.EditableFieldStyles_SubmitContainer__26nph {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.EditableFieldStyles_InvisibleInput__2cYJe {
    display: none;
}

@media (max-width: 1100px) {
    .EditableFieldStyles_PopupContainer__1AerA {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        padding-top: 60px;
        padding-bottom: 15vh;
        box-sizing: border-box;
    }

    .EditableFieldStyles_ProfileEditPopup__1fI-4 {
        max-height: none;
        overflow-y: auto;
    }

    .EditableFieldStyles_SubmitContainer__26nph {
        border-top: none;
    }
}

.EditInvestorDetails_EditIcon__G8rJr {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: bold;
}

.EditInvestorDetails_EditIcon__G8rJr:hover {
    background-color: var(--color-primary-washed);
}

.EditInvestorDetails_PopupContainer__3K_Nh {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    width: 600px;
    position: relative;
}

.EditInvestorDetails_Title__ve4PL {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.EditInvestorDetails_SubmitContainer__3zy-s {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.EditInvestorDetails_SaveOption__1Fk5p {
    margin-left: 10px;
}

.EditInvestorDetails_CancelOption__5YUTH {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.EditInvestorDetails_ProfileEditPopup__34VPQ {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    max-height: 600px;
    overflow-y: scroll;
}

.EditInvestorDetails_ProfileEditPopup__34VPQ textarea{
    height: 350px;
}

.EditInvestorDetails_ChangeButtonContainer__idcvH {
    height: 38px;
    margin-bottom: 20px;
}

.EditInvestorDetails_ChangeButtonContainer__idcvH i {
    margin-right: 10px;
}

.EditInvestorDetails_ChangeButton__x0MIZ {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}

.Cards_CreateProfileCardOptionContainer__3BGMg {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.Cards_CardContainer__1acN0 {
    outline: none;
    background-color: white;
    height: 120px;
    width: 120px;
    margin: 0 15px 15px 0;
    border: 1px solid #cdd6e5;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 3px;
    font-size: 14px;
    font-family: inherit;
    cursor: pointer;
    position: relative;
}

.Cards_CardContainerSelected__3quRy {
    background-color: #fafcfe;
    border: 2px solid var(--color-primary);
}

.Cards_CardContainer__1acN0 img {
    max-width: 50px;
    max-height: 50px;
}

.Cards_CheckIcon__1L3lS {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: var(--color-primary);
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

@-webkit-keyframes PlaceHolder_placeHolderShimmer__1cwAJ{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes PlaceHolder_placeHolderShimmer__1cwAJ{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.PlaceHolder_Container__3f6Lj {
    margin-top: 20px;
    width: 100%;
    padding: 15px 10px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
}

.PlaceHolder_PlaceHolderLeft__2kH_x {
    width: 20%;
}

.PlaceHolder_PlaceHolderRight__2TTbk {
    width: 80%;
}

.PlaceHolder_Profile__1mIkv {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 15px;
}

.PlaceHolder_TitleStrip__oJztV {
    width: 50%;
    height: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.PlaceHolder_Strip__azm8- {
    width: 100%;
    height: 12px;
    margin-bottom: 10px;
    overflow: hidden;
}

.PlaceHolder_LinearBackground__fW8fY {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: PlaceHolder_placeHolderShimmer__1cwAJ;
            animation-name: PlaceHolder_placeHolderShimmer__1cwAJ;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}


@media (max-width: 700px) {
    .PlaceHolder_Profile__1mIkv {
        width: 50px;
        height: 50px;
    }

}

.Posts_PostsContainer__1--oS {
    /*padding: 20px;*/
    box-sizing: border-box;
    width: 100%;
    max-width: 700px;
}

.Posts_NoPosts__3b0tN {
    width: 100%;
    height: 280px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    font-size: 25px;
    color: var(--color-text-secondary);
}

.Posts_NoPosts__3b0tN i {
    font-size: 55px;
}

.Posts_SpinnerContainer__1E5BW {
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.CompanyInformation_CompanyInformationContainer__1AGyH {
    width: 100%;
}

.CompanyInformation_SocialContainer__37nWk {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.CompanyInformation_FeaturedImages__3IPuA {
    width: 100%;
    padding-top: 60px;
    position: relative;
}

.CompanyInformation_TagLine__1gpFA {
    width: 100%;
    font-size: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 40px;
}

.CompanyInformation_TagLineInner__3wHKU {
    max-width: 100%;
    width: 600px;
    font-style: italic;
}

.CompanyInformation_FoundersContainer__16adm {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.CompanyInformation_FoundersContainer__16adm h3 {
    width: 100%;
}

.CompanyInformation_Founder__2gPuA {
    margin-right: 30px;
    text-align: center;
}

.CompanyInformation_FounderHeadshot__FOCmr {
    width: 150px;
    height: 150px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.CompanyInformation_FounderHeadshotInner__3XsB0 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
}

.CompanyInformation_FounderHeadshotInner__3XsB0 img {
    height: 100%;
}

.CompanyInformation_LinkedIn__1Px_M {
    width: 40px;
    height: 40px;
    background-color: var(--color-primary);
    border-radius: 100%;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.CompanyInformation_FounderHeadshot__FOCmr a {
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    text-decoration: none;
}

.CompanyInformation_FounderName__22dCD {
    font-weight: 400;
    margin-top: 5px;
}

.CompanyInformation_EditIconContainer__nY5Ke {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-bottom: 10px;
}


.CompanyInformation_KeyFacts__1jDuG {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.CompanyInformation_KeyFact__33yaX {
    width: 50%;
}

.CompanyInformation_KeyFactValue__2222N {
    font-size: 40px;
}

.CompanyInformation_KeyFactLabel__2gipy {
    margin-left: 40px;
}

.CompanyInformation_BusinessStageTechnologiesSplitContainer__2yOTR {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.CompanyInformation_BusinessStageContainer__31nvj {
    width: 50%;
    position: relative;
}

.CompanyInformation_TechnologiesContainer__1Ei8f {
    width: 50%;
    position: relative;
}

.CompanyInformation_PreviousInvestors__1uhj7 {
    margin-bottom: 170px;
}

.CompanyInformation_EditIcon__1IKfz {
    position: absolute;
    top: 0;
    right: -10px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 30px;
    height: 30px;
}

@media (max-width: 1100px) {
    .CompanyInformation_KeyFact__33yaX, .CompanyInformation_TechnologiesContainer__1Ei8f, .CompanyInformation_BusinessStageContainer__31nvj {
        width: 100%;
    }
}

.EditCompanyDetails_EditIcon__XimAO {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: bold;
}

.EditCompanyDetails_EditIcon__XimAO:hover {
    background-color: var(--color-primary-washed);
}

.EditCompanyDetails_PopupContainer__2ODM0 {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    width: 600px;
    position: relative;
}

.EditCompanyDetails_Title__1IRT4 {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.EditCompanyDetails_SubmitContainer__x3QO- {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.EditCompanyDetails_SaveOption__jZvJ3 {
    margin-left: 10px;
}

.EditCompanyDetails_CancelOption__1140w {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.EditCompanyDetails_ProfileEditPopup__3Rq1Y {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    max-height: 600px;
    overflow-y: scroll;
}

.EditCompanyDetails_ProfileEditPopup__3Rq1Y textarea{
    height: 350px;
}

.EditCompanyDetails_ChangeButtonContainer__235M6 {
    height: 38px;
    margin-bottom: 20px;
}

.EditCompanyDetails_ChangeButtonContainer__235M6 i {
    margin-right: 10px;
}

.EditCompanyDetails_ChangeButton__1AqDD {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}

.DatePicker_Container__1NEXr {
    width: 200px;
    max-width: 100%;
    background-color: white;
    font-size: 15px;
    height: 40px;
    border: 1px solid #cdd6e5;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.DatePicker_Container__1NEXr input {
    font-size: 16px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;  
}


.DatePicker_Container__1NEXr input::-webkit-input-placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}


.DatePicker_Container__1NEXr input:-ms-input-placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}


.DatePicker_Container__1NEXr input::placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}

.DatePicker_ContainerFocused__3dqYF {
    border: 2px solid var(--color-primary);
}

.EditProfilePhoto_EditIcon__3lRvr {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    font-size: 14px;
}

.EditProfilePhoto_PopupContainer__1lwak {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    width: 500px;
    position: relative;
}

.EditProfilePhoto_Title__QmxLf {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.EditProfilePhoto_SubmitContainer__2_NLr {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.EditProfilePhoto_SaveOption__14d9W {
    margin-left: 10px;
}

.EditProfilePhoto_CancelOption__ls-Ff {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.EditProfilePhoto_ProfileEditPopup__1VbXD {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.EditProfilePhoto_ImageContainer__12C2f {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 10px 0;
}

.EditProfilePhoto_ImageContainerInner__3KSi3 {
    width: 200px;
    height: 200px;
    position: relative;
}

.EditProfilePhoto_ChangeButtonContainer__3zfir {
    height: 38px;
    margin-bottom: 20px;
}

.EditProfilePhoto_ChangeButtonContainer__3zfir i {
    margin-right: 10px;
}

.EditProfilePhoto_ChangeButton__1liCU {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}

.EditBanner_EditIcon__1cfTZ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    font-size: 14px;
}

.EditBanner_Title__2oxNG {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.EditBanner_SubmitContainer__3xSP5 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.EditBanner_SaveOption__1L-9p {
    margin-left: 10px;
}

.EditBanner_CancelOption__2DYSv {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.EditBanner_HeaderBanner__2QTAp {
    height: 180px;
    width: 100%;
    overflow: hidden;
    background-color: var(--color-border-light);
    margin: 10px 0;
}

.EditBanner_HeaderBanner__2QTAp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.EditBanner_ChangeButtonContainer__1fulU {
    height: 38px;
    margin-bottom: 20px;
}

.EditBanner_ChangeButtonContainer__1fulU i {
    margin-right: 10px;
}

.EditBanner_ChangeButton__unRB6 {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}

.EditHeader_EditIcon__3oULN {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: bold;
}

.EditHeader_EditIcon__3oULN:hover {
    background-color: var(--color-primary-washed);
}

.EditHeader_Title__10UQk {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.EditHeader_SubmitContainer__tpdsy {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.EditHeader_SaveOption__ZxqBL {
    margin-left: 10px;
}

.EditHeader_CancelOption__Fjb74 {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.EditHeader_ProfileEditPopup__1prQB {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    max-height: 600px;
    overflow-y: scroll;
}

.EditHeader_ChangeButtonContainer__3jirE {
    height: 38px;
    margin-bottom: 20px;
}

.EditHeader_ChangeButtonContainer__3jirE i {
    margin-right: 10px;
}

.EditHeader_ChangeButton__2_UnW {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}

.BasicTextInput_ContainerOuter__3xn-O {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}

.BasicTextInput_Container__2L3A8 {
    width: 100%;
    background-color: white;
    overflow: hidden;
    font-size: 15px;
    height: 40px;
    border: 1px solid #cdd6e5;
    box-sizing: border-box;
    border-radius: 3px;
    position: relative;
}

.BasicTextInput_ContainerFocused__2aMu0 {
    box-shadow: 0 0 0 1px var(--color-primary);
    box-sizing: border-box;
    border: 1px solid var(--color-primary);
}

.BasicTextInput_TypeError__3qphp {
    position: absolute;
    right: 0;
    top: -23px;
    font-size: 12px;
    font-weight: 400;
    color: #6e7891;
}

.BasicTextInput_Error__7WM8_ {
    color: #D93025;
    font-size: 12px;
    margin-bottom: 5px;
}

.BasicTextInput_Error__7WM8_ i {
    margin-right: 3px;
}

.BasicTextInput_ContainerError__TyWRo {
    outline: 2px solid #D93025 !important;
}

.BasicTextInput_Container__2L3A8 input {
    font-size: 16px;
    padding: 0 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;

}

.BasicTextInput_Container__2L3A8 input::-webkit-input-placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}

.BasicTextInput_Container__2L3A8 input:-ms-input-placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}

.BasicTextInput_Container__2L3A8 input::placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}

.BasicTextInput_CharacterCounter__26tlJ {
    position: absolute;
    right: 0;
    bottom: -20px;
    font-size: 12px;
    font-weight: 400;
}

.PersonalInformation_InvestmentInfo__EmGRM {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.PersonalInformation_InvestmentInfo__EmGRM h4 {
    margin-top: 40px;
    margin-bottom: 5px;
}

.PersonalInformation_InvestmentInfoLeft__1F3cl {
    width: 50%;
}

.PersonalInformation_InvestmentInfoRight__2F470 {
    width: 50%;
}

.PersonalInformation_IconsContainer__2QSZ7 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 20px;
}

.PersonalInformation_InvestmentRange__1zti6 {
    padding: 8px;
    margin: 0 15px 5px 0;
    border: 1px solid var(--color-text-dark);
    border-radius: 2px;
    font-weight: 400;
}

.PersonalInformation_FocusAreasContainer__tpW0X {
    width: 100%;
}

.PersonalInformation_TechnologiesContainer__2x31A {
    width: 100%;
}

.PersonalInformation_PastInvestmentsContainer__234Nx {
    width: 100%;
    margin-bottom: 170px;
}

.PersonalInformation_EditIconContainer__2mecf {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-bottom: 10px;
}

@media (max-width: 1100px) {
    .PersonalInformation_InvestmentInfoLeft__1F3cl, .PersonalInformation_InvestmentInfoRight__2F470 {
        width: 100%;
    }
}

.ProfilePhotoPreview_ProfilePhotoInner__1Clfv {
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: white;
    box-shadow: 0 2px 5px 1px rgba(0,0,0, 0.15);
    width: 125px;
    height: 125px;
    border-radius: 100%;
    cursor: pointer;
}


.ProfilePhotoPreview_ProfilePhotoPreview__127Xj {
    width: 400px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: white;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 10px;
    border-radius: 5px;
    position: relative;
}

.ProfilePhotoPreview_ProfilePhotoPreviewContainer__3MBQV {
    width: 380px;
    height: 380px;
    position: relative;
}

.ProfilePhotoPreview_CloseHeader__2MZ3u {
    height: 50px;
    width: 100%;
    position: relative;
}

.ProfilePhotoPreview_CloseIcon__3oGeB {
    width: 35px;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;
}

.ProfilePhotoPreview_CloseIcon__3oGeB:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

.ProfilePhotoPreview_CloseIcon__3oGeB svg {
    width: 14px;
}

@media (max-width: 700px) {
    .ProfilePhotoPreview_ProfilePhotoPreview__127Xj {
        width: 100vw;
        height: 100vh;
        padding-top: 60px;
        padding-bottom: 15vh;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    .ProfilePhotoPreview_ProfilePhotoPreviewContainer__3MBQV {
        width: 300px;
        height: 300px;
    }

    .ProfilePhotoPreview_ProfilePhotoInner__1Clfv {
        width: 95px;
        height: 95px;
    }

}

.EditFeaturedImages_EditIcon__3_W0X {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: bold;
}

.EditFeaturedImages_EditIcon__3_W0X:hover {
    background-color: var(--color-primary-washed);
}

.EditFeaturedImages_PopupContainer__5XMAH {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    width: 500px;
    position: relative;
}

.EditFeaturedImages_Title__2kEdJ {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.EditFeaturedImages_SubmitContainer__3tWCV {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.EditFeaturedImages_SaveOption__3NGQK {
    margin-left: 10px;
}

.EditFeaturedImages_CancelOption__3TRsk {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.EditFeaturedImages_ProfileEditPopup__2eCd0 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 15px;
    box-sizing: border-box;
    max-height: 700px;
    overflow-y: scroll;
}

.AddPhoto_Container__3oXc5 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.AddPhoto_Container__3oXc5 input {
    display: none;
}

.AddPhoto_PhotoContainer__1r6mT {
    cursor: pointer;
    width: 100%;
    max-height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-flow: column;
            flex-flow: column;
    position: relative;
    font-size: 12px;
    box-sizing: border-box;
}

.AddPhoto_PhotoButtonContainer__3Ez4f:active {
    background-color: #F5F7FA;
}

.AddPhoto_IconPrimary__PElUU {
    font-size: 50px;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
}

.AddPhoto_PhotoContainer__1r6mT img {
    max-width: 100%;
    max-height: 100%;
}

.AddPhoto_PhotoButtonContainer__3Ez4f {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--color-text-dark);
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 2px;
    color: var(--color-text-dark);
    font-weight: 400;
    cursor: pointer;
}

.AddPhoto_PhotoButtonContainer__3Ez4f i {
    margin-right: 10px;
}

.AddPhoto_OptionIcons__3xcKL {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 100%;
}

.AddPhoto_OptionIcon__3okl4 {
    font-size: 14px;
    color: var(--color-text-dark);
    padding: 6px 0 6px 30px;
    font-weight: 400;
    cursor: pointer;
}

.AddPhoto_OptionIcon__3okl4 i {
    margin-left: 5px;
}

.AddPhoto_PhotoPreview__3prP1 {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.EditFounders_EditIcon__kjI1t {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: bold;
}

.EditFounders_EditIcon__kjI1t:hover {
    background-color: var(--color-primary-washed);
}

.EditFounders_PopupContainer__1QmZJ {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    width: 500px;
    position: relative;
}

.EditFounders_Title__Tw5tt {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.EditFounders_SubmitContainer__2VZMu {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.EditFounders_SaveOption__3E-rF {
    margin-left: 10px;
}

.EditFounders_CancelOption__2GO9M {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.EditFounders_ProfileEditPopup__2jQ_N {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 15px;
    box-sizing: border-box;
    max-height: 700px;
    overflow-y: scroll;
}

.People_FoundersContainer__c50fg {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.People_FoundersContainer__c50fg h3 {
    width: 100%;
}

.People_Founder__1CLnl {
    width: 33%;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.People_FounderHeadshot__3zqiF {
    width: 175px;
    height: 175px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.People_FounderHeadshotInner__3UuMY {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    background-color: var(--color-input-background-light);
}

.People_FounderHeadshotInner__3UuMY img {
    height: 100%;
}

.People_LinkedIn__MRim7 {
    width: 40px;
    height: 40px;
    background-color: var(--color-primary);
    border-radius: 100%;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.People_FounderHeadshot__3zqiF a {
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    text-decoration: none;
}

.People_FounderName__KZGOE {
    font-weight: 400;
    margin-top: 5px;
    width: 100%;
}

@media (max-width: 700px) {
    .People_FounderHeadshot__3zqiF {
        width: 100px;
        height: 100px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        position: relative;
    }
}

.MessageButton_MessageButton__1zpo4 {
    padding: 0 13px;
    font-weight: 400;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    margin-left: 10px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 15px;
}

.MessageButton_MessageButton__1zpo4:hover {
    background-color: var(--color-primary-washed);
}

.FeedPostPage_SideNav__2YdwP {
    width: 220px;
}

.FeedPostPage_Back__2K0mP {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    display: -webkit-flex;
    display: flex;
    top: 0;
    padding: 5px 10px;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    border-radius: 25px;
    margin: 20px 0 15px 10px;
    font-weight: bold;
    font-size: 15px;
}

.FeedPostPage_Back__2K0mP svg {
    width: 16px;
    max-height: 16px;
    margin-right: 6px;
}

.FeedPostPage_Back__2K0mP:hover {
    background-color: var(--color-input-background-light);
}

.FeedPostPage_FeedPost__1I75z {
    width: calc(100% - 220px - 340px);
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
    box-sizing: border-box;
}

.FeedPostPage_FeedPostPrimary__t8j-7 {
    box-sizing: border-box;
}

.FeedPostPage_FeedPostPrimary__t8j-7 > div:hover {
    background-color: inherit; /* TODO: this is a hack */
}

.FeedPostPage_CommentsHeader__2p661 {
    width: 100%;
    padding: 60px 15px 15px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-border-light);
}

.FeedPostPage_CommentsHeader__2p661 h3 {
    margin: 0;
}

.FeedPostPage_SpinnerContainer__3L46M {
    width: 100%;
    height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.FeedPostPage_EndOfResults__ewvWO {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 60px;
    font-weight: bold;
}

@media (max-width: 1200px) {
    .FeedPostPage_SideNav__2YdwP {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .FeedPostPage_FeedPost__1I75z {
        width: calc(100% - 220px);
        margin-bottom: 150px;
        padding-right: 50px;
        box-sizing: border-box;
    }
}

@media (max-width: 700px) {
    .FeedPostPage_SideNav__2YdwP {
        display: none;
    }

    .FeedPostPage_FeedPost__1I75z {
        width: 100%;
        padding: 0;
        border-left: none;
        border-right: none;
    }

    .FeedPostPage_FeedPostPrimary__t8j-7 {
        padding: 10px;
        box-sizing: border-box;
    }
}

.FeedPostComment_Comment__2S2lv {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    font-size: 15px;
    border-bottom: 1px solid var(--color-border-light);
    padding: 0 20px;
}

.FeedPostComment_FeedPostPrimary__3RYOY {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    box-sizing: border-box;
    padding: 15px 0 10px 0;
    cursor: pointer;
}

.FeedPostComment_FeedPostLeft__3thyG {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.FeedPostComment_FeedPostLeft__3thyG a {
    text-decoration: none;
}

.FeedPostComment_Logo__2_KE8 {
    width: 50px;
    height: 50px;
    position: relative;
}

.FeedPostComment_FeedPostRight__3c7Rx {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    position: relative;
    padding-left: 65px;
}

.FeedPostComment_CreateCommentPopupContainer__D3dO7 {
    z-index: 1000;
    width: 560px;
    background-color: white;
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 15px 0 0 0;
    position: relative;
}

.FeedPostComment_CloseCommentIconContainer__3kmb1 {
    width: 35px;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;
}

.FeedPostComment_CloseCommentIconContainer__3kmb1:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

.FeedPostComment_CloseCommentIconContainer__3kmb1 svg {
    width: 14px;
}

.FeedPostComment_DeleteFeedPostContainer__2MIq1 {
    padding: 60px 100px;
    background-color: white;
    border-radius: 4px;
    position: relative;
}

.FeedPostComment_DeleteSubmitContainer__31TV5 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 40px;
}

.FeedPostComment_FeedPostOptions__1wMhY {
    z-index: 10000;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    color: var(--color-text-dark-secondary);
}

.FeedPostComment_FeedPostOption__23Ddj {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #AAB0BF;
    cursor: pointer;
}

.FeedPostComment_FeedPostOption__23Ddj:hover {
    background-color: #F5F7FA;
}

.FeedPostComment_Container__2jsXF {
    width: 100%;
}

.FeedPostComment_FeedPostActions__29VDC {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    /*margin-top: 20px;*/
    padding: 10px 15px 0 15px;
    box-sizing: border-box;
}

.FeedPostComment_FeedPostAction__13lQ9 {
    margin-right: 20px;
    color: var(--color-text-dark-secondary);
    height: 40px;
    width: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
}

.FeedPostComment_FeedPostAction__13lQ9:hover {
    background-color: rgba(205, 214, 229, .15);
}

.FeedPostComment_FeedPostActionLiked__M6wPg {
    color: var(--color-primary);
}


.FeedPostComment_Comments__1WgHK {
    width: 100%;
    position: relative;
}

.FeedPostComment_FeedPostActionValue__kDGxA {
    margin-left: 7px;
    font-size: 14px;
}

.FeedPostComment_EditContainer__1qoIZ {
    border-top: 1px solid var(--color-border-light);
    border-bottom: 1px solid var(--color-border-light);
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
}

.FeedPostComment_EditSubmitContainer__gzaYC {
    width: 100%;
}

.FeedPostComment_EditSubmitCancelButton__ozfKX {
    background-color: white;
    border: 1px solid #CDD6E5;
    font-size: 14px;
    color: #7c859c;
}

.FeedPostComment_EditSubmitButton__3oqgm {
    font-size: 14px;
    margin-left: 10px;
}

.FeedPostComment_FeedPostHeader__YCOgM {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 14px;
    margin-left: 15px;
}

.FeedPostComment_FeedPostHeader__YCOgM h3 {
    margin: 0;
    width: 100%;
}

.FeedPostComment_FeedPostHeader__YCOgM h3 a {
    color: inherit;

}

.FeedPostComment_FeedPostHeader__YCOgM h3 i {
    color: #a8aebd;
    font-size: 14px;
}

.FeedPostComment_Follow__CZXus {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.FeedPostComment_Timestamp__1xN0A {
    color: #a8aebd;
}

.FeedPostComment_Timestamp__1xN0A span {
    margin-right: 6px;
    font-weight: bold;
    color: #757887;
}

.FeedPostComment_FeedPostContent__1VKw3 {
    font-weight: 400;
    word-wrap: break-word;
}

.FeedPostComment_FeedPostContent__1VKw3 pre {
    margin: 0;
    font-size: 15px;
}

.FeedPostComment_FeedPostText__OyN8G {
    margin-bottom: 10px;
}

.FeedPostComment_Images__2EaQW img {
    margin-top: 10px;
    width: 100%;
    max-height: 600px;
}

.FeedPostComment_Videos__PkDdY video {
    margin-top: 10px;
    width: 100%;
}

.FeedPostComment_Input__1g2tw {
    margin-right:0;
    margin-left: auto;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.FeedPostComment_Input__1g2tw textarea {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    height: 100%;
    min-height: 100px;
    min-width: 100%;
    font-size: 16px;
    padding: 13px;
    box-sizing: border-box;
    resize: none;
    transition: background-color .2s, box-shadow .2s;
}

@media (max-width: 1100px) {
    .FeedPostComment_FeedPost__y8Ocj {
        border-right: none;
        border-left: none;
    }

    .FeedPostComment_CreateCommentPopupContainer__D3dO7 {
        width: 100vw;
        height: 100%;
        padding-top: 60px;
        padding-bottom: 15vh;
    }
}

@-webkit-keyframes PlaceHolder_placeHolderShimmer__35t6Q{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes PlaceHolder_placeHolderShimmer__35t6Q{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.PlaceHolder_Container__3AFlO {
    margin-top: 20px;
    width: 100%;
    padding: 15px 10px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
}

.PlaceHolder_PlaceHolderLeft__wIhK8 {
    width: 70px;
    position: relative;
}

.PlaceHolder_PlaceHolderRight__2l5Vl {
    width: calc(100% - 70px);
    padding-left: 10px;
    box-sizing: border-box;
    position: relative;

}

.PlaceHolder_Profile__1YXby {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 15px;
}

.PlaceHolder_TitleStrip__ETS7p {
    width: 50%;
    height: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.PlaceHolder_Strip__W0fix {
    width: 100%;
    height: 12px;
    margin-bottom: 10px;
    overflow: hidden;
}

.PlaceHolder_LinearBackground__2szoy {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: PlaceHolder_placeHolderShimmer__35t6Q;
            animation-name: PlaceHolder_placeHolderShimmer__35t6Q;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.NavigationBar_NavigationBar__MiMUX {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 9px -6px rgba(0,0,0, 0.2);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 900;
    background: white;
    min-width: 0;
    min-height: 0;
    border-bottom: 1px solid var(--color-border-light);
}

.NavigationBar_NavigationBarInner__1jLRL {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-width: var(--page-width);
    position: relative;
    min-width: 0;
    min-height: 0;
}

.NavigationBar_NavigationPrimary__1cjb3 {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
}

.NavigationBar_NavigationBarLeft__2MhKb img {
    max-height: 35px;
    margin-right: 10px;
    image-rendering: -webkit-optimize-contrast;
}

.NavigationBar_NavigationBarLeft__2MhKb a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.NavigationBar_NavigationBarLeft__2MhKb {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 100%;
}

.NavigationBar_SideNavToggle__2dXiz {
    display: none;
}

.NavigationBar_NavigationBarRight__1qMpr {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    height: 100%;
}

.NavigationBar_AccountOption__1VAe1 {
    position: relative;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.NavigationBar_AccountOptionInner__F5FYK {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
}

.NavigationBar_SearchControls__1vwhM {
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    position: relative;
    width: 520px;
    max-width: calc(100vw - 110px);
    padding-left: 160px;
}

.NavigationBar_SearchFilters__1907P {
    width: 100%;
}

.NavigationBar_NavigationLinkIcon__Y1cPG {
    display: none;
}

.NavigationBar_AccountDropdown__2Qj74 {
    position: absolute;
    z-index: 100;
    top: 60px;
    right: 10px;
    width: 350px;
    box-shadow: 0 6px 16px 3px rgba(0,0,0, 0.2);
    border-radius: 3px;
    background-color: white;
    cursor: default;
    border: 1px solid var(--color-input-background-light);
    max-height: 80vh;
    overflow-y: scroll;
}

.NavigationBar_Clear__3RyR4 {
    cursor: pointer;
    padding: 10px 8px;
    color: var(--color-text-dark);
    font-size: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}


@media (max-width: 1200px) {

    .NavigationBar_SearchControls__1vwhM {
        width: 400px;
    }

    .NavigationBar_AccountOption__1VAe1 {
        margin-left: 0;
    }

    .NavigationBar_NavigationBarRightSmall__1gPmv {
        -webkit-flex: 1 1;
                flex: 1 1;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }

    .NavigationBar_AccountOptionInner__F5FYK {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        position: relative;
    }
}

@media (max-width: 700px) {
    .NavigationBar_SearchControls__1vwhM {
        padding-left: 0;
    }

    .NavigationBar_Logo__3S3wp {
        display: none;
    }

    .NavigationBar_SideNavToggle__2dXiz {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        width: 35px;
        height: 35px;
        cursor: pointer;
        border-radius: 100%;
        margin-right: 7px;
    }

    .NavigationBar_SideNavToggle__2dXiz svg {
        width: 17px;
    }

    .NavigationBar_SideNavToggle__2dXiz:hover {
        background-color: var(--color-input-background-light);
    }

    .NavigationBar_AccountDropdown__2Qj74 {
        width: 100vw;
        height: 100%;
        max-height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        padding-bottom: 15vh;
    }
}



.AccountDropdown_ContainerInner__2HEfo {
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.AccountDropdown_ActiveProfile__2gd7F {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.AccountDropdown_ActiveProfileImage__1UYqI {
    height: 120px;
    width: 120px;
    position: relative;
    text-align: center;
    border-radius: 3px;
    overflow: hidden;
}

.AccountDropdown_ActiveProfile__2gd7F h3 {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin: 5px 0 20px 0;
}

.AccountDropdown_ViewProfileButton__268lM {
    padding: 3px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}

.AccountDropdown_ViewProfileButton__268lM:hover {
    background-color: var(--color-primary-washed);
}

.AccountDropdown_OtherProfilesHeader__2IegY {
    border-top: 1px solid var(--color-border-light);
    width: 100%;
    padding: 15px 15px;
    cursor: pointer;
    font-size: 14px;
    color: #3C4043;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    box-sizing: border-box;
}

.AccountDropdown_Profiles__3U-5M {
    transition: max-height .2s ease-out;
}

.AccountDropdown_Profile__Uzf2S {
    width: 100%;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 14px;
    color: #3C4043;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    box-sizing: border-box;
}

.AccountDropdown_Profile__Uzf2S:hover {
    background-color: rgba(205, 214, 229, .15);
}

.AccountDropdown_ProfileName__2HmtZ {
    margin-left: 15px;
    font-size: 15px;
    font-weight: 400;
}

.AccountDropdown_LogoContainer__2_S9n {
    width: 45px;
    height: 45px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.AccountDropdown_LogoContainer__2_S9n i {
    font-size: 15px;
}

.AccountDropdown_LogoutContainer__2Hw_j {
    width: 100%;
    border-top: 1px solid var(--color-border-light);
    padding: 15px 20px;
    box-sizing: border-box;
}

.AccountDropdown_LogoutButton__3jMo3 {
    padding: 8px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #3C4043;
    color: #3C4043;
    font-size: 14px;
    background-color: white;
}

.AccountDropdown_LogoutButton__3jMo3 i {
    font-size: 15px;
    margin-right: 10px;
}


.AccountDropdown_LogoutButton__3jMo3:hover {
    background-color: rgba(205, 214, 229, .15);
}


.FormStyles_BackDrop__3ft5A {
    background-color: var(--color-text-dark);
    opacity: .3;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
}

.FormStyles_PopupInner__2kmrl {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.FormStyles_Header__1WBKK {
    text-align: center;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #EBEBEB;
    padding: 20px 0 20px 0;
}

.FormStyles_Header__1WBKK h2 {
    margin: 0;
    font-size: 18px;
}

.FormStyles_Exit__3xhg2 {
    position: absolute;
    top: 15px;
    left: 15px;
    font-weight: normal;
    background: none;
    outline: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
    color: var(--color-text-dark);
}

.FormStyles_Exit__3xhg2 svg {
    width: 15px;
    color: var(--color-text-dark);
    z-index: 1000;
}

.FormStyles_Exit__3xhg2:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

.FormStyles_FormContainer__1e3Ea {
    padding: 0 50px;
}

.FormStyles_FormInner__1pvkq {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    box-sizing: border-box;
}

.FormStyles_InputHeader__1OHA2 {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.FormStyles_Input__2Dc8v {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #f0f1f5;
    overflow: hidden;
    outline: none;
    font-size: 16px;
    height: 45px;
    border: 1px solid #ebedf2;
}

.FormStyles_TextArea__23PyT {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    background-color: #f0f1f5;
    outline: none;
    font-size: 15px;
    min-height: 200px;
    resize: vertical;
    border: 1px solid #ebedf2;
}

.FormStyles_Label__5YEJH {
    margin-top: 15px;
    margin-bottom: 5px;
    position: relative;
    font-size: 14px;
    text-align: left;
}

.FormStyles_ErrorMessage__3C63K {
    color: red;
    font-size: 12px;
    margin-left: auto;
    margin-right: 0;
}

.FormStyles_Hidden__pxQLf {
    display: none;
}

.FormStyles_LabelUnderline__10R8H {
    text-decoration: underline;
    cursor: pointer;
    margin: 0 3px;
    color: inherit;
}


.FormStyles_Submit__2yUJ0 {
    border: none;
    outline: none;
    padding: 15px 30px;
    border-radius: 4px;
    box-shadow: 0 4px 9px -3px rgba(0,0,0, 0.2);
    cursor: pointer;
    color: white;
    background-color: #0A2540;
    font-size: 16px;
    width: 100%;
    margin: 25px 0 10px 0;
    position: relative;
}

.FormStyles_Submit__2yUJ0:hover {
    box-shadow: 0 4px 9px -2px rgba(0,0,0, 0.4);
}

.FormStyles_Error__1_OGs {
    color: #D93025;
    font-size: 14px;
}

.FormStyles_SwitchPopup__2xYhB {
    text-align: center;
    font-size: 14px;
    width: 100%;
    padding-top: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.FormStyles_TermsAndConditions__3kAOO {
    text-align: center;
    font-size: 13px;
    color: var(--color-text-dark-secondary);
}

.FormStyles_SideSwitchPopupLeft__3geuO{
    text-align: left;
    font-size: inherit;
    -webkit-justify-content: left;
            justify-content: left;
}
.FormStyles_SideSwitchPopupLeft__3geuO{
    text-align: right;
    font-size: inherit;
    -webkit-justify-content: right;
            justify-content: right;
}
.FormStyles_SwitchPopupCenter__3hYCR {
    text-align: inherit;
    -webkit-justify-content: center;
            justify-content: center;
}

.FormStyles_Spinner__17abO {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .4);
    z-index: 110;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.FormStyles_ErrorPopupContainer__1dtjY {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    z-index: 110;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.FormStyles_ErrorPopup__2nJWB {
    position: relative;
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 150px;
    max-width: 200px;
    box-shadow: 0px 4px 9px -2px rgba(0,0,0, 0.4);
    text-align: center;
}

.FormStyles_ErrorPopupIcon__EjqDg {
    width: 100%;
    font-size: 60px;
    color: #cc3300;
}

.FormStyles_SubmitButtonWrapper__1qyzx {
    width: 100%;
    position: relative;
    margin: 10px 0;
    height: 50px;
}

.FormStyles_GoogleButton__1Yna6 {
    background-color: white !important;
    color: black !important;
    border: 1px solid var(--color-text-dark-secondary) !important;
}

.FormStyles_GoogleButton__1Yna6 svg {
    height: 20px;
    margin-right: 15px;
}

.FormStyles_DescriptiveLabel__3YuRR {
    padding: 15px 0 5px 0;
    text-align: left;
}

.FormStyles_CenteredText__2ehWp {
    padding: 15px 0 20px 0;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.FormStyles_CenteredText__2ehWp div {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.FormStyles_CenteredText__2ehWp img {
    margin-top: 20px;
    border-radius: 100%;
    width: 150px;
}

.NavigationButton_NavigationButton__1kWTs {
    height:100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: bold;
    padding: 8px 20px;
    text-decoration: none;
    cursor:pointer;
    color: var(--color-text-dark);
    font-size: 15px;
}

.NavigationButton_NavigationButtonPrimary__2TgGS {
    background-color: var(--color-primary);
    color: white;
    border-radius: 4px;
    margin-left: 15px;
}

@media (max-width: 1100px) {
    .NavigationButton_NavigationButton__1kWTs {
        display: none;
    }
}

.SearchBar_SearchInputContainer__3vzk4 {
    width: 100%;
    position: relative;
}

.SearchBar_Container__2IT1G {
    width: 75%;
    display: -webkit-flex;
    display: flex;
    border-radius: 25px;
    transition: box-shadow 0.1s ease-in-out;
    background-color: var(--color-input-background);
    position: relative;
}

.SearchBar_ContainerFocused__huB_c {
    box-shadow: 0 0 0 1px var(--color-primary);
    background-color: white;
}

.SearchBar_Container__2IT1G input {
    width: calc(100% - 80px);
    height: 42px;
    font-size: 16px;
    outline: none;
    padding: 0 0 0 7px;
    box-sizing: border-box;
    border: none;
    background: none;
}

.SearchBar_Container__2IT1G input::-webkit-input-placeholder {
    color: var(--color-text-dark-secondary);
    font-size: 14px;
}

.SearchBar_Container__2IT1G input:-ms-input-placeholder {
    color: var(--color-text-dark-secondary);
    font-size: 14px;
}

.SearchBar_Container__2IT1G input::placeholder {
    color: var(--color-text-dark-secondary);
    font-size: 14px;
}

.SearchBar_SearchIcon__2uLMl {
    color: var(--color-text-dark-secondary);
    width: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 14px;
}

.SearchBar_SearchIcon__2uLMl svg {
    width: 15px;
}

.SearchBar_ClearInputIcon__KXDX_ {
    width: 40px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    transition: color .1s;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: var(--color-primary);
}

.SearchBar_ClearInputIcon__KXDX_ svg {
    width: 11px;
}

.SearchBar_Suggestions__16l6n {
    top: calc(100% + 3px);
    border-top: 1px solid #F5F7FA;
    position: absolute;
    z-index: 10000;
    width: 500px;
    max-width: 100vw;
    box-shadow: 0 4px 20px 1px rgba(0,0,0, 0.3);
    background: white;
    border-radius: 3px;
    max-height: 80vh;
    overflow-y: scroll;
}

.SearchBar_BrowseOption__1mL6a {
    padding: 10px;
    cursor: pointer;
    color: var(--color-text-dark-secondary);
    font-size: 14px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.SearchBar_BrowseOption__1mL6a svg {
    width: 14px;
    margin-right: 10px;
}

.SearchBar_BrowseOption__1mL6a:hover {
    background: #F5F7FA;
}

.SearchBar_SuggestionHeader__2R4W9 {
    width: 100%;
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--color-input-background-light);
}

.SearchBar_Suggestion__zeC7C {
    width: 100%;
    padding: 7px;
    box-sizing: border-box;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.SearchBar_Suggestion__zeC7C:hover {
    background: #F5F7FA;
}

.SearchBar_SuggestionLogo__Ll5ff {
    width: 37px;
    height: 37px;
    position: relative;
    margin-right: 10px;
}

.SearchBar_SuggestionDescription__3GRmr {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
}

@media (max-width: 1100px) {
    .SearchBar_Suggestions__16l6n {
        top: 57px;
        left: 0;
        border-top: 1px solid #F5F7FA;
        position: fixed;
        z-index: 10000;
        height: calc(100% - 60px);
        max-height: calc(100% - 60px);
        width: 100vw;
        background: white;
        overflow-y: scroll;
        box-shadow: none;
    }

    .SearchBar_Container__2IT1G {
        width: 100%;
    }
}

.NavigationSlide_Exit__2Lzvh {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 10px 10px 0 10px;
}

.NavigationSlide_ExitIcon__1ieLg {
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.NavigationSlide_ExitIcon__1ieLg:hover {
    background-color: #F5F7FA;
    border-radius: 100%;
}

.NavigationSlide_NavigationSmallOptionsActive__2i9rE {
    position: fixed;
    left: 0 !important;
    z-index: 2000;
    transition: left .2s;
    box-shadow: 0 4px 20px 1px rgba(0,0,0, 0.3);
}

.NavigationSlide_NavigationSmallOptions__1CVJ7 {
    position: fixed;
    background-color: white;
    width: 250px;
    max-width: 100vw;
    height: 100%;
    top: 0;
    left: -250px;
    z-index: 100;
    transition: left .2s;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 0 0 10px;
    box-sizing: border-box;
}

.NavigationSlide_Backdrop__dSG9m {
    background-color: var(--color-text-dark);
    opacity: .1;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
}

.NavigationSlide_Option__2cbJo {
    font-size: 20px;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
}

.NavigationSlide_Option__2cbJo:hover {
    background-color: #F5F7FA;
}

.GoogleAccountRedirect_Container__1SIKX {
    width: 100%;
    padding-top: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.GoogleAccountRedirect_Spinner__1AnhC {
    width: 100%;
    height: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.ConversationsPopup_Container__3tPzA {
    position: fixed;
    bottom: 0;
    right: 50px;
    width: 400px;
    box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
}

.ConversationsPopup_Icon__3x-hW {
    position: absolute;
    top: 5px;
    right: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    -webkit-justify-content: center;
            justify-content: center;
}

.ConversationsPopup_Icon__3x-hW:hover {
    background-color: var(--color-input-background-light);
}

.ConversationsPopup_Icon__3x-hW svg {
    width: 24px;
    max-height: 22px;
}

.ConversationsPopup_Open__ACGCr {
    height: 80vh;
    transition: height .3s ease-out;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
}

.ConversationsPopup_Closed__1mQZT {
    height: 60px;
    transition: height .3s ease-out;
}

@media (max-width: 700px) {

    .ConversationsPopup_Container__3tPzA {
        display: none;
    }
}

.Conversations_Header__3gzzs {
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px 15px;
    box-sizing: border-box;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
}

.Conversations_Header__3gzzs h3 {
    margin: 0;
}

.Conversations_HeaderTitle__3Oiu6 {
    margin-left: 15px;
}

.Conversations_HeaderTitle__3Oiu6 a {
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    margin: 0;
}

.Conversations_HeaderTitle__3Oiu6 a:hover {
    text-decoration: underline;
}

.Conversations_HeaderLogo__1drnh {
    width: 40px;
    height: 40px;
    position: relative;
}

.Conversations_HeaderIcons__1t7ly {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.Conversations_ProfileMetadata__3aDk8 {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
}

.Conversations_Icon__2COTq {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    border-radius: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    width: 40px;
    height: 40px;
}

.Conversations_Icon__2COTq:hover {
    background-color: var(--color-input-background);
}

.Conversations_Icon__2COTq svg {
    width: 15px;
}

.Conversations_Conversations__kYs0X {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    overflow-y: scroll;
}

.Conversations_SpinnerContainer__fGf-a {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    padding: 70px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
}

.Conversations_Message__1oBuL {
    display: -webkit-flex;
    display: flex;
    padding: 15px;
    border-bottom: 1px solid var(--color-border-light);
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    font-size: 14px;
}

.Conversations_Message__1oBuL:hover {
    background-color: rgba(205, 214, 229, .15);
}

.Conversations_MessageLeft__1JU-w {
    width: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.Conversations_Logo__2RD0u {
    width: 50px;
    height: 50px;
}

.Conversations_MessageRight__t47Ks {
    padding-left: 15px;
    width: calc(100% - 50px);
    box-sizing: border-box;
}

.Conversations_MessageTitle__39tgn {
    display: -webkit-flex;
    display: flex;
}

.Conversations_MessageName__c35sq {
    font-weight: bold;
    font-size: 16px;
}

.Conversations_Timestamp__vTlKq {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    text-align: right;
}


.Conversations_MessageContent__16NcS {
    color: var(--color-text-dark-secondary);
}

.Conversations_MessageContent__16NcS pre {
    margin: 0;
}

@media (max-width: 700px) {
    .Conversations_Header__3gzzs {
        padding-left: 10px;
    }

    .Conversations_MessageLeft__1JU-w {
        width: 40px;
    }

    .Conversations_MessageRight__t47Ks {
        width: calc(100% - 40px);
    }

    .Conversations_Logo__2RD0u {
        width: 40px;
        height: 40px;
    }

    .Conversations_Message__1oBuL {
        font-size: 14px;
    }
}

.Conversation_Messages__1QYwa {
    padding: 0 15px 0 15px;
    box-sizing: border-box;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    overflow-y: scroll;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

.Conversation_Link__2cRVH {
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: none;
}

.Conversation_PlaceHolders__1Z61D {
    margin-bottom: auto;
    padding-top: 20px;
}

.Conversation_Message__2HzB4:first-child {
    margin-bottom: auto;
}

.Conversation_Message__2HzB4 {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    position: relative;
    padding: 20px 0 0 0;
}

.Conversation_Logo__TXXFD {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.Conversation_MessageContent__1WSYI {
    margin:  0 10px 0 0;
    max-width: calc(100% - 50px);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 14px;
}

.Conversation_MessageContent__1WSYI pre {
    margin: 0;
    font-size: 14px;
}

.Conversation_MessageContent__1WSYI h3 {
    margin: 0;
    cursor: pointer;
}

.Conversation_MessageContent__1WSYI h3:hover {
    text-decoration: underline;
}

.Conversation_MessageContentHeader__3UTcT {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 7px;
}

.Conversation_Timestamp__1hbnL {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
    margin-left: 10px;
}

.Conversation_CreateMessage__1LsTN {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 5px;
    border-top: 1px solid var(--color-border-light);
    box-sizing: border-box;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
}

.Conversation_InputContainer__3oRd2 {
    padding: 0 5px;
    width: calc(100% - 40px);
    position: relative;
    box-sizing: border-box;
}

.Conversation_InputContainer__3oRd2 input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    background-color: var(--color-input-background);
    border: none;
    border-radius: 25px;
    outline: none;
    padding: 0 15px;
    font-size: 16px;
}

.Conversation_InputContainer__3oRd2 input:focus {
    background-color: white;
    box-shadow: 0 0 0 1px var(--color-primary);
}

.Conversation_CreateMessageButton__1XYKT {
    position: relative;
    height: 40px;
    width: 40px;
    background-color: white;
    color: var(--color-primary);
    box-shadow: none !important;
}

.Conversation_CreateMessageButton__1XYKT svg {
    width: 20px;
}

@-webkit-keyframes PlaceHolder_placeHolderShimmer__3orQx{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes PlaceHolder_placeHolderShimmer__3orQx{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.PlaceHolder_Container__4QLdp {
    width: 100%;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.PlaceHolder_SplitContainer__1hqN6 {
    display: -webkit-flex;
    display: flex;
}

.PlaceHolder_Profile__24_sX {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 15px;
}

.PlaceHolder_Metadata__3LR-Z {
    width: calc(100% - 45px);
    padding-left: 10px;
    box-sizing: border-box;
}

.PlaceHolder_TitleStrip__pkd07 {
    width: 30%;
    height: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.PlaceHolder_Strip__21ZTV {
    width: 100%;
    height: 12px;
    margin-bottom: 7px;
    overflow: hidden;
}

.PlaceHolder_LinearBackground__3MOIz {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: PlaceHolder_placeHolderShimmer__3orQx;
            animation-name: PlaceHolder_placeHolderShimmer__3orQx;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

@-webkit-keyframes PlaceHolder_placeHolderShimmer__b7V5O{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes PlaceHolder_placeHolderShimmer__b7V5O{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.PlaceHolder_Container__He9_L {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.PlaceHolder_SplitContainer__1KQ5K {
    display: -webkit-flex;
    display: flex;
}

.PlaceHolder_Profile__S9uQf {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 15px;
}

.PlaceHolder_Metadata__2CDQv {
    width: calc(100% - 50px);
    padding-left: 20px;
    box-sizing: border-box;
}

.PlaceHolder_TitleStrip__17KkG {
    width: 30%;
    height: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.PlaceHolder_Strip__Gu0YV {
    width: 100%;
    height: 12px;
    margin-bottom: 7px;
    overflow: hidden;
}

.PlaceHolder_LinearBackground__2mEav {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: PlaceHolder_placeHolderShimmer__b7V5O;
            animation-name: PlaceHolder_placeHolderShimmer__b7V5O;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.CreateConversation_Messages__9zT3V {
    box-sizing: border-box;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    overflow-y: auto;
}

.CreateConversation_Message__35zr8 {
    display: -webkit-flex;
    display: flex;
}

.CreateConversation_OutgoingMessage__29-m4 {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.CreateConversation_OutgoingMessage__29-m4 > .CreateConversation_MessageContent__2QcAt {
    background-color: var(--color-primary);
    color: white;
}

.CreateConversation_Logo__3TvDb {
    width: 40px;
    height: 40px;
}

.CreateConversation_MessageContent__2QcAt {
    margin:  0 10px 20px 10px;
    width: calc(100% - 60px);
    background-color: var(--color-input-background);
    padding: 10px;
    border-radius: 10px;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.CreateConversation_CreateMessage__1VPPD {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 5px;
    border-top: 1px solid var(--color-border-light);
    box-sizing: border-box;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
}

.CreateConversation_RecipientInputContainer__3rlWD {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
}

.CreateConversation_RecipientInputContainer__3rlWD input {
    width: 100%;
    outline: none;
    padding: 0 15px;
    font-size: 16px;
    border: none;
    height: 50px;
    box-sizing: border-box;
}

.CreateConversation_SelectedRecipientContainer__rXFYD {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 25px;
    overflow: hidden;
    margin: 10px 10px;
    padding: 3px 6px 3px 3px;
    box-shadow: 0 0 0 1px var(--color-border-light);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.CreateConversation_SelectedRecipientLogo__PskYl {
    width: 37px;
    height: 37px;
    position: relative;
}

.CreateConversation_SelectedRecipientContainer__rXFYD svg {
    width: 14px;
    max-height: 18px;
}

.CreateConversation_SelectedRecipientName__3vSVS {
    margin-left: 10px;
    margin-right: 5px;
}

.CreateConversation_SelectedRecipientClose__1iWDp {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
}

.CreateConversation_SelectedRecipientClose__1iWDp:hover {
    background-color: var(--color-input-background);
}

.CreateConversation_InputContainer__LNwGD {
    padding: 0 5px;
    width: calc(100% - 40px);
    position: relative;
    box-sizing: border-box;
}

.CreateConversation_InputContainer__LNwGD input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    background-color: var(--color-input-background);
    border: none;
    border-radius: 25px;
    outline: none;
    padding: 0 15px;
    font-size: 16px;
}

.CreateConversation_InputContainer__LNwGD input:focus {
    background-color: white;
    box-shadow: 0 0 0 1px var(--color-primary);
}

.CreateConversation_CreateMessageButton__1p4C5 {
    position: relative;
    height: 40px;
    width: 40px;
    background-color: white;
    color: var(--color-primary);
    box-shadow: none !important;
}

.CreateConversation_CreateMessageButton__1p4C5 svg {
    width: 20px;
}









.CreateConversation_SuggestionHeader__3VtPL {
    width: 100%;
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--color-input-background-light);
}

.CreateConversation_Suggestion__2YKN9 {
    width: 100%;
    padding: 7px;
    box-sizing: border-box;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.CreateConversation_Suggestion__2YKN9:hover {
    background: #F5F7FA;
}

.CreateConversation_SuggestionLogo__1T6OJ {
    width: 37px;
    height: 37px;
    position: relative;
}

.CreateConversation_SuggestionDetails__1wTD8 {
    width: calc(100% - 37px);
    padding-left: 10px;
    box-sizing: border-box;
}

.CreateConversation_SuggestionDescription__2Exvx {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
}

.ConversationsPage_ContentContainerInner__14kjx {
    width: 100%;
    max-width: var(--page-width);
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 100%;
}

.ConversationsPage_Conversations__3EoBu {
    width: calc(100% - 220px - 340px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    position: relative;
    box-sizing: border-box;
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
    -webkit-flex-flow: column;
            flex-flow: column;
    height: 100%;
}

.ConversationsPage_SideNav__377qM {
    width: 220px;
}


@media (max-width: 1200px) {
    .ConversationsPage_SideNav__377qM {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .ConversationsPage_Conversations__3EoBu {
        width: calc(100% - 220px - 15px);
        margin-right: 15px;
    }
}

@media (max-width: 700px) {

    .ConversationsPage_SideNav__377qM {
        display: none;
    }

    .ConversationsPage_Conversations__3EoBu {
        width: 100%;
        padding-right: 0;
        border-left: none;
        border-right: none;
        margin-right: 0;
    }
}

.Legal_TableOfContents__w_MwH {
    width: 220px;
    padding-right: 50px;
    box-sizing: border-box;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Legal_Link__2vbbb {
    color: #44629E;
    padding: 5px 0;
    font-size: 14px;
    cursor: pointer;
}

.Legal_Link__2vbbb:hover {
    text-decoration: underline;
}

.Legal_Content__3drtj {
    width: calc(100% - 220px);
    position: relative;
    padding-bottom: 80px;
}

.Legal_Section__3O1VI {
    width: 100%;
    padding-right: 80px;
    box-sizing: border-box;
}

.Legal_Section__3O1VI pre {
    padding-left: 30px;
    font-size: 15px;
    margin: 0;
}

.Legal_Section__3O1VI pre > div {
    margin-bottom: 20px;
}

.Legal_Section__3O1VI h3 {
    width: 100%;
    padding: 20px 0 10px 0;
    margin: 0;
    font-weight: bold;
    font-size: 18px;
}

@media (max-width: 700px) {
    .Legal_TableOfContents__w_MwH {
        display: none;
    }

    .Legal_Content__3drtj {
        width: 100%;
        padding: 0 20px;
    }

    .Legal_Section__3O1VI {
        padding: 0;
    }
}

.SignInPopUp_SignInPopUp__1mC7X {
    width: 600px;
    z-index: 1000;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
    padding-bottom: 15px;
}

.SignInPopUp_ResendEmailButtonContainer__2DBUI {
    padding: 10px 0 10px 0;
    margin-right: 120px;
    margin-left: 120px;
    height: 40px;
}

@media (max-width: 1100px) {
    .SignInPopUp_SignInPopUp__1mC7X {
        width: 100vw;
        height: 100vh;
        padding-top: 60px;
    }
}

.TextInput_LabelFocused__3Xa5G {
    position: absolute;
    top: 3px;
    left: 3px;
    color: var(--color-text-dark);
    font-size: 13px;
    transition: top .1s linear, font-size .1s linear, left .1s linear;
}

.TextInput_LabelUnfocused__3FBmy {
    position: absolute;
    font-size: 14px;
    top: calc(50% - 10px);
    left: 5px;
    color: var(--color-text-dark);
    transition: top .1s linear, font-size .1s linear, left .1s linear;
}

.TextInput_ContainerOuter__175do {
    width: 100%;
    position: relative;
}

.TextInput_Container__248DY {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    font-size: 15px;
    height: 50px;
    box-sizing: border-box;
    position: relative;
    margin: 10px 0;
    box-shadow: 0 0 0 1px var(--color-text-area-border);
}

.TextInput_ContainerFocused__pI98I {
    box-shadow: 0 0 0 2px var(--color-primary);
}

.TextInput_Error__2h-3s {
    color: #D93025;
    font-size: 12px;
    margin-bottom: 5px;
}

.TextInput_Error__2h-3s i {
    margin-right: 3px;
}

.TextInput_ContainerError__21w87 {
    box-shadow: 0 0 0 2px #D93025;
}

.TextInput_Container__248DY input {
    font-size: 16px;
    padding: 20px 5px 0 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    background: none;
}

.TextInput_Container__248DY input::-webkit-textfield-decoration-container {
    visibility: hidden;
}


.TextInput_Icon__3aVP9 {
    position: absolute;
    right: 15px;
    top: calc(50% - 10px);
    color: var(--color-primary);
    font-size: 16px;
}

.SignUpPopUp_SignUpPopUp__2fWiJ {
    width: 600px;
    z-index: 1000;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
    padding-bottom: 15px;
}

@media (max-width: 1100px) {
    .SignUpPopUp_SignUpPopUp__2fWiJ {
        width: 100vw;
        height: 100vh;
        padding-top: 60px;
    }
}

.CreateProfile_Container__3bM_J {
    width: 650px;
    background-color: white;
    border-radius: 3px;
    padding: 60px 40px 40px 40px;
    box-sizing: border-box;
    position: relative;
    z-index: 1000;
    max-width: 100%;
}

.CreateProfile_InvisibleInput__bdxb9 {
    display: none;
}

.CreateProfile_CreateProfileHeader__2oLZe {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
}

.CreateProfile_CreateProfileDescription__2uSfn {
    font-size: 14px;
    color: var(--color-text-dark-secondary);
    margin-bottom: 20px;
}

.CreateProfile_CreateProfileHeaderCentered__23FGs {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.CreateProfile_NextButton__26EhL {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 20px;
    margin-left: 20px;
}

.CreateProfile_PreviousButton__g_b6p {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 20px;
    margin-right: 20px;
    background-color: white;
    border: 1px solid var(--color-text-dark-secondary);
    color: var(--color-text-dark);
}

.CreateProfile_FormContainer__2-jce {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 20px;
}

.CreateProfile_ProfileTypeContainer__193D6 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 10px;
}

.CreateProfile_ProfileTypeContainerActive__m5wlE {
    background-color: var(--color-primary-washed);
}

.CreateProfile_ProfileTypeContainer__193D6 h2 {
    margin: 0;
    font-size: 20px;
}

.CreateProfile_ProfileTypeContainer__193D6 svg {
    width: 60px;
}

.CreateProfile_ProfileTypeIcon__3BNQU {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.CreateProfile_ProfileTypeContent__33a6i {
    padding-left: 20px;
    box-sizing: border-box;
}

.CreateProfile_ProfileTypeContentDescription__3D36m {
    margin-top: 10px;
    font-size: 14px;
    color: var(--color-text-dark-secondary);
}

.CreateProfile_Navigation__2juzk {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    margin-top: 40px;
    box-sizing: border-box;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.CreateProfile_NavigationSingleButton__4E_jb {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    box-sizing: border-box;
}

.CreateProfile_PageDetailsContainer__8iItG {
    width: 100%;
    text-align: center;
}

.CreateProfile_Exit__1vY2g {
    position: absolute;
    top: 15px;
    left: 15px;
    font-weight: normal;
    background: none;
    outline: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
}

.CreateProfile_Exit__1vY2g svg {
    width: 15px;
}

.CreateProfile_Exit__1vY2g:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

.CreateProfile_NameContent__2ahWg {
    margin: 20px 0;
}

@media (max-width: 700px) {
    .CreateProfile_Container__3bM_J {
        width: 100vw;
        height: 100vh;
        padding: 60px 20px 15vh 20px;
        overflow-y: scroll;
    }
}

/* Basic Details */
.InvestorQuestionnaire_Content__2oeJV {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.InvestorQuestionnaire_BasicDetailsLeft__2Rl3y {
    width: 175px;
}

.InvestorQuestionnaire_BasicDetailsRight__122zo {
    width: calc(100% - 175px);
    padding-left: 30px;
    box-sizing: border-box;
}

.InvestorQuestionnaire_ImageContainer__3DwUE {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 25px 0 10px 0;
    position: relative;
}

.InvestorQuestionnaire_ImageContainerInner__3kcx6 {
    width: 175px;
    height: 175px;
    position: relative;
}

.InvestorQuestionnaire_ChangeButton__3JDN1 {
    border: 1px solid var(--color-border-light);
    color: var(--color-text-dark);
    background-color: white;
    position: absolute;
    top: 7px;
    right: 7px;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.InvestorQuestionnaire_ChangeButton__3JDN1 svg {
    width: 20px;
}

@media (max-width: 700px) {
    .InvestorQuestionnaire_BasicDetailsLeft__2Rl3y {
        width: 100%;
    }

    .InvestorQuestionnaire_BasicDetailsRight__122zo {
        width: 100%;
        padding-left: 0;
    }
}

/* Basic Details */
.StartupQuestionnaire_Content__JjfjQ {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.StartupQuestionnaire_BasicDetailsLeft__1OI8Y {
    width: 175px;
}

.StartupQuestionnaire_BasicDetailsRight__Ekx3O {
    width: calc(100% - 175px);
    padding-left: 30px;
    box-sizing: border-box;
}

.StartupQuestionnaire_ImageContainer__Zvmfh {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 25px 0 10px 0;
    position: relative;
}

.StartupQuestionnaire_ImageContainerInner__29LAG {
    width: 175px;
    height: 175px;
    position: relative;
}

.StartupQuestionnaire_ChangeButton__1ipi4 {
    border: 1px solid var(--color-border-light);
    color: var(--color-text-dark);
    background-color: white;
    position: absolute;
    top: 7px;
    right: 7px;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.StartupQuestionnaire_ChangeButton__1ipi4 svg {
    width: 20px;
}

@media (max-width: 700px) {
    .StartupQuestionnaire_BasicDetailsLeft__1OI8Y {
        width: 100%;
    }

    .StartupQuestionnaire_BasicDetailsRight__Ekx3O {
        width: 100%;
        padding-left: 0;
    }
}

.PersonalProfileOnboarding_Container__1_97S {
    width: 650px;
    background-color: white;
    border-radius: 3px;
    padding: 60px 40px 40px 40px;
    box-sizing: border-box;
    position: relative;
    z-index: 1000;
    max-width: 100%;
}

.PersonalProfileOnboarding_Exit__jsx6- {
    position: absolute;
    top: 15px;
    left: 15px;
    font-weight: normal;
    background: none;
    outline: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100%;
}

.PersonalProfileOnboarding_Exit__jsx6- svg {
    width: 15px;
}

.PersonalProfileOnboarding_Exit__jsx6-:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

/* Basic Details */
.PersonalProfileOnboarding_Content__50_8K {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.PersonalProfileOnboarding_BasicDetailsLeft__20YDd {
    width: 175px;
}

.PersonalProfileOnboarding_BasicDetailsRight__3SRL_ {
    width: calc(100% - 175px);
    padding-left: 30px;
    box-sizing: border-box;
}

.PersonalProfileOnboarding_ImageContainer__3yC54 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 25px 0 10px 0;
    box-sizing: border-box;
    position: relative;
}

.PersonalProfileOnboarding_ImageContainerInner__VWAHZ {
    width: 175px;
    height: 175px;
    position: relative;
}

.PersonalProfileOnboarding_ChangeButton__2fgft {
    border: 1px solid var(--color-border-light);
    color: var(--color-text-dark);
    background-color: white;
    position: absolute;
    top: 7px;
    right: 7px;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.PersonalProfileOnboarding_ChangeButton__2fgft svg {
    width: 20px;
}

@media (max-width: 700px) {
    .PersonalProfileOnboarding_BasicDetailsLeft__20YDd {
        width: 100%;
    }

    .PersonalProfileOnboarding_BasicDetailsRight__3SRL_ {
        width: 100%;
        padding-left: 0;
    }

    .PersonalProfileOnboarding_Container__1_97S {
        width: 100vw;
        height: 100vh;
        padding: 60px 20px 15vh 20px;
        overflow-y: scroll;
    }
}

