:root {
    --color-primary: rgba(83, 144, 253, 1);
    --color-primary-washed: rgba(83, 144, 253, .1);
    --color-border-light: rgba(205, 214, 229, .55);
    --color-text-secondary: rgba(169, 175, 190, 1);
    --color-input-background: rgba(233, 236, 241, 1);
    --color-text-area-border: rgba(218, 221, 226, 1);
    --color-input-background-light: rgba(238, 241, 246, .6);
    --color-input-background-very-light: rgba(238, 241, 246, .4);
    --color-text-dark: rgba(10, 37, 64, 1);
    --color-text-dark-secondary: rgba(10, 37, 64, .6);
    --color-text-dark-secondary-darker: rgba(10, 37, 64, .8);
    --page-width: 1200px;
}

.ContentContainer {
    flex: 1 1 auto;
    overscroll-behavior: contain !important;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    height: 100%;
}

.ContentContainerInner {
    width: 100%;
    max-width: var(--page-width);
    display: flex;
    position: relative;
    flex-wrap: wrap;
}

.ClimateAds {
    width: 340px;
    padding-left: 20px;
    box-sizing: border-box;
    top: 20px;
    margin-top: 20px;
    position: sticky;
    height: fit-content;
    max-height: calc(100% - 80px);
    overflow-y: auto;
}

.ImageContainer {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.ImageContainer img {
    width: 100%;
    margin-bottom: 10px;
}

.PeopleContainer {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.EditIconContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}

@media (max-width: 1200px) {

    .ClimateAds {
        display: none;
    }

    .ContentContainerInner {
        padding-top: 0;
    }
}
