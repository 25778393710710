.TableOfContents {
    width: 220px;
    padding-right: 50px;
    box-sizing: border-box;
    position: sticky;
    top: 20px;
    height: fit-content;
}

.Link {
    color: #44629E;
    padding: 5px 0;
    font-size: 14px;
    cursor: pointer;
}

.Link:hover {
    text-decoration: underline;
}

.Content {
    width: calc(100% - 220px);
    position: relative;
    padding-bottom: 80px;
}

.Section {
    width: 100%;
    padding-right: 80px;
    box-sizing: border-box;
}

.Section pre {
    padding-left: 30px;
    font-size: 15px;
    margin: 0;
}

.Section pre > div {
    margin-bottom: 20px;
}

.Section h3 {
    width: 100%;
    padding: 20px 0 10px 0;
    margin: 0;
    font-weight: bold;
    font-size: 18px;
}

@media (max-width: 700px) {
    .TableOfContents {
        display: none;
    }

    .Content {
        width: 100%;
        padding: 0 20px;
    }

    .Section {
        padding: 0;
    }
}
