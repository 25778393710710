.Container {
    position: relative;
    width: 100%;
    margin-bottom: -90px;
    max-width: 100%;
}

.SearchBar {
    width: fit-content;
    box-shadow: 0 0 8px -3px rgba(0,0,0, 0.5);
    border-radius: 45px;
    display: flex;
    margin-bottom: 30px;
    position: relative;
    background-color: white;
    align-items: center;
    max-width: 100%;
}

.QuestionBubble {
    padding: 17px 27px;
    font-size: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: left;
    min-width: 200px;
    max-width: 100%;
}

.Question {
    position: relative;
}

.Question:hover {
    cursor: pointer;
}

.Question svg {
    width: 24px;
    max-height: 22px;
    margin-right: 18px;
    color: var(--color-text-dark-secondary);
}

.QuestionSearch {
    height: 48px;
    border-radius: 25px;
    background-color: var(--color-primary);
    color: white;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    cursor: pointer;
}

.QuestionSearch svg {
    width: 17px;
    margin-right: 10px;
}

.ExploreIcon {
    display: none;
}

.QuestionActive {
    box-shadow: 2px 4px 12px -3px rgba(0,0,0, 0.5);
    border-radius: 45px;
}

.QuestionActive > .QuestionBubble {
    -webkit-box-shadow: 0 0 0 1px var(--color-text-dark);
    box-shadow: 0 0 0 1px var(--color-text-dark);
    width: 100%;
    height: 100%;
    border-radius: 45px;
    transition: box-shadow .2s;
}

.QuestionBubble > div > div {
    color: var(--color-text-dark) !important;
    font-weight: bold;
}

.QuestionOptions {
    width: fit-content;
    position: absolute;
    box-shadow: 2px 3px 9px -3px rgba(0,0,0, 0.5);
    background-color: white;
    top: 80px;
    border-radius: 10px;
    border: 1px solid var(--color-border-light);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    z-index: 1000000000;
}

.LeftOptions {
    left: 0;
}

.QuestionDivider {
    height: 40px;
    width: 1px;
    background-color: var(--color-border-light);
}

.QuestionDividerHidden {
    visibility: hidden;
}

.MiddleOptions {
    left: 180px;
}

.RightOptions {
    right: 230px;
}

.QuestionOption {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 25px;
    font-size: 16px;
    color: var(--color-text-dark-secondary);
    cursor: pointer;
    box-sizing: border-box;
}

.QuestionOptionFull {
    width: 100%;
}

.QuestionOption:hover {
    background-color: var(--color-input-background-light);
}

.QuestionOption svg {
    width: 22px;
    max-height: 20px;
    margin-right: 20px;
    color: var(--color-text-dark);
}

.Question h3 {
    font-size: 14px;
    margin: 0;
    margin-bottom: 3px;
}

.Question div  > div{
    color: var(--color-text-secondary);
    font-size: 14px;
}

.SearchInput {
    font-weight: bold;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.SearchInput {
    padding: 10px;
}

.SearchInput svg {
    width: 15px;
    margin-right: 30px;
}

.ContentMobile {
    display: none;
}

@media (max-width: 1200px) {
    .SearchBar {
        margin-left: auto;
        margin-right: auto;
    }

    .QuestionBubble {
        min-width: 100px;
    }

    .Question h3 {
        font-size: 12px;
    }

    .LeftOptions {
        left: 165px;
    }
}

@media (max-width: 700px) {
    .ContentNonMobile {
        display: none;
    }

    .ContentMobile {
        display: block;
        font-size: 11px !important;
    }

    .Question {
        width: calc(33.33% - (40px / 3));
    }

    .Question h3 {
        font-size: 12px;
    }

    .QuestionBubble {
        padding: 17px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        text-align: left;
        min-width: 100%;
        width: calc(33.33% - (50px / 3)) !important;
    }

    .QuestionSearch {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0 5px;
    }

    .QuestionSearch svg {
        margin: 0;
    }

    .ExploreIcon {
        display: flex;
    }

    .ExploreText {
        display: none;
    }

    .SelectText {
        display: none;
    }

    .SearchBar {
        width: 100%;
    }

    .QuestionOptions {
        width: 100%;
    }

    .MiddleOptions {
        left: 0;
    }

    .LeftOptions {
        left: 0;
    }

    .RightOptions {
        left: 0;
    }

    .QuestionOption {
        font-size: 12px;
        padding: 10px 25px;
    }
}


@media (max-width: 400px) {
    .Question h3 {
        font-size: 11px;
    }

    .Question svg {
        display: none;
    }
}
