@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


body {
  margin: 0;
  font-family: Roboto, 'Gothic A1', Arial, sans-serif;
  color: var(--color-text-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

h3, h2, h1 {
  font-family: Roboto, 'Gothic A1', Arial, sans-serif;
  line-height: 1.2;
}

input, textarea, div {
  font-family: Roboto, 'Gothic A1', Arial, sans-serif;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: Roboto, 'Gothic A1', Arial, sans-serif;
}
