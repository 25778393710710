.Messages {
    padding: 0 15px 0 15px;
    box-sizing: border-box;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
    flex-direction: column-reverse;
}

.Link {
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: none;
}

.PlaceHolders {
    margin-bottom: auto;
    padding-top: 20px;
}

.Message:first-child {
    margin-bottom: auto;
}

.Message {
    display: flex;
    width: 100%;
    position: relative;
    padding: 20px 0 0 0;
}

.Logo {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.MessageContent {
    margin:  0 10px 0 0;
    max-width: calc(100% - 50px);
    width: fit-content;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    flex-direction: column;
    font-size: 14px;
}

.MessageContent pre {
    margin: 0;
    font-size: 14px;
}

.MessageContent h3 {
    margin: 0;
    cursor: pointer;
}

.MessageContent h3:hover {
    text-decoration: underline;
}

.MessageContentHeader {
    display: flex;
    margin-bottom: 7px;
}

.Timestamp {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
    margin-left: 10px;
}

.CreateMessage {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 5px;
    border-top: 1px solid var(--color-border-light);
    box-sizing: border-box;
    height: fit-content;
    flex: 0 1 auto;
}

.InputContainer {
    padding: 0 5px;
    width: calc(100% - 40px);
    position: relative;
    box-sizing: border-box;
}

.InputContainer input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    background-color: var(--color-input-background);
    border: none;
    border-radius: 25px;
    outline: none;
    padding: 0 15px;
    font-size: 16px;
}

.InputContainer input:focus {
    background-color: white;
    -webkit-box-shadow: 0 0 0 1px var(--color-primary);
    box-shadow: 0 0 0 1px var(--color-primary);
}

.CreateMessageButton {
    position: relative;
    height: 40px;
    width: 40px;
    background-color: white;
    color: var(--color-primary);
    box-shadow: none !important;
}

.CreateMessageButton svg {
    width: 20px;
}
