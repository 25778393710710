@import '../../../styles/Styles.module.css';

.Dropdown {
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 10px 1px rgba(0,0,0, 0.3);
    border-radius: 5px;
    z-index: 2000;
}

.DropdownOption {
    padding: 7px 10px;
    width: 150px;
    cursor: pointer;
}

.DropdownOption:hover {
    background-color: var(--color-border-light);
}

.HeaderOptionActive i {
    margin-right: 7px;
}

.HeaderOptionActive {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 45px;
    margin-right: 10px;
    font-size: 15px;
    background-color: var(--color-primary);
    color: white;
    font-weight: bold;
    transition: background-color .1s linear, color .1s linear;
}

.Caret i {
    margin: 0 0 0 6px;
}
