.SignUpPopUp {
    width: 600px;
    z-index: 1000;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
    padding-bottom: 15px;
}

@media (max-width: 1100px) {
    .SignUpPopUp {
        width: 100vw;
        height: 100vh;
        padding-top: 60px;
    }
}
