.ContainerInner {
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.ActiveProfile {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.ActiveProfileImage {
    height: 120px;
    width: 120px;
    position: relative;
    text-align: center;
    border-radius: 3px;
    overflow: hidden;
}

.ActiveProfile h3 {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin: 5px 0 20px 0;
}

.ViewProfileButton {
    padding: 3px 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}

.ViewProfileButton:hover {
    background-color: var(--color-primary-washed);
}

.OtherProfilesHeader {
    border-top: 1px solid var(--color-border-light);
    width: 100%;
    padding: 15px 15px;
    cursor: pointer;
    font-size: 14px;
    color: #3C4043;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.Profiles {
    transition: max-height .2s ease-out;
}

.Profile {
    width: 100%;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 14px;
    color: #3C4043;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.Profile:hover {
    background-color: rgba(205, 214, 229, .15);
}

.ProfileName {
    margin-left: 15px;
    font-size: 15px;
    font-weight: 400;
}

.LogoContainer {
    width: 45px;
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LogoContainer i {
    font-size: 15px;
}

.LogoutContainer {
    width: 100%;
    border-top: 1px solid var(--color-border-light);
    padding: 15px 20px;
    box-sizing: border-box;
}

.LogoutButton {
    padding: 8px 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #3C4043;
    color: #3C4043;
    font-size: 14px;
    background-color: white;
}

.LogoutButton i {
    font-size: 15px;
    margin-right: 10px;
}


.LogoutButton:hover {
    background-color: rgba(205, 214, 229, .15);
}

