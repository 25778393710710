.ContentContainerInner {
    width: 100%;
    max-width: var(--page-width);
    display: flex;
    position: relative;
    flex-wrap: wrap;
    height: 100%;
}

.Conversations {
    width: calc(100% - 220px - 340px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    position: relative;
    box-sizing: border-box;
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
    flex-flow: column;
    height: 100%;
}

.SideNav {
    width: 220px;
}


@media (max-width: 1200px) {
    .SideNav {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .Conversations {
        width: calc(100% - 220px - 15px);
        margin-right: 15px;
    }
}

@media (max-width: 700px) {

    .SideNav {
        display: none;
    }

    .Conversations {
        width: 100%;
        padding-right: 0;
        border-left: none;
        border-right: none;
        margin-right: 0;
    }
}
