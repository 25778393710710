@import '../../../styles/Styles.module.css';

.FilterOptions {
    margin: 10px 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.FilterOptionsCollapsed {
    display: none;
}

.FilterOption {
    padding: 4px 8px;
    cursor: pointer;
    color: var(--color-text-dark-secondary);
    font-weight: 400;
    font-size: 14px;
    margin: 5px 10px 5px 0;
    border-radius: 40px;

}

.FilterOptionSelected {
    background-color: var(--color-input-background-light);
    color: var(--color-primary);
}

.CloseFiltersButton {
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -16px;
    right: 50%;
    z-index: 1000;
    background-color: var(--color-text-dark);
    border-radius: 100%;
    color: white;
    box-shadow: 0 6px 9px -3px rgba(0,0,0, 0.2);
}
