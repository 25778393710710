.SocialIcon {
    width: 25px;
    height: 25px;
    background-color: var(--color-primary);
    color: white;
    border-radius: 100%;
    margin-right: 10px;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SocialIcon a {
    color: white;
    text-decoration: none;
}

.Link {
    text-decoration: none;
}


@media (max-width: 700px) {
    .SocialIcon {
        width: 20px;
        height: 20px;
        font-size: 14px;
    }
}
