.BackDrop {
    background-color: var(--color-text-dark);
    opacity: .3;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
}

.PopupInner {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.Header {
    text-align: center;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #EBEBEB;
    padding: 20px 0 20px 0;
}

.Header h2 {
    margin: 0;
    font-size: 18px;
}

.Exit {
    position: absolute;
    top: 15px;
    left: 15px;
    font-weight: normal;
    background: none;
    outline: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: var(--color-text-dark);
}

.Exit svg {
    width: 15px;
    color: var(--color-text-dark);
    z-index: 1000;
}

.Exit:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

.FormContainer {
    padding: 0 50px;
}

.FormInner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.InputHeader {
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #f0f1f5;
    overflow: hidden;
    outline: none;
    font-size: 16px;
    height: 45px;
    border: 1px solid #ebedf2;
}

.TextArea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    background-color: #f0f1f5;
    outline: none;
    font-size: 15px;
    min-height: 200px;
    resize: vertical;
    border: 1px solid #ebedf2;
}

.Label {
    margin-top: 15px;
    margin-bottom: 5px;
    position: relative;
    font-size: 14px;
    text-align: left;
}

.ErrorMessage {
    color: red;
    font-size: 12px;
    margin-left: auto;
    margin-right: 0;
}

.Hidden {
    display: none;
}

.LabelUnderline {
    text-decoration: underline;
    cursor: pointer;
    margin: 0 3px;
    color: inherit;
}


.Submit {
    border: none;
    outline: none;
    padding: 15px 30px;
    border-radius: 4px;
    box-shadow: 0 4px 9px -3px rgba(0,0,0, 0.2);
    cursor: pointer;
    color: white;
    background-color: #0A2540;
    font-size: 16px;
    width: 100%;
    margin: 25px 0 10px 0;
    position: relative;
}

.Submit:hover {
    box-shadow: 0 4px 9px -2px rgba(0,0,0, 0.4);
}

.Error {
    color: #D93025;
    font-size: 14px;
}

.SwitchPopup {
    text-align: center;
    font-size: 14px;
    width: 100%;
    padding-top: 15px;
    display: flex;
    justify-content: space-evenly;
}

.TermsAndConditions {
    text-align: center;
    font-size: 13px;
    color: var(--color-text-dark-secondary);
}

.SideSwitchPopupLeft{
    text-align: left;
    font-size: inherit;
    justify-content: left;
}
.SideSwitchPopupLeft{
    text-align: right;
    font-size: inherit;
    justify-content: right;
}
.SwitchPopupCenter {
    text-align: inherit;
    justify-content: center;
}

.Spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .4);
    z-index: 110;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ErrorPopupContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    z-index: 110;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ErrorPopup {
    position: relative;
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    max-width: 200px;
    box-shadow: 0px 4px 9px -2px rgba(0,0,0, 0.4);
    text-align: center;
}

.ErrorPopupIcon {
    width: 100%;
    font-size: 60px;
    color: #cc3300;
}

.SubmitButtonWrapper {
    width: 100%;
    position: relative;
    margin: 10px 0;
    height: 50px;
}

.GoogleButton {
    background-color: white !important;
    color: black !important;
    border: 1px solid var(--color-text-dark-secondary) !important;
}

.GoogleButton svg {
    height: 20px;
    margin-right: 15px;
}

.DescriptiveLabel {
    padding: 15px 0 5px 0;
    text-align: left;
}

.CenteredText {
    padding: 15px 0 20px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.CenteredText div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.CenteredText img {
    margin-top: 20px;
    border-radius: 100%;
    width: 150px;
}
