@import '../../styles/Styles.module.css';

.SideNav {
    width: 220px;
}

.Back {
    width: fit-content;
    position: absolute;
    display: flex;
    top: 0;
    padding: 5px 10px;
    align-items: center;
    cursor: pointer;
    border-radius: 25px;
    margin: 20px 0 5px 10px;
    font-weight: bold;
    font-size: 15px;
    background-color: white;
    z-index: 100;
}

.Back:hover {
    background-color: rgba(255, 255, 255, .8);
}

.Back svg {
    width: 16px;
    max-height: 16px;
    margin-right: 6px;
}

.Profile {
    width: calc(100% - 220px - 340px);
    position: relative;
    box-sizing: border-box;
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
}

.ProfileMetadata {
    padding: 10px;
}

.ProfileMetadata h3 {
    margin: 0;
}

.ProfileMetadata div {
    color: var(--color-text-secondary);
}

.BackContainer {
    width: 100%;
    position: relative;
    display: flex;
}

.BackIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(255, 255, 255, 1);
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.ProfileInner {
    border-bottom: none;
    position: relative;
}

.Recommendations {
    width: 24%;
    position: sticky;
    height: fit-content;
    top: 20px;
    margin-top: 20px;
}

.Header {
    width: 100%;
    height: 220px;
    display: flex;
    position: relative;
    justify-content: center;
}

.HeaderBanner {
    height: 180px;
    width: 100%;
    overflow: hidden;
    background-color: var(--color-border-light);
}

.HeaderBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.HeaderContent {
    padding: 0 20px 20px 20px;
    width: 100%;
    background: none;
    box-sizing: border-box;
    position: relative;
}

.Content {
    width: 100%;
    box-sizing: border-box;
}

.ProfilePhoto {
    width: 125px;
    height: 125px;
    position: absolute;
    left: 20px;
    bottom: 0px;
}

.ProfileEditIcon {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid var(--color-border-light);
}

.BannerEditIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid var(--color-border-light);
}

.HeaderIconContainer {
    position: absolute;
    top: -40px;
    right: 10px;
    display: flex;
}

.HeaderEditIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DeleteIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 30px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 13px;
    color: var(--color-text-dark);

}

.DeleteFeedPostContainer {
    padding: 60px 40px;
    background-color: white;
    border-radius: 4px;
    position: relative;
}

.DeleteSubmitContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 40px;
}

.EditSubmitCancelButton {
    background-color: white;
    border: 1px solid #CDD6E5;
    font-size: 14px;
    color: #7c859c;
}

.EditSubmitButton {
    font-size: 14px;
    margin-left: 10px;
}

.TitleContainer {
    margin: 10px 0 0 0;
    display: flex;
    align-items: center;
}

.FollowButton {
    padding: 0 13px;
    font-weight: 400;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    margin-left: 20px;
    cursor: pointer;
    border-radius: 4px;
}

.FollowButtonFollowing {
    background: var(--color-primary);
    color: white;
}

.Title {
    margin: 0;
    font-size: 25px;
}

.SocialContainer {
    display: flex;
    flex-wrap: wrap;
}

.Link {
    color: var(--color-text-dark);
    margin-right: 10px;
}

.Email {
    font-size: 14px;
    color: #6e7891;
}

.Followers {
    display: flex;
    padding: 15px 0 0 0;
    cursor: pointer;
    width: fit-content;
    font-weight: bold;
}

.Followers span {
    margin-right: 10px;
}

.SpinnerContainer {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: white;
}

.Tabs {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    border-bottom: 1px solid var(--color-border-light);

}

.Tab {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    height: 40px;
    width: 18%;
    min-width: 100px;
    font-size: 13px;
    justify-content: center;
    box-sizing: border-box;
    transition: background-color .1s linear, color .1s linear;
    text-transform: uppercase;
    color: var(--color-text-dark-secondary);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
}

.TabActive {
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
    transition: background-color .1s linear, color .1s linear;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .SideNav {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .Profile {
        width: calc(100% - 220px - 15px);
        margin-right: 15px;
    }
}

@media (max-width: 700px) {
    .SideNav {
        display: none;
    }

    .Profile {
        width: 100%;
        padding-right: 0;
        border-left: none;
        border-right: none;
        margin-right: 0;
    }

    .Header {
        height: 160px;
    }

    .HeaderBanner {
        height: 120px;
    }

    .ProfilePhoto {
        width: 95px;
        height: 95px;
    }

    .Tab {
        font-size: 12px;
        min-width: 60px;
    }

    .Title {
        font-size: 18px;
    }

    .Back {
        margin-top: 10px;
    }

    .SocialContainer {
        font-size: 13px;
    }

    .Followers {
        font-size: 14px;
    }
}
