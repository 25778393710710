.Container {
    width: 100%;
    padding-right: 30px;
    box-sizing: border-box;
    height: calc(100vh - 80px);
    position: sticky;
    top: 0;
    padding-top: 20px;
    background-color: white;
    overflow-y: auto;
}

.NavGroup {
    margin-bottom: 10px;
    padding-bottom: 15px;
}

.NavGroup a {
    text-decoration: none;
    color: inherit;
}

.NavGroupNews {
    border-top: 1px solid var(--color-input-background);
    border-bottom: 1px solid var(--color-input-background);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 15px 0;
}

.NavGroupNews a {
    text-decoration: none;
    color: inherit;
}

.NavHeader {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    font-size: 14px;
    color: var(--color-text-dark-secondary);
    transition: background-color .05s linear, color .05s linear;
    text-transform: uppercase;
}

.NavHeader span {
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
}


.NavOption {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    padding: 8px 10px 8px 10px;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 16px;
    background: none;
    transition: background-color .05s linear, color .05s linear;
}

.CreateProfileButton {
    padding: 10px 0;
    font-size: 15px;
    margin: 10px;
    border: 1px solid var(--color-primary);
    background-color: white;
    color: var(--color-primary);
}

.CreateProfileButton:hover {
    background-color: var(--color-primary-washed);
}

.CreateProfileButtonSubText {
    color: var(--color-text-dark-secondary);
    font-size: 15px;
    width: 100%;
    margin-top: 20px;
    padding: 0 10px;
}

.NavOption svg {
    width: 19px;
    max-height: 17px;
    margin-right: 20px;
    color: var(--color-text-dark-secondary);
}

.NavOption:hover {
    background-color: var(--color-input-background-light);
    transition: background-color .1s linear;
}

.NavOptionActive > .NavOption {
    color: var(--color-primary);
}

.NavOptionActive > .NavOption > svg {
    color: var(--color-primary) !important;
}

.FooterPage {
    margin-top: 40px;
    color: var(--color-text-secondary);
    font-size: 12px;
    font-weight: bold;
    padding: 10px; box-sizing: border-box;
}

.ContactOption {
    padding: 4px 0;
}

.ContactOption a {
    text-decoration: none;
    color: inherit;
}
