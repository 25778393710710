.FeaturedEntityContainer {
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.Logo {
    width: 40px;
    height: 40px;
}

.Right {
    width: calc(100% - 40px);
    padding-left: 10px;
box-sizing: border-box;
}

.Preview {
    width: 100%;
    height: 225px;
    object-fit: contain;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.Preview img {
    height: 225px;
    min-width: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.Details {
    width: 100%;
    display: flex;
    padding-top: 10px;
}

.Title {
    font-size: 18px;
    font-weight: bold;
}

.Description {
    width: 100%;
    color: var(--color-text-dark-secondary);
}

.Description svg {
    width: 10px;
}
