.Container {
    width: 500px;
    height: 600px;
    background-color: white;
    position: relative;
    cursor: default;
    border-radius: 3px;
}

.Header {
    width: 100%;
    height: 60px;
    display: flex;
}

.HeaderItem {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid #EBEEF0;
    font-weight: bold;
    cursor: pointer;
}

.HeaderActive {
    border-bottom: 3px solid var(--color-primary);
    color: var(--color-primary);
}

.ProfileList {
    overflow-y: scroll;
    max-height: calc(600px - 60px);
    padding-bottom: 150px;
    box-sizing: border-box;
}

.FollowerRow {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #EBEEF0;
    padding: 10px 20px;
    box-sizing: border-box;
    align-items: center;
}

.Logo {
    width: 45px;
    height: 45px;
    position: relative;
    cursor: pointer;
}

.NameMetadataContainer {
    width: calc(100% - 70px - 100px);
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
}

.Name {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.Name:hover {
    text-decoration: underline;
}

.Metadata {
    font-size: 14px;
    color: #6e7891;
    font-weight: 400;
}

.FollowButton {
    padding: 2px 10px;
    background-color: var(--color-primary);
    color: white;
    border-radius: 4px;
}

.Close {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
}

.Close svg {
    width: 15px;
}

.Close:hover {
    background-color: #F5F7FA;
}

@media (max-width: 1100px) {
    .Container {
        width: 100vw;
        height: 100vh;
        padding-top: 60px;
        padding-bottom: 15vh;
        box-sizing: border-box;
    }

    .ProfileList {
        max-height: calc(100% - 60px);
        box-sizing: border-box;
    }
}
