/* Basic Details */
.Content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.BasicDetailsLeft {
    width: 175px;
}

.BasicDetailsRight {
    width: calc(100% - 175px);
    padding-left: 30px;
    box-sizing: border-box;
}

.ImageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0 10px 0;
    position: relative;
}

.ImageContainerInner {
    width: 175px;
    height: 175px;
    position: relative;
}

.ChangeButton {
    border: 1px solid var(--color-border-light);
    color: var(--color-text-dark);
    background-color: white;
    position: absolute;
    top: 7px;
    right: 7px;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ChangeButton svg {
    width: 20px;
}

@media (max-width: 700px) {
    .BasicDetailsLeft {
        width: 100%;
    }

    .BasicDetailsRight {
        width: 100%;
        padding-left: 0;
    }
}
