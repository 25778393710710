@import '../../../../styles/Styles.module.css';


.HashTag {
    color: var(--color-primary);
    cursor: pointer;
}

.Link {
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: none;
}

.Link i {
    font-size: 12px;
    margin-right: -2px;
}

.ShowMore {
    cursor: pointer;
    color: var(--color-primary);
}
