.CompanyInformationContainer {
    width: 100%;
}

.SocialContainer {
    display: flex;
    justify-content: flex-end;
}

.FeaturedImages {
    width: 100%;
    padding-top: 60px;
    position: relative;
}

.TagLine {
    width: 100%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.TagLineInner {
    max-width: 100%;
    width: 600px;
    font-style: italic;
}

.FoundersContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.FoundersContainer h3 {
    width: 100%;
}

.Founder {
    margin-right: 30px;
    text-align: center;
}

.FounderHeadshot {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.FounderHeadshotInner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
}

.FounderHeadshotInner img {
    height: 100%;
}

.LinkedIn {
    width: 40px;
    height: 40px;
    background-color: var(--color-primary);
    border-radius: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FounderHeadshot a {
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    text-decoration: none;
}

.FounderName {
    font-weight: 400;
    margin-top: 5px;
}

.EditIconContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}


.KeyFacts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.KeyFact {
    width: 50%;
}

.KeyFactValue {
    font-size: 40px;
}

.KeyFactLabel {
    margin-left: 40px;
}

.BusinessStageTechnologiesSplitContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.BusinessStageContainer {
    width: 50%;
    position: relative;
}

.TechnologiesContainer {
    width: 50%;
    position: relative;
}

.PreviousInvestors {
    margin-bottom: 170px;
}

.EditIcon {
    position: absolute;
    top: 0;
    right: -10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

@media (max-width: 1100px) {
    .KeyFact, .TechnologiesContainer, .BusinessStageContainer {
        width: 100%;
    }
}
