.Container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Container input {
    display: none;
}

.PhotoContainer {
    cursor: pointer;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-flow: column;
    position: relative;
    font-size: 12px;
    box-sizing: border-box;
}

.PhotoButtonContainer:active {
    background-color: #F5F7FA;
}

.IconPrimary {
    font-size: 50px;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
}

.PhotoContainer img {
    max-width: 100%;
    max-height: 100%;
}

.PhotoButtonContainer {
    width: fit-content;
    border: 1px solid var(--color-text-dark);
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 2px;
    color: var(--color-text-dark);
    font-weight: 400;
    cursor: pointer;
}

.PhotoButtonContainer i {
    margin-right: 10px;
}

.OptionIcons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.OptionIcon {
    font-size: 14px;
    color: var(--color-text-dark);
    padding: 6px 0 6px 30px;
    font-weight: 400;
    cursor: pointer;
}

.OptionIcon i {
    margin-left: 5px;
}

.PhotoPreview {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
