.Comment {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    font-size: 15px;
    border-bottom: 1px solid var(--color-border-light);
    padding: 0 20px;
}

.FeedPostPrimary {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 15px 0 10px 0;
    cursor: pointer;
}

.FeedPostLeft {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
}

.FeedPostLeft a {
    text-decoration: none;
}

.Logo {
    width: 50px;
    height: 50px;
    position: relative;
}

.FeedPostRight {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    position: relative;
    padding-left: 65px;
}

.CreateCommentPopupContainer {
    z-index: 1000;
    width: 560px;
    background-color: white;
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 15px 0 0 0;
    position: relative;
}

.CloseCommentIconContainer {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;
}

.CloseCommentIconContainer:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

.CloseCommentIconContainer svg {
    width: 14px;
}

.DeleteFeedPostContainer {
    padding: 60px 100px;
    background-color: white;
    border-radius: 4px;
    position: relative;
}

.DeleteSubmitContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 40px;
}

.FeedPostOptions {
    z-index: 10000;
    display: flex;
    justify-content: flex-end;
    color: var(--color-text-dark-secondary);
}

.FeedPostOption {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    color: #AAB0BF;
    cursor: pointer;
}

.FeedPostOption:hover {
    background-color: #F5F7FA;
}

.Container {
    width: 100%;
}

.FeedPostActions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /*margin-top: 20px;*/
    padding: 10px 15px 0 15px;
    box-sizing: border-box;
}

.FeedPostAction {
    margin-right: 20px;
    color: var(--color-text-dark-secondary);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
}

.FeedPostAction:hover {
    background-color: rgba(205, 214, 229, .15);
}

.FeedPostActionLiked {
    color: var(--color-primary);
}


.Comments {
    width: 100%;
    position: relative;
}

.FeedPostActionValue {
    margin-left: 7px;
    font-size: 14px;
}

.EditContainer {
    border-top: 1px solid var(--color-border-light);
    border-bottom: 1px solid var(--color-border-light);
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
}

.EditSubmitContainer {
    width: 100%;
}

.EditSubmitCancelButton {
    background-color: white;
    border: 1px solid #CDD6E5;
    font-size: 14px;
    color: #7c859c;
}

.EditSubmitButton {
    font-size: 14px;
    margin-left: 10px;
}

.FeedPostHeader {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin-left: 15px;
}

.FeedPostHeader h3 {
    margin: 0;
    width: 100%;
}

.FeedPostHeader h3 a {
    color: inherit;

}

.FeedPostHeader h3 i {
    color: #a8aebd;
    font-size: 14px;
}

.Follow {
    flex: 1;
}

.Timestamp {
    color: #a8aebd;
}

.Timestamp span {
    margin-right: 6px;
    font-weight: bold;
    color: #757887;
}

.FeedPostContent {
    font-weight: 400;
    word-wrap: break-word;
}

.FeedPostContent pre {
    margin: 0;
    font-size: 15px;
}

.FeedPostText {
    margin-bottom: 10px;
}

.Images img {
    margin-top: 10px;
    width: 100%;
    max-height: 600px;
}

.Videos video {
    margin-top: 10px;
    width: 100%;
}

.Input {
    margin-right:0;
    margin-left: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.Input textarea {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    height: 100%;
    min-height: 100px;
    min-width: 100%;
    font-size: 16px;
    padding: 13px;
    box-sizing: border-box;
    resize: none;
    transition: background-color .2s, box-shadow .2s;
}

@media (max-width: 1100px) {
    .FeedPost {
        border-right: none;
        border-left: none;
    }

    .CreateCommentPopupContainer {
        width: 100vw;
        height: 100%;
        padding-top: 60px;
        padding-bottom: 15vh;
    }
}
