@import '../../../../styles/Styles.module.css';

.EditIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
}

.Title {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px;
    box-sizing: border-box;
}

.SubmitContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
    height: 80px;
    border-top: 1px solid var(--color-border-light);
}

.SaveOption {
    margin-left: 10px;
}

.CancelOption {
    background-color: white;
    border: 1px solid #CDD6E5;
    color: #7c859c;
}

.HeaderBanner {
    height: 180px;
    width: 100%;
    overflow: hidden;
    background-color: var(--color-border-light);
    margin: 10px 0;
}

.HeaderBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ChangeButtonContainer {
    height: 38px;
    margin-bottom: 20px;
}

.ChangeButtonContainer i {
    margin-right: 10px;
}

.ChangeButton {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: white;
}
