.ProfilePhotoInner {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: 0 2px 5px 1px rgba(0,0,0, 0.15);
    width: 125px;
    height: 125px;
    border-radius: 100%;
    cursor: pointer;
}


.ProfilePhotoPreview {
    width: 400px;
    height: fit-content;
    display: flex;
    justify-content: center;
    background-color: white;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 5px;
    position: relative;
}

.ProfilePhotoPreviewContainer {
    width: 380px;
    height: 380px;
    position: relative;
}

.CloseHeader {
    height: 50px;
    width: 100%;
    position: relative;
}

.CloseIcon {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10000;
}

.CloseIcon:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

.CloseIcon svg {
    width: 14px;
}

@media (max-width: 700px) {
    .ProfilePhotoPreview {
        width: 100vw;
        height: 100vh;
        padding-top: 60px;
        padding-bottom: 15vh;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    .ProfilePhotoPreviewContainer {
        width: 300px;
        height: 300px;
    }

    .ProfilePhotoInner {
        width: 95px;
        height: 95px;
    }

}
