.FollowButton {
    padding: 0 13px;
    font-weight: 400;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    margin-left: 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 15px;
}

.FollowButton:hover {
    background-color: var(--color-primary-washed);
}

.FollowButtonFollowing {
    background: var(--color-primary);
    color: white;
}

.FollowButtonFollowing:hover {
    background: var(--color-primary);
    color: white;
}
