.PostsContainer {
    /*padding: 20px;*/
    box-sizing: border-box;
    width: 100%;
    max-width: 700px;
}

.NoPosts {
    width: 100%;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
    color: var(--color-text-secondary);
}

.NoPosts i {
    font-size: 55px;
}

.SpinnerContainer {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
