.Container {
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.InputContainer {
    width: 100%;
    position: relative;
    border-radius: 5px;
    transition: box-shadow 0.1s ease-in-out;
}

.Header {
    width: 100%;
    display: flex;
    height: 50px;
    margin-bottom: 10px;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-top: 15px;
    box-sizing: border-box;
}

.HeaderImage {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.Header a {
    text-decoration: none;
    color: inherit;
}

.Header h3 {
    margin: 0;
}

.InputContainerInner {
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
    min-height: 100%;
}

.Input {
    margin-right:0;
    margin-left: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.Input textarea {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    height: 100%;
    min-height: 60px;
    min-width: 100%;
    font-size: 16px;
    padding: 13px;
    box-sizing: border-box;
    resize: none;
    transition: background-color .2s, box-shadow .2s;
}

.SubmitButtonContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-left: 5px;
    height: 35px;
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
}

.MediaOptions {
    border-top: 1px solid #F0F1F5;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.MediaOptions input {
    display: none;
}

.MediaOption {
    display: flex;
    align-items: center;
    padding: 5px 8px;
    border-radius: 4px;
    margin-right: 15px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
}

.MediaOption:hover {
    background-color: var(--color-input-background-light);
}

.MediaOption svg {
    margin-right: 6px;
    width: 20px;
    max-height: 17px;
    color: var(--color-primary);
}

.MediaPreviewContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 0 10px;
    font-size: 13px;
}

.ImagePreview {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    background-color: var(--color-primary-washed);
    -webkit-box-shadow: 0 0 0 1px var(--color-primary);
    box-shadow: 0 0 0 1px var(--color-primary);
    padding: 3px 5px 3px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
}

.ImagePreview svg {
    width: 15px;
    margin-right: 10px;
}

.VideoPreview {
    display: flex;
    align-items: center;
    color: rgba(0, 204, 170, 1);
    background-color: rgba(0, 204, 170, .1);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 204, 170, 1);
    box-shadow: 0 0 0 1px rgba(0, 204, 170, 1);
    padding: 3px 5px 3px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
}

.VideoPreview svg {
    width: 15px;
    margin-right: 10px;
}

.RemoveMedia {
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2px;
}

.RemoveMedia svg {
    height: 15px;
    margin: 0;
}

@media (max-width: 1100px) {

    .Header {
        padding: 0 10px;
    }
}
