@import '../../../styles/Styles.module.css';

.ProfilePage {
    width: 100%;
    min-height: calc(100% - 370px);
    position: relative;
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 0 20px;
    box-sizing: border-box;
}

.ProfilePage h2 {
    margin: 0;
}

.ProfilePage h3 {
    font-size: 25px;
    font-weight: 400;
    margin: 0;
    width: 100%;
}

.ProfilePage h4 {
    margin: 0 0 10px 0;
}

.Container {
    width: 100%;
    max-width: 1000px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 20px 70px 20px;
    box-sizing: border-box;
}

.BackContainer {
    height: 50px;
    width: 100%;
    position: relative;
}

.BackIcon {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 1000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(255, 255, 255, 1);
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.Header {
    width: 100%;
    height: 250px;
    display: flex;
    position: relative;
    justify-content: center;
    overflow: hidden;
}

.HeaderBanner {
    height: 200px;
    width: 100%;
    overflow: hidden;
}

.HeaderBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ProfilePhoto {
    border: 1px solid #CDD6E5;
    outline: 5px solid white;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    position: absolute;
    left: 50px;
    bottom: 0;
}

.ProfilePhoto img {
    max-width: 100%;
}

.ProfileOptionsContainer {
    margin-top: 10px;
}

.ProfileOption {
    height: 25px;
    background:  none;
    padding: 0;
    outline: none;
    border: none;
    color: var(--color-primary);
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
}

.ProfileOption i {
    margin-left: 3px;
}

.DeleteProfileContainer {
    padding: 60px 100px;
    background-color: white;
    border-radius: 4px;
    position: relative;
}

.DeleteSubmitContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 40px;
}

.DeleteSubmitCancelButton {
    background-color: white;
    border: 1px solid #CDD6E5;
    font-size: 14px;
    color: #7c859c;
}

.DeleteSubmitButton {
    font-size: 14px;
    margin-left: 10px;
}

.ContainerInner {
    z-index: 100;
    width: 900px;
    background: none;
}

.TitleContainer {
    margin: 10px 0 0 0;
    display: flex;
    align-items: center;
}

.Title {
    margin: 0;
    font-size: 40px;
    line-height: 1.0em;
}

.SubTitle {
    color: #6e7891;
    display: flex;
    align-items: center;
    font-size: 15px;
}

.SubTitle i {
    font-size: 6px;
    margin: 0 7px;
    padding-bottom: 10px;
}

.SocialContainer {
    display: flex;
}

.Link {
    color: var(--color-text-dark);
    margin-right: 10px;
}

.Followers {
    display: flex;
    padding: 5px 0 10px 0;
    cursor: pointer;
    width: fit-content;
}

.FollowersOption {
    font-weight: bold;
    margin-right: 10px;
}

.Connect {
    display: flex;
}

.Description {
    display: flex;
    position: relative;
    flex-wrap: wrap;
}

.FocusAreaIcon {
    position: relative;
    width: 70px;
}

.FocusAreaIcon img {
    max-width: 100%;
}

.SpinnerContainer {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: white;
}

.SplitSection {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

.SplitSectionLeft {
    width: 30%;
}

.SplitSectionLeft h3 {
    margin: 0;
    font-size: 15px;
}

.SplitSectionRight {
    width: 70%;
    font-size: 14px;
    color: var(--color-text-dark-secondary-darker);
}

.Section {
    display: flex;
    width: 100%;
    padding: 10px 0;
    flex-wrap: wrap;
    position: relative;
}

.Section h3 {
    width: 100%;
    margin: 0 0 10px 0;
}

.HalfSection {
    width: 50%;
}

.IconsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.IconContainer {
    text-align: center;
    position: relative;
    width: 65px;
    margin: 0 5px 5px 0;
}

.InvestmentRange {
    padding: 8px;
    margin: 0 15px 5px 0;
    border: 1px solid var(--color-text-dark);
    border-radius: 2px;
    font-weight: 400;
}

.Icon {
    width: 60px;
    height: 60px;
    position: relative;
    padding: 7px;
    box-sizing: border-box;
}

.Icon img {
    width: 100%;
}

.TechnologyIcon img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(46deg) brightness(102%) contrast(101%);
}

.IconLabel {
    font-size: 12px;
    font-weight: 400;
}


.Tabs {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    margin-bottom: 20px;
}

.Tab {
    width: 125px;
    height: 40px;
    border-bottom: 2px solid var(--color-border-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    color: var(--color-text-secondary);
    cursor: pointer;
}

.TabActive {
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
}

.TabFiller {
    width: calc((100% - 250px) / 2);
    border-bottom: 1px solid var(--color-border-light);
}

.Tab i {
    margin-right: 10px;
}

@media (max-width: 700px) {
    .SubTitle {
        font-size: 13px;
    }
}
