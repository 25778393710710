.Container {
    position: absolute;
    top: -10px;
    right: 5px;
    z-index: 1;
}

.ContainerInner {
    position: relative;
}

.DropdownToggler {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: background-color .2s linear;
}

.DropdownToggler:hover {
    background-color: rgba(205, 214, 229, .15);
    transition: background-color .1s linear;
}

.OptionsDropdown {
    right: 0;
    background-color: white;
    position: absolute;
    box-shadow: rgba(101, 119, 134, .2) 0 0 15px, rgba(101, 119, 134, .15) 0 0 3px 1px
}

.OptionsDropdownInner {
    position: relative;
    width: 150px;
}

.DropdownOption {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
}

.DropdownOption:hover {
    background-color: rgba(205, 214, 229, .15);
}

.DropdownOption svg {
    width: 15px;
    margin-left: 10px;
}
