@import '../../styles/Styles.module.css';

.SideNav {
    width: 220px;
}

.Header {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 10px 10px;
    min-width: 0;
    min-height: 0;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--color-border-light);
}

.HeaderOption {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 45px;
    -webkit-box-shadow: 0 0 0 1px var(--color-border-light);
    box-shadow: 0 0 0 1px var(--color-border-light);
    background-color: var(--color-input-background-light);
    transition: box-shadow 0.1s ease-in-out;
    margin-right: 8px;
    height: fit-content;
    font-size: 15px;
}

.HeaderOption i {
    margin-right: 7px;
}

.HeaderOptionActive {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 45px;
    transition: box-shadow 0.1s ease-in-out;
    margin-right: 8px;
    height: fit-content;
    font-size: 15px;
    font-weight: bold;
    background-color: var(--color-primary);
    color: white;
}

.SearchResults {
    width: calc(100% - 220px - 340px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    position: relative;
    box-sizing: border-box;
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
}

.ResultsAll {
    width: 100%;
    position: relative;
}

.ResultsAllHeader {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0 0 0;
    color: var(--color-text-dark-secondary);
    font-size: 15px;
}

.ResultsAllLink {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 10px 0 25px 0;
}

.ResultsAllLink a {
    text-decoration: none;
    color: #606060;
    font-weight: bold;
    cursor: pointer;
    padding: 4px 8px;
    width: 30%;
    min-width: fit-content;
    font-size: 12px;
    text-transform: uppercase;
}

.SearchResult {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    background-color: white;
    box-sizing: border-box;
    padding: 20px;
}

.SearchResult:hover {
    background-color: rgba(205, 214, 229, .15);
}

.Footer {
    width: 100%;
    margin-top: 20px;
    position: sticky;
    bottom: 0;
    max-height: 50px;
    display: flex;
}

.TotalResults {
    flex: 1;
    font-size: 14px;
    text-align: right;
    padding: 5px;
}

.SearchResultsContainer {
    width: 100%;
}

.EndOfResults {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-weight: bold;
}

.SpinnerContainer {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1200px) {
    .SideNav {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .SearchResults {
        width: calc(100% - 220px - 15px);
        margin-right: 15px;
    }

    .SearchFilters {
        margin-top: 0;
        width: 100%;
    }

    .SearchResultContainer {
        width: 100%;
        height: auto;
        padding: 0;
    }

    .SearchPageContainer {
        min-height: calc(100% - 170px);
    }

    .TotalResults {
        padding-right: 5px;
    }
}

.CreateBottom {
    width: 100%;
    height: 15px;
}

.CreatePlaceholder {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid var(--color-border-light);
    padding: 20px;
}

.CreatePlaceholder h3 {
    margin: 0;
    font-size: 22px;
}

.CreatePlaceholderDescription {
    color: var(--color-text-dark-secondary);
}

.CreatePlaceholderButton {
    width: fit-content;
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background: white;
}

@media (max-width: 700px) {

    .Header {
        padding-left: 10px;
    }

    .SideNav {
        display: none;
    }

    .SearchResults {
        width: 100%;
        padding-right: 0;
        border-left: none;
        border-right: none;
        margin-right: 0;
    }
}
