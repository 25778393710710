@import '../../styles/Styles.module.css';

.Container {
    width: 100%;
    padding: 100px 80px 0 80px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
}

.FeaturedContainer {
    width: var(--page-width);
    max-width: 100%;
    background-color: white;
    margin-top: 90px;
}

.ContainerInner {
    width: 100%;
    max-width: var(--page-width);
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: flex-start;
    position: relative;
}

.LandingTitle {
    font-size: 50px !important;
    width: 100%;
    margin: 0;
}

.Title {
    font-size: 28px;
    width: 100%;
    margin: 0;
}

.SeeAllLink {
    color: var(--color-text-dark-secondary);
}

.FeaturedDescription {
    width: 100%;
    color: var(--color-text-dark-secondary);
    font-size: 19px;
    margin-bottom: 5px;
}

.Description {
    color: var(--color-text-dark-secondary);
    font-size: 20px;
    width: 900px;
    margin-bottom: 25px;
    padding-right: 200px;
    box-sizing: border-box;
}

.LandingContainer {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--color-input-background-light);
    padding: 10px 20px 20px 20px;
    box-sizing: border-box;

}

.ProfileTypes {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.ProfileType {
    padding: 10px 30px;
    border-bottom: 2px solid var(--color-text-secondary);
    color: var(--color-text-secondary);

    margin: 0 10px;
    font-weight: 500;
}

.ProfileTypeActive {
    border-bottom: 2px solid var(--color-text-dark);
    color: var(--color-text-dark);
}

.ProfileType svg {
    width: 16px;
}


.RelativeContainer {
    position: relative;
    width: fit-content;
}

.DiagonalContainer {
    position: relative;
    width: 100%;
}

.DiagonalLeft {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 10vw solid white;
    border-right: 100vw solid transparent;
}

.DiagonalRight {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 10vw solid white;
    border-left: 100vw solid transparent;
}

.DiagonalTopRight {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 10vw solid white;
    border-right: 100vw solid transparent;
    z-index: 1000;
}

.Iphone {
    width: 220px;
    position: relative;
    padding-right: 40px;
    box-sizing: border-box;
}

.Iphone img {
    max-width: 100%;
}

.LandingLeft {
    width: calc(100%);
    padding-right: 80px;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    padding-left: 215px;
    padding-top: 0;
}

.LandingLeft h2 {
    font-size: 65px;
    font-weight: bold;
    line-height: 1.0;
    margin-bottom: 5px;
}

.Logo {
    margin-bottom: 20px;
}

.Logo img {
    width: 60px;
}

.SwitchPopup {
    font-size: 14px;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
}

.SideSwitchPopupLeft{
    text-align: right;
    margin-right: 30px;
}
.SideSwitchPopupLeft{
    text-align: left;
}

.LabelUnderline {
    text-decoration: underline;
    cursor: pointer;
}

.LandingRight {
    /*display: none;*/
    width: 40%;
    padding-right: 50px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 100;
    justify-content: flex-end;
}

.LandingRight img {
    max-width: 100%;
    width: 600px;
    object-fit: contain;
}

.LandingButtonsNew {
    margin-top: 5px;
    width: 100%;
}

.LandingButtons {
    margin-bottom: 5px;
    /*margin-top: 5px;*/
    display: flex;
    /*z-index: 100;*/
    width: 100%;
    justify-content: center;
    text-decoration: underline;
}

.SubmitButtonWrapper {
    width: 100%;
    position: relative;
    margin: 10px 0;
    height: 50px;
}

.GoogleButton {
    background-color: white !important;
    color: black !important;
    border: 1px solid var(--color-text-dark-secondary) !important;
}

.GoogleButton svg {
    height: 20px;
    margin-right: 15px;
}

.Line {
    border-bottom: 1px solid var(--color-border-light);
    margin: 15px 0;
}


.Link {
    text-decoration: none;
}

.SignupContainer {
    width: 500px;
    background: rgba(255, 255, 255, 1);
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    animation: fade-translate 1s ease-out;
}

@keyframes fade-translate {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
}

.SignupHeader {
    padding-bottom: 10px;
    font-size: 18px;
    color: var(--color-text-dark-secondary);
}

.SignupHeader h3 {
    margin: 15px 0 5px 0;
    font-size: 24px;
    color: var(--color-text-dark);
}

.FeaturedProfilesContainer {
    background-size: 100%;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: var(--page-width);
}

.LogoText {
    margin-left: 0;
    margin-right: auto;
    font-weight: bold;
    font-size: 26px;
}

.Navigation {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
}


.LatestNews {
    background-color: #fdcf9b;
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.FeaturedProfiles {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
}

.FullWidth {
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    height: fit-content;
}

.LeftHalf {
    width: 50%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    object-fit: contain;
    height: 450px;
}

.LeftHalf img {
    width: 100%;
    object-fit: cover;
    height: 450px;
}

.RightHalf {
    width: 50%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #F5F7FA;
    position: relative;
    padding: 40px 80px;
    box-sizing: border-box;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.RightHalf h3 {
    color: var(--color-text-dark-secondary);
    font-size: 20px;
    margin: 0 0 10px 0;
}

.RightHalf h2 {
    font-size: 32px;
    margin: 0 0 10px 0;
}

.RightHalf div > div {
    color: var(--color-text-dark-secondary);
}

.JohnTest {
    width: fit-content;
    margin-top: 20px;
    padding: 15px 15px !important;
}

.JohnTest > div {
    color: white !important;
}

.SpaceBetween {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    height: fit-content;
}

.FeaturedProfile {
    width: calc(33% - 10px);
    box-sizing: border-box;
    margin: 10px 0;
    display: flex;
    height: fit-content;
}

.SeeAll {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 120px;
    border-radius: 100%;
    font-size: 13px;
    text-align: center;
    text-decoration: underline;
}

.BorderContainer {
    width: 100%;
    height: 258px;
    border: 1px solid var(--color-border-light);
    border-radius: 5px;
}

.BuildingFuture {
    background-color: rgba(10, 37, 64, 1);
    padding-top: 250px !important;
    padding-bottom: 200px !important;
    color: white;
}

.BuildingFutureSplitHeader {
    width: 50%;
}

.BuildingFutureSplit {
    padding-right: 20px;
    box-sizing: border-box;
    width: 50%;
}

.BuildingFutureSplitHeader h2 {
    font-size: 50px;
}

.BuildingFutureSplitHeader h3 {
    font-size: 30px;
    margin: 0;
}

.BuildingFutureHighlight {
    color: #1BB2D3;
}

.JoinNetworkButton {
    width: fit-content;
    margin-top: 50px;
}

.FocusAreasContainerOuter {
    padding-top: 0;
}

.FocusAreasContainer {
    display: flex;
    justify-content: center;
    width: 90%;
    color: white;
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 0 5px 10px 2px rgba(0,0,0, 0.2);
    border-radius: 10px;
    position: relative;
}

.FocusAreasHeader {
    padding-top: 50px;
    width: 50%;
}

.FocusAreasHeader h3 {
    font-size: 30px;
    margin: 0;
    color: #1BB2D3;
}

.FocusAreasHeader h2 {
    font-size: 50px;
    margin: 10px 0 20px 0;
}

.FocusAreaSelectedIconContainer {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    height: 100px;
}

.FocusAreaSelectedIconContainer img {
    width: 65px;
    filter: invert(88%) sepia(83%) saturate(0%) hue-rotate(10deg) brightness(111%) contrast(101%);
    margin-right: 20px;
    animation: fade-translate-focus-area 1s ease-out;
    height: 65px;
}

.FocusAreaSelectedIconContainer h2 {
    margin: 0;
    font-size: 35px;
    animation: fade-translate-focus-area 1s ease-out;
}

.FocusAreaDescription {
    min-height: 100px;
    animation: fade-translate 1s ease-out;
}

.FocusAreaTabs {
    width: 800px;
    max-width: 50%;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 0 0 0;
}

.FocusAreaTextTabs {
    width: 100%;
    max-width: var(--page-width);
    margin-right: auto;
    margin-left: auto;
    display: flex;
    background: none;
    flex-wrap: wrap;
    padding: 0 20px;
}

.FocusAreaTab {
    width: calc(100% / 7 - 28px);
    margin: 0 14px;
    position: relative;
    height: 3px;
    background-color: white;
    opacity: .4;
    transition: opacity .2s linear;
}

.FocusAreaTabActive {
    opacity: 1;
    transition: opacity .2s linear;
}

.FocusAreaTabText {
    width: calc(100% / 7);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: bold;
    color: var(--color-text-secondary);
    transition: color .1s linear;
}

.FocusAreaTabTextActive {
    color: var(--color-text-dark);
    transition: color .1s linear;
}


.ContactLeft {
    width: 60%;
    padding-right: 20px;
    box-sizing: border-box;
}

.ContactRight {
    display: flex;
    justify-content: center;
    width: 40%;
}

.ContactUsContainer {
    background: rgba(255, 255, 255, 0.75);
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    animation: fade-translate 1s ease-out;
}

.Questions {
    width: 100%;
}


.FrequentlyAskedQuestions {
    padding-bottom: 200px;
    padding-top: 200px;
    position: relative;
}

.QuestionsDecorationBox1 {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #0A2540;
    height: 100px;
    width: 400px;
}

.QuestionsDecorationBox2 {
    background-color: rgba(27, 178, 211, .6);
    position: absolute;
    bottom: 60px;
    left: 0;
    height: 80px;
    width: 250px;
}


@media (max-width: 1200px) {
    .Container {
        padding: 90px 20px 0 20px;
    }

    .LandingTitle {
        font-size: 55px !important;
    }

    .LandingLeft {
        padding: 0;
        box-sizing: border-box;
        position: relative;
        z-index: 100;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        margin-top: 70px;
    }

    .LandingTitle {
        text-align: center;
    }

    .Description {
        color: var(--color-text-dark-secondary);
        font-size: 20px;
        width: 900px;
        margin-bottom: 25px;
        padding: 0;
        box-sizing: border-box;
        text-align: center;
    }
    .LandingRight {
        margin-top: 150px;
        width: 100%;
    }

    .SignupContainer {
        width: 100% !important;
    }

    .BuildingFutureSplitHeader {
        width: 100%;
    }

    .FeaturedProfilesContainer {
        background-image: none;
        background-size: 100%;
    }

    .FeaturedProfile {
        width:  calc(50% - 10px);
    }

    .FocusAreasHeader {
        width: 100%;
    }

    .FocusAreasContainer h2 {
        font-size: 20px;
    }

    .FocusAreasContainer {
        width: 100%;
    }

    .FocusAreasContainerOuter {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .FocusAreaTabText {
        width: calc(100% / 3);
    }

    .FocusAreaDescription {
        height: 300px;
        width: 100%;
    }

    .ContactLeft {
        width: 100%;
        margin-bottom: 60px;
    }

    .ContactRight {
        width: 100%;
    }

    .ContactUsContainer {
        width: 100%;
        box-shadow: none;
        margin-bottom: 200px;
    }

    .QuestionsDecorationBox1 {
        height: 60px;
        width: 300px;
    }

    .QuestionsDecorationBox2 {
        bottom: 40px;
        height: 50px;
        width: 150px;
    }
}

@media (max-width: 700px) {
    .FeaturedProfile {
        width: 100%;
        margin: 0;
    }

    .LandingLeft {
        margin-top: 0;
    }

    .LandingContainer {
        padding: 40px 10px 0 10px;
    }

    .LandingTitle {
        font-size: 40px !important;
    }

    .Description {
        font-size: 16px !important;
    }

    .FullWidth {
        flex-wrap: wrap;
    }

    .RightHalf {
        width: 100%;
        border-radius: 0 0 20px 20px;
        height: 300px;
        padding: 15px 20px;
    }

    .RightHalf h2 {
        font-size: 22px;
    }

    .LeftHalf {
        width: 100%;
        height: 300px;
        border-radius: 20px 20px 0 0;
    }
}
