@import '../../styles/Styles.module.css';

.SideNav {
    width: 220px;
}

.FeedPosts {
    width: calc(100% - 220px - 340px);
    box-sizing: border-box;
    border-left: 1px solid var(--color-border-light);
    border-right: 1px solid var(--color-border-light);
}

.CreatePost {
    padding: 20px 10px 0 10px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
}

.CreateBottom {
    border-bottom: 1px solid var(--color-border-light);
    height: 15px;
}

.FeedResults {
    box-sizing: border-box;
}

.CreatePlaceholder {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid var(--color-border-light);
    padding: 20px;
}

.CreatePlaceholder h3 {
    margin: 0;
    font-size: 22px;
}

.CreatePlaceholderDescription {
    color: var(--color-text-dark-secondary);
}

.CreatePlaceholderButton {
    width: fit-content;
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
}

.NoPosts {
    width: 100%;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
    color: var(--color-text-secondary);
}

.NoPosts i {
    font-size: 55px;
}

.SpinnerContainer {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EndOfResults {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-weight: bold;
}

.HashTagSearchBarContainer {
    padding: 20px 20px 0 20px;
    font-size: 20px;
}


@media (max-width: 1200px) {
    .SideNav {
        padding-left: 10px;
        box-sizing: border-box;
    }

    .CreatePost {
        border-left: none;
        border-right: none;
    }

    .CreatePlaceholder {
        border: none;
    }

    .FeedResults {
        border-left: none;
        border-right: none;
    }

    .FeedPosts {
        width: calc(100% - 220px);
        max-width: 600px;
    }
}

@media (max-width: 700px) {
    .SideNav {
        display: none;
    }

    .FeedPosts {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding: 0;
        border-left: none;
        border-right: none;
    }

    .CreatePost {
        margin-top: 10px;
    }
}
