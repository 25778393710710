.ScrollMenu {
    flex: 1 0 0;
    position: relative;
    overflow: hidden;
}

.ScrollMenuContainer {
    padding: 1px 0;
    border-left: 1px solid var(--color-border-light);
    width: 100%;
    display: flex;
    box-sizing: border-box;
    overflow-x: auto;
    flex-direction: row;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    min-width: 0;
    min-height: 0;
    position: relative;
}

.ScrollMenuContainer::-webkit-scrollbar {
    display: none;
}

.CaretLeft {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: fit-content;
}

.CaretLeftContent {
    background-color: white;
    padding: 0 12px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 1px; /* TODO: this is a hack to not extend over the divider border*/
}

.CaretLeftContent:after {
    width: 30px;
    height: 100%;
    content: '';
    top: 0;
    right: -30px;
    position: absolute;
    background-image: linear-gradient(to left, rgba(255,255,255, .0), rgba(255,255,255, 1));
}

.CaretRight {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: fit-content;
}

.CaretRightContent {
    background-color: white;
    padding: 0 12px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.CaretRightContent:after {
    width: 30px;
    height: 100%;
    content: '';
    top: 0;
    left: -30px;
    position: absolute;
    background-image: linear-gradient(to right, rgba(255,255,255, .0), rgba(255,255,255, 1));
}

.CaretRight svg, .CaretLeft svg {
    width: 9px;
}

.FilterHeader {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    -webkit-box-shadow: 0 0 0 1px var(--color-border-light);
    box-shadow: 0 0 0 1px var(--color-border-light);
    background-color: var(--color-input-background-light);
    transition: box-shadow 0.1s ease-in-out;
    border-radius: 25px;
    padding: 5px 10px;
    box-sizing: border-box;
    margin: 0 0 0 10px;
}

.FilterHeaderSelected {
    -webkit-box-shadow: 0 0 0 1px var(--color-text-dark);
    box-shadow: 0 0 0 1px var(--color-text-dark);
    color: var(--color-text-dark);
}

.FilterHeaderPseudo {
    visibility: hidden;
}

.FilterHeaderLabel {
    display: flex;
    align-items: center;
}

.Caret {
    margin-left: 5px;
}

.FilterCount {
    background-color: var(--color-primary);
    color: white;
    font-weight: bold;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}
