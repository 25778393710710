.Container {
    width: 650px;
    background-color: white;
    border-radius: 3px;
    padding: 60px 40px 40px 40px;
    box-sizing: border-box;
    position: relative;
    z-index: 1000;
    max-width: 100%;
}

.Exit {
    position: absolute;
    top: 15px;
    left: 15px;
    font-weight: normal;
    background: none;
    outline: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.Exit svg {
    width: 15px;
}

.Exit:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

/* Basic Details */
.Content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.BasicDetailsLeft {
    width: 175px;
}

.BasicDetailsRight {
    width: calc(100% - 175px);
    padding-left: 30px;
    box-sizing: border-box;
}

.ImageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0 10px 0;
    box-sizing: border-box;
    position: relative;
}

.ImageContainerInner {
    width: 175px;
    height: 175px;
    position: relative;
}

.ChangeButton {
    border: 1px solid var(--color-border-light);
    color: var(--color-text-dark);
    background-color: white;
    position: absolute;
    top: 7px;
    right: 7px;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ChangeButton svg {
    width: 20px;
}

@media (max-width: 700px) {
    .BasicDetailsLeft {
        width: 100%;
    }

    .BasicDetailsRight {
        width: 100%;
        padding-left: 0;
    }

    .Container {
        width: 100vw;
        height: 100vh;
        padding: 60px 20px 15vh 20px;
        overflow-y: scroll;
    }
}
