.MessageButton {
    padding: 0 13px;
    font-weight: 400;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    margin-left: 10px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 15px;
}

.MessageButton:hover {
    background-color: var(--color-primary-washed);
}
