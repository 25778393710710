.Test {
    position: relative;
}

.ImagesContainer {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.ImagesContainerInner {
    display: flex;
    transition: margin-left .3s;
    position: relative;
}

.Arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    cursor: pointer;
    font-size: 25px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, .3);
}

.ArrowRight {
    top: calc(50% - 15px);
    right: -13px;
    background-color: white;
}

.ArrowLeft {
    top: calc(50% - 15px);
    left: -13px;
    background-color: white;
}

.ArrowRight svg, .ArrowLeft svg {
    width: 8px;
}

.Dots {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.Dot {
    color: var(--color-text-secondary);
    font-size: 6px;
    margin: 2px;
}

.ActiveDot {
    color: var(--color-text-dark);
}
