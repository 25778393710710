.CreateProfileCardOptionContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.CardContainer {
    outline: none;
    background-color: white;
    height: 120px;
    width: 120px;
    margin: 0 15px 15px 0;
    border: 1px solid #cdd6e5;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    font-size: 14px;
    font-family: inherit;
    cursor: pointer;
    position: relative;
}

.CardContainerSelected {
    background-color: #fafcfe;
    border: 2px solid var(--color-primary);
}

.CardContainer img {
    max-width: 50px;
    max-height: 50px;
}

.CheckIcon {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: var(--color-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
