.SignInPopUp {
    width: 600px;
    z-index: 1000;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
    padding-bottom: 15px;
}

.ResendEmailButtonContainer {
    padding: 10px 0 10px 0;
    margin-right: 120px;
    margin-left: 120px;
    height: 40px;
}

@media (max-width: 1100px) {
    .SignInPopUp {
        width: 100vw;
        height: 100vh;
        padding-top: 60px;
    }
}
