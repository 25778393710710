.SearchInputContainer {
    width: 100%;
    position: relative;
}

.Container {
    width: 75%;
    display: flex;
    border-radius: 25px;
    transition: box-shadow 0.1s ease-in-out;
    background-color: var(--color-input-background);
    position: relative;
}

.ContainerFocused {
    -webkit-box-shadow: 0 0 0 1px var(--color-primary);
    box-shadow: 0 0 0 1px var(--color-primary);
    background-color: white;
}

.Container input {
    width: calc(100% - 80px);
    height: 42px;
    font-size: 16px;
    outline: none;
    padding: 0 0 0 7px;
    box-sizing: border-box;
    border: none;
    background: none;
}

.Container input::placeholder {
    color: var(--color-text-dark-secondary);
    font-size: 14px;
}

.SearchIcon {
    color: var(--color-text-dark-secondary);
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.SearchIcon svg {
    width: 15px;
}

.ClearInputIcon {
    width: 40px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    transition: color .1s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
}

.ClearInputIcon svg {
    width: 11px;
}

.Suggestions {
    top: calc(100% + 3px);
    border-top: 1px solid #F5F7FA;
    position: absolute;
    z-index: 10000;
    width: 500px;
    max-width: 100vw;
    box-shadow: 0 4px 20px 1px rgba(0,0,0, 0.3);
    background: white;
    border-radius: 3px;
    max-height: 80vh;
    overflow-y: scroll;
}

.BrowseOption {
    padding: 10px;
    cursor: pointer;
    color: var(--color-text-dark-secondary);
    font-size: 14px;
    display: flex;
    align-items: center;
}

.BrowseOption svg {
    width: 14px;
    margin-right: 10px;
}

.BrowseOption:hover {
    background: #F5F7FA;
}

.SuggestionHeader {
    width: 100%;
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--color-input-background-light);
}

.Suggestion {
    width: 100%;
    padding: 7px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Suggestion:hover {
    background: #F5F7FA;
}

.SuggestionLogo {
    width: 37px;
    height: 37px;
    position: relative;
    margin-right: 10px;
}

.SuggestionDescription {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
}

@media (max-width: 1100px) {
    .Suggestions {
        top: 57px;
        left: 0;
        border-top: 1px solid #F5F7FA;
        position: fixed;
        z-index: 10000;
        height: calc(100% - 60px);
        max-height: calc(100% - 60px);
        width: 100vw;
        background: white;
        overflow-y: scroll;
        box-shadow: none;
    }

    .Container {
        width: 100%;
    }
}
