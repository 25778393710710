.Messages {
    box-sizing: border-box;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
}

.Message {
    display: flex;
}

.OutgoingMessage {
    flex-direction: row-reverse;
}

.OutgoingMessage > .MessageContent {
    background-color: var(--color-primary);
    color: white;
}

.Logo {
    width: 40px;
    height: 40px;
}

.MessageContent {
    margin:  0 10px 20px 10px;
    width: calc(100% - 60px);
    background-color: var(--color-input-background);
    padding: 10px;
    border-radius: 10px;
    flex-direction: column;
}

.CreateMessage {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 5px;
    border-top: 1px solid var(--color-border-light);
    box-sizing: border-box;
    height: fit-content;
    flex: 0 1 auto;
}

.RecipientInputContainer {
    width: 100%;
    border-bottom: 1px solid var(--color-border-light);
}

.RecipientInputContainer input {
    width: 100%;
    outline: none;
    padding: 0 15px;
    font-size: 16px;
    border: none;
    height: 50px;
    box-sizing: border-box;
}

.SelectedRecipientContainer {
    width: fit-content;
    border-radius: 25px;
    overflow: hidden;
    margin: 10px 10px;
    padding: 3px 6px 3px 3px;
    box-shadow: 0 0 0 1px var(--color-border-light);
    display: flex;
    align-items: center;
}

.SelectedRecipientLogo {
    width: 37px;
    height: 37px;
    position: relative;
}

.SelectedRecipientContainer svg {
    width: 14px;
    max-height: 18px;
}

.SelectedRecipientName {
    margin-left: 10px;
    margin-right: 5px;
}

.SelectedRecipientClose {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
}

.SelectedRecipientClose:hover {
    background-color: var(--color-input-background);
}

.InputContainer {
    padding: 0 5px;
    width: calc(100% - 40px);
    position: relative;
    box-sizing: border-box;
}

.InputContainer input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    background-color: var(--color-input-background);
    border: none;
    border-radius: 25px;
    outline: none;
    padding: 0 15px;
    font-size: 16px;
}

.InputContainer input:focus {
    background-color: white;
    -webkit-box-shadow: 0 0 0 1px var(--color-primary);
    box-shadow: 0 0 0 1px var(--color-primary);
}

.CreateMessageButton {
    position: relative;
    height: 40px;
    width: 40px;
    background-color: white;
    color: var(--color-primary);
    box-shadow: none !important;
}

.CreateMessageButton svg {
    width: 20px;
}









.SuggestionHeader {
    width: 100%;
    font-weight: bold;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--color-input-background-light);
}

.Suggestion {
    width: 100%;
    padding: 7px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Suggestion:hover {
    background: #F5F7FA;
}

.SuggestionLogo {
    width: 37px;
    height: 37px;
    position: relative;
}

.SuggestionDetails {
    width: calc(100% - 37px);
    padding-left: 10px;
    box-sizing: border-box;
}

.SuggestionDescription {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
}
