@import '../../../styles/Styles.module.css';

.Container {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    border-top: 1px solid var(--color-border-light);
    padding: 0 20px;
}

.Container:hover {
    background-color: var(--color-input-background-very-light);
}

.Container:last-child {
    border-bottom: 1px solid var(--color-border-light);
}

.SearchResult {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    padding: 20px 0;
}

.Header {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.Left {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.Right {
    width: 100%;
    padding-left: 65px;
    box-sizing: border-box;
}

.InvestorTypeBadge {
    width: fit-content;
    height: fit-content;
    border-radius:20px;
    padding: 0 10px;
    font-weight: 400;
    border: 1px solid var(--color-border-light);
}

.LinkContainer {
    text-decoration: none;
    color: var(--color-text-dark);
}

.Logo {
    width: 50px;
    height: 50px;
    position: relative;
}

.Metadata {
    margin-left: 15px;
}

.Metadata h3 {
    font-size: 16px;
    margin: 0;
}

.Metadata h3:hover {
    text-decoration: underline;
}

.Title h1 {
    margin: 0;
    font-size:20px;
}

.Description {
    font-size: 15px;
}

.Description p {
    margin: 0;
}

.SubTitle {
    width: 100%;
    font-size: 14px;
    color: var(--color-text-dark-secondary);
}

.SubTitle i {
    margin-right: 5px;
}

.Technologies {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
}

.Technology {
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    background-color: var(--color-text-dark-secondary);
    color: white;
    padding: 0 5px;
    border-radius: 3px;
    font-weight: bold;
}

.Technology img {
    width: 100%;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(46deg) brightness(102%) contrast(101%);
}


@media (max-width: 700px) {
    .Container {
        border-left: none;
        border-right: none;
        padding: 0 10px;
    }

    .Logo {
        width: 40px;
        height: 40px;
        position: relative;
    }

    .Right {
        padding-left: 0;
        margin-top: 10px;
    }
}
