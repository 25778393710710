.NavigationButton {
    height:100%;
    display: flex;
    align-items:center;
    justify-content: center;
    font-weight: bold;
    padding: 8px 20px;
    text-decoration: none;
    cursor:pointer;
    color: var(--color-text-dark);
    font-size: 15px;
}

.NavigationButtonPrimary {
    background-color: var(--color-primary);
    color: white;
    border-radius: 4px;
    margin-left: 15px;
}

@media (max-width: 1100px) {
    .NavigationButton {
        display: none;
    }
}
