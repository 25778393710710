@import '../../styles/Styles.module.css';

.NavigationBar {
    width: 100%;
    height: fit-content;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 9px -6px rgba(0,0,0, 0.2);
    display: flex;
    justify-content: center;
    z-index: 900;
    background: white;
    min-width: 0;
    min-height: 0;
    border-bottom: 1px solid var(--color-border-light);
}

.NavigationBarInner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: var(--page-width);
    position: relative;
    min-width: 0;
    min-height: 0;
}

.NavigationPrimary {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
}

.NavigationBarLeft img {
    max-height: 35px;
    margin-right: 10px;
    image-rendering: -webkit-optimize-contrast;
}

.NavigationBarLeft a {
    display: flex;
    align-items: center;
}

.NavigationBarLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.SideNavToggle {
    display: none;
}

.NavigationBarRight {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}

.AccountOption {
    position: relative;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.AccountOptionInner {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.SearchControls {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 520px;
    max-width: calc(100vw - 110px);
    padding-left: 160px;
}

.SearchFilters {
    width: 100%;
}

.NavigationLinkIcon {
    display: none;
}

.AccountDropdown {
    position: absolute;
    z-index: 100;
    top: 60px;
    right: 10px;
    width: 350px;
    box-shadow: 0 6px 16px 3px rgba(0,0,0, 0.2);
    border-radius: 3px;
    background-color: white;
    cursor: default;
    border: 1px solid var(--color-input-background-light);
    max-height: 80vh;
    overflow-y: scroll;
}

.Clear {
    cursor: pointer;
    padding: 10px 8px;
    color: var(--color-text-dark);
    font-size: 15px;
    display: flex;
    align-items: center;
}


@media (max-width: 1200px) {

    .SearchControls {
        width: 400px;
    }

    .AccountOption {
        margin-left: 0;
    }

    .NavigationBarRightSmall {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .AccountOptionInner {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
}

@media (max-width: 700px) {
    .SearchControls {
        padding-left: 0;
    }

    .Logo {
        display: none;
    }

    .SideNavToggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        cursor: pointer;
        border-radius: 100%;
        margin-right: 7px;
    }

    .SideNavToggle svg {
        width: 17px;
    }

    .SideNavToggle:hover {
        background-color: var(--color-input-background-light);
    }

    .AccountDropdown {
        width: 100vw;
        height: 100%;
        max-height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        padding-bottom: 15vh;
    }
}


