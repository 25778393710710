.Container {
    width: 650px;
    background-color: white;
    border-radius: 3px;
    padding: 60px 40px 40px 40px;
    box-sizing: border-box;
    position: relative;
    z-index: 1000;
    max-width: 100%;
}

.InvisibleInput {
    display: none;
}

.CreateProfileHeader {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
}

.CreateProfileDescription {
    font-size: 14px;
    color: var(--color-text-dark-secondary);
    margin-bottom: 20px;
}

.CreateProfileHeaderCentered {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.NextButton {
    width: fit-content;
    padding: 10px 20px;
    margin-left: 20px;
}

.PreviousButton {
    width: fit-content;
    padding: 10px 20px;
    margin-right: 20px;
    background-color: white;
    border: 1px solid var(--color-text-dark-secondary);
    color: var(--color-text-dark);
}

.FormContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.ProfileTypeContainer {
    width: 100%;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 10px;
}

.ProfileTypeContainerActive {
    background-color: var(--color-primary-washed);
}

.ProfileTypeContainer h2 {
    margin: 0;
    font-size: 20px;
}

.ProfileTypeContainer svg {
    width: 60px;
}

.ProfileTypeIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProfileTypeContent {
    padding-left: 20px;
    box-sizing: border-box;
}

.ProfileTypeContentDescription {
    margin-top: 10px;
    font-size: 14px;
    color: var(--color-text-dark-secondary);
}

.Navigation {
    width: 100%;
    display: flex;
    margin-top: 40px;
    box-sizing: border-box;
    justify-content: space-between;
}

.NavigationSingleButton {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
    box-sizing: border-box;
}

.PageDetailsContainer {
    width: 100%;
    text-align: center;
}

.Exit {
    position: absolute;
    top: 15px;
    left: 15px;
    font-weight: normal;
    background: none;
    outline: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.Exit svg {
    width: 15px;
}

.Exit:hover {
    background-color: #e2e4e9;
    transition: background-color .2s;
}

.NameContent {
    margin: 20px 0;
}

@media (max-width: 700px) {
    .Container {
        width: 100vw;
        height: 100vh;
        padding: 60px 20px 15vh 20px;
        overflow-y: scroll;
    }
}
