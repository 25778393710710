.Header {
    border-bottom: 1px solid var(--color-border-light);
    padding: 10px 15px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
    flex: 0 1 auto;
}

.Header h3 {
    margin: 0;
}

.HeaderTitle {
    margin-left: 15px;
}

.HeaderTitle a {
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    margin: 0;
}

.HeaderTitle a:hover {
    text-decoration: underline;
}

.HeaderLogo {
    width: 40px;
    height: 40px;
    position: relative;
}

.HeaderIcons {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.ProfileMetadata {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
}

.Icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 100%;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.Icon:hover {
    background-color: var(--color-input-background);
}

.Icon svg {
    width: 15px;
}

.Conversations {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
}

.SpinnerContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 70px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
}

.Message {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid var(--color-border-light);
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-start;
    font-size: 14px;
}

.Message:hover {
    background-color: rgba(205, 214, 229, .15);
}

.MessageLeft {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Logo {
    width: 50px;
    height: 50px;
}

.MessageRight {
    padding-left: 15px;
    width: calc(100% - 50px);
    box-sizing: border-box;
}

.MessageTitle {
    display: flex;
}

.MessageName {
    font-weight: bold;
    font-size: 16px;
}

.Timestamp {
    color: var(--color-text-dark-secondary);
    font-size: 13px;
    flex: 1 1 auto;
    text-align: right;
}


.MessageContent {
    color: var(--color-text-dark-secondary);
}

.MessageContent pre {
    margin: 0;
}

@media (max-width: 700px) {
    .Header {
        padding-left: 10px;
    }

    .MessageLeft {
        width: 40px;
    }

    .MessageRight {
        width: calc(100% - 40px);
    }

    .Logo {
        width: 40px;
        height: 40px;
    }

    .Message {
        font-size: 14px;
    }
}
