.FoundersContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.FoundersContainer h3 {
    width: 100%;
}

.Founder {
    width: 33%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.FounderHeadshot {
    width: 175px;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.FounderHeadshotInner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    background-color: var(--color-input-background-light);
}

.FounderHeadshotInner img {
    height: 100%;
}

.LinkedIn {
    width: 40px;
    height: 40px;
    background-color: var(--color-primary);
    border-radius: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FounderHeadshot a {
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    text-decoration: none;
}

.FounderName {
    font-weight: 400;
    margin-top: 5px;
    width: 100%;
}

@media (max-width: 700px) {
    .FounderHeadshot {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}
