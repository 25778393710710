.Container {
    width: 200px;
    max-width: 100%;
    background-color: white;
    font-size: 15px;
    height: 40px;
    border: 1px solid #cdd6e5;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: flex;
    align-items: center;
}

.Container input {
    font-size: 16px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;  
}


.Container input::placeholder {
    font-style: italic;
    font-size: 14px;
    color: #a8aebd;
}

.ContainerFocused {
    border: 2px solid var(--color-primary);
}
