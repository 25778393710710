@import '../../../styles/Styles.module.css';

.LabelFocused {
    position: absolute;
    top: 3px;
    left: 3px;
    color: var(--color-text-dark);
    font-size: 13px;
    transition: top .1s linear, font-size .1s linear, left .1s linear;
}

.LabelUnfocused {
    position: absolute;
    font-size: 14px;
    top: calc(50% - 10px);
    left: 5px;
    color: var(--color-text-dark);
    transition: top .1s linear, font-size .1s linear, left .1s linear;
}

.ContainerOuter {
    width: 100%;
    position: relative;
}

.Container {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    font-size: 15px;
    height: 50px;
    box-sizing: border-box;
    position: relative;
    margin: 10px 0;
    -webkit-box-shadow: 0 0 0 1px var(--color-text-area-border);
    box-shadow: 0 0 0 1px var(--color-text-area-border);
}

.ContainerFocused {
    -webkit-box-shadow: 0 0 0 2px var(--color-primary);
    box-shadow: 0 0 0 2px var(--color-primary);
}

.Error {
    color: #D93025;
    font-size: 12px;
    margin-bottom: 5px;
}

.Error i {
    margin-right: 3px;
}

.ContainerError {
    -webkit-box-shadow: 0 0 0 2px #D93025;
    box-shadow: 0 0 0 2px #D93025;
}

.Container input {
    font-size: 16px;
    padding: 20px 5px 0 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    background: none;
}

.Container input::-webkit-textfield-decoration-container {
    visibility: hidden;
}


.Icon {
    position: absolute;
    right: 15px;
    top: calc(50% - 10px);
    color: var(--color-primary);
    font-size: 16px;
}
