.FooterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 15px;
    /*background-color: var(--color-input-background-light);*/
    padding-bottom: 20px;
}

.ConnectContainer {
    display: flex;
}

.ConnectLogoContainer {
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 100%;
    margin: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.FooterInner {
    flex-wrap: wrap;
    display: flex;
    padding: 10px 0;
    justify-content: center;
}

.FooterInner {
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
}

.FooterInner a {
    color: var(--color-text-dark);
    text-decoration: none;
    width: 100%;
    display: block;
    padding: 3px 0;
}

.ContactOption {
    margin-right: 20px;
}

.ContactOption a {
    color: var(--color-text-secondary);
}

.ContactOption i {
    margin-right: 10px;
}

.LogoContainer {
    width: 100%;
}

.LogoContainer img {
    max-width: 100px;
}


@media (max-width: 1100px) {
    .FooterPage {
        max-width: 50%;
    }
}
