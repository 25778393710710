.RequiredAnnotation {
    color: #CC333A;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: -10px;
}

.Container {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    margin-bottom: 5px;
    width: 100%;
}

.Label {
    position: relative;
    width: fit-content;
}