.Container {
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    border-radius: 20px;
    font-size: 15px;
    background-color: var(--color-input-background-very-light);
}

.Container h2 {
    font-size: 20px;
    padding: 0 0 10px 0;
    margin: 0;
    border-bottom: 1px solid var(--color-border-light);
}

.Recommendation {
    width: 100%;
    padding-top: 15px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
}

.Logo {
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.Title {
    margin: 0 0 0 10px;
    max-width: calc(100% - 60px - 80px);
}

.Title h3:hover {
    cursor: pointer;
    text-decoration: underline;
}

.RecommendationTitle {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.RecommendationTitle h3 {
    font-size: 15px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.RecommendationSubtitle {
    color: var(--color-text-secondary);
    font-size: 14px;
    margin-bottom: 5px;
    width: 100%;
}

.RecommendationSubtitle span {
    margin-right: 5px;
}

.FollowButtonContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.FollowButton {
    padding: 2px 10px;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    color: var(--color-primary);
    cursor: pointer;
    margin-left: 10px;
}

.FollowButtonFollowing {
    background: var(--color-primary);
    color: white;
}
