@import '../../../styles/Styles.module.css';


.SpinnerContainer {
    height: 100%;
    padding-right: 15px;
    display: flex;
    align-items: center;
}
.Container {
    border: none;
    outline: none;
    padding: 15px 0;
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    background-color: var(--color-primary);
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.FullWidth {
    width: 100%;
}

.Label {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContainerSubmitting {
    background-color: white;
    -webkit-box-shadow: 0 0 0 1px var(--color-border-light);
    box-shadow: 0 0 0 1px var(--color-border-light);
    color: #a8aebd;
    cursor: default;
}
