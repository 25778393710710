.InvestmentInfo {
    width: 100%;
    display: flex;
    position: relative;
    flex-wrap: wrap;
}

.InvestmentInfo h4 {
    margin-top: 40px;
    margin-bottom: 5px;
}

.InvestmentInfoLeft {
    width: 50%;
}

.InvestmentInfoRight {
    width: 50%;
}


.EditIconContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}


.IconsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.InvestmentRange {
    padding: 8px;
    margin: 0 15px 5px 0;
    border: 1px solid var(--color-text-dark);
    border-radius: 2px;
    font-weight: 400;
}

.FocusAreasContainer {
    width: 100%;
}

.TechnologiesContainer {
    width: 100%;
}

.PastInvestmentsContainer {
    width: 100%;
    margin-bottom: 170px;
}

.EditIcon {
    position: absolute;
    top: 0;
    right: -10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

@media (max-width: 1100px) {
    .InvestmentInfoLeft, .InvestmentInfoRight {
        width: 100%;
    }
}
